/* eslint-disable camelcase */
/* eslint-disable vars-on-top */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-var */
/* eslint-disable radix */
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Page, Stack, DisplayText, Spinner,
} from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import { getSessionToken } from '@shopify/app-bridge-utils';
import createApp from '@shopify/app-bridge';
import AsyncSelect from 'react-select/async';
import store from '../../../../../store/CustomStore';
import apiCallerAdvancedGraphs from '../../../../../helper/API/apiCallerAdvancedGraphs';
import FilterData from '../../../../../helper/FilterData';

const selectOptionsStyles = { margin: '20px 0px 0px 0px' };
const { REACT_APP_API_Key } = process.env;
const today = new Date();
const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
var filterDate = {
  from_date: startDay.toISOString().split('T')[0],
  to_date: nextweek.toISOString().split('T')[0],
};
var selectedIDs = [];
var RESPONSE = [];
function findValues(arr) {
  RESPONSE = [];
  for (var i in arr) {
    RESPONSE.push(arr[i]);
  }
}
const OrderSize = () => {
  const [productPrice, setProductPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const Type = store.get('typeOfQuestion');
  // const handleInputChange = (value) => {
  //   setValue(value);
  // };
  const notify = () => toast.error('No Record Found !', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const toastMarkup = (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
  async function getProductFeedback(productPrice) {
    const MERCHANT_ID = store.get('merchant_id');
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);

    if (selectedIDs.length !== 0) {
      setLoading(true);
      const endPoint = 'priceFeedbackValue';
      const headers = { Authorizer: sessionToken };
      const data = {
        order_price: productPrice,
        from_date: filterDate.from_date,
        to_date: filterDate.to_date,
        merchant_id: MERCHANT_ID,
        type: Type,
      };
      try {
        const response = await apiCallerAdvancedGraphs(endPoint, headers, data);
        const values = response.data.body;
        findValues(values);
        setLoading(false);
      } catch (error) {
        console.log('Error', error.message);
        notify();
      }
    }
    setLoading(false);
  }
  const handleFilterDate = (value) => {
    filterDate = { from_date: value.start, to_date: value.end };
    getProductFeedback(productPrice);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
    selectedIDs = value;
    if (selectedIDs.length !== 0) {
      const productPrice = value?.map((a) => a.value);
      // setProductId(productIds);
      setProductPrice(productPrice);
      getProductFeedback(productPrice);
    } else {
      RESPONSE = [];
    }
  };

  const state = {
    labels: RESPONSE.map((value) => value.price),
    datasets: [
      Type === 'net_promoter_score'
        ? {
          label: 'NPS',
          data: RESPONSE.map((value) => value.nps_score),
          borderWidth: 3,
          backgroundColor: '#9FC840',
          borderColor: '#9FC840',
        }
        : {
          label: 'CSAT',
          data: RESPONSE.map((value) => value.csat_score),
          borderWidth: 3,
          backgroundColor: '#E85769',
          borderColor: '#E85769',
        },
    ],
  };

  const LineOptions = {
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  // const chart = () => {
  //   setChartData({

  //   });
  // };

  // const LineOptions = {

  // };
  async function getDropdDownList() {
    const MERCHANT_ID = store.get('merchant_id');
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const endPoint = 'dropdownList';
    const headers = { Authorizer: sessionToken };
    const body = {
      type: 'order_size',
      merchant_id: MERCHANT_ID,
    };
    const response = await apiCallerAdvancedGraphs(endPoint, headers, body);
    const data = response.data.body;
    return data;
  }
  // useEffect(() => {
  //   chart();
  // }, []);
  return (
    <Page>
      <div id="react-select" style={selectOptionsStyles}>
        <Stack distribution="fillEvenly" spacing="loose" alignment="center">
          <DisplayText size="large">Score by Order Size</DisplayText>
          <AsyncSelect
            cacheOptions
            defaultOptions
            value={selectedValue}
            getOptionLabel={(e) => e.label}
            getOptionValue={(e) => e.value}
            loadOptions={getDropdDownList}
            // onInputChange={handleInputChange}
            onChange={handleChange}
            isMulti
          />
        </Stack>
      </div>

      <Stack vertical>
        <Stack.Item distribution="trailing">
          <div id="react-select" style={selectOptionsStyles}>
            {selectedValue.length !== 0 ? (
              <FilterData filterDate={(value) => handleFilterDate(value)} />
            ) : (
              ''
            )}
          </div>
        </Stack.Item>

        <Stack.Item>
          {loading ? (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          ) : (
            <Line data={state} options={LineOptions} />
          )}
        </Stack.Item>
      </Stack>
      {toastMarkup}
    </Page>
  );
};

export default OrderSize;
