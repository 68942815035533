/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { Stack, Link, Banner } from '@shopify/polaris';
import { NavLink, Route, Switch } from 'react-router-dom';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../store/CustomStore';
import { ShopStore } from '../../store/PullStore';
import CaseAndActions from './CaseAndActions';
import TakeActions from './TakeActions';
import Reports from './Reports';
import Settings from './Settings';
import Skeleton from '../../assets/PreLoader/Skeleton';
// import Logo from '../../assets/QriteHeader/Logo';
import '../../assets/styles/HeadeNavStyles.css';
import apiCaller from '../../helper/API/apiCaller';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const HeaderNav = ({ match }) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);

  const activeLink = {
    fontSize: '15px',
    color: '#626264',
    paddingBottom: '15px',
    borderBottom: '3px solid #097757 ',
  };
  async function getShopDetails() {
    setLoading(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };

    await apiCaller(URL, headers, data).then((response) => {
      // setLoading(true);
      const { plan_id } = response.data.info[0];

      const data = response.data.info[0];
      // console.log('DASHBOARD', data);
      ShopStore.update((s) => {
        s.shopDetails = data;
      });
      const email = data.feedback_channel_email_enabled;
      const sms = data.feedback_channel_sms_enabled;
      const thankyou = data.feedback_channel_thank_you_enabled;
      const FeedbackSchedule = data.feedback_schedule;
      const TypeOfQuestion = data.type_of_question;
      const ShowOpenText = data.show_open_text;
      const OpenTextQuestion = data.open_text_question;
      const LogoUrl = data.logo_url;
      const NeedCareNotificationEmail = data.need_care_notification !== null ? data.need_care_notification.email : '';
      const Questions = data.questions[0];
      const AutoReviewChannels = data.auto_review?.channels;
      const AutoReviewUrl = data.auto_review?.url;
      const reviewSchedule = data.review_schedule;
      const rewardCode = data.reward_feedback?.code;
      const rewardText = data.reward_feedback?.text;
      const FeedbackChannels = [
        email ? 'email' : '',
        sms ? 'sms' : '',
        thankyou ? 'thank_you' : '',
      ];
      store.set('planSelectedID', plan_id);
      store.set(
        'planSelected',
        plan_id == 1 ? 'Free' : plan_id == 2
          ? 'Growth' : plan_id == 3 ? 'Advanced' : '',
      );
      store.set('feedbackChannels', FeedbackChannels);
      store.set('feedbackSchedule', FeedbackSchedule);
      store.set('needCareEmail', NeedCareNotificationEmail);
      store.set('typeOfQuestion', TypeOfQuestion);
      store.set('Question', Questions);
      store.set('showOpenText', ShowOpenText);
      store.set('openTextQuestion', OpenTextQuestion);
      store.set('uploadS3url', LogoUrl);
      store.set('autoReviewChannels', AutoReviewChannels);
      store.set('autoReviewUrl', AutoReviewUrl);
      store.set('autoReviewSchedule', reviewSchedule);
      store.set('RewardFeedbackText', rewardText);
      store.set('RewardFeedbackCode', rewardCode);
      setLoading(false);
    });
  }
  async function checkChargeId(ID) {
    setLoading(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    // if (ID) {
    const MERCHANT_ID = store.get('merchant_id');
    const CONFIRM_PAYMENT_URL = 'confirmPayment';
    const data = {
      merchant_id: MERCHANT_ID,
      shopify_payment_id: ID,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    apiCaller(CONFIRM_PAYMENT_URL, headers, data)
      .then(async (response) => {
        // tlocalStorage.removeItem('CHargeID');
        const { message } = response.data;
        if (message === 'verified') {
          store.remove('chargeID');
          await getShopDetails();
        }
        setLoading(false);
        // setView(!view);
      })
      .catch(() => {
        // console.log(error);
      });
    store.remove('chargeID');
    // }
  }
  useEffect(() => {
    const ID = store.get('chargeID');
    if (ID) {
      checkChargeId(ID);
    }
    // setTimeout(() => {
    getShopDetails();
    // }, 2000);
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div>
            {view ? (
              <Banner
                title="Please reload the App if you dont see your features or plan enabled"
                status="info"
                onDismiss={() => setView(!view)}
              />
            ) : (
              ''
            )}
          </div>
          <Skeleton />
        </>
      ) : (
        <>
          <Stack vertical>
            <div
              style={{
                textAlign: 'left',
                marginLeft: '60px',
                // marginTop: '20px',
              }}
            />
            <div>
              <div id="headerNav">
                <NavLink
                  exact
                  id="NavLink"
                  to={`${match.path}/`}
                  // style={navStyle}
                  activeStyle={activeLink}
                >
                  <p>Reports</p>
                </NavLink>
                <NavLink
                  id="NavLink"
                  to={`${match.path}/CaseAndActions`}
                  // style={navStyle}
                  activeStyle={activeLink}
                >
                  <p>Case Management </p>
                </NavLink>
                <NavLink
                  id="NavLink"
                  to={`${match.path}/TakeActions`}
                  // style={navStyle}
                  activeStyle={activeLink}
                >
                  <p>Action Centre </p>
                </NavLink>

                <NavLink
                  id="NavLink"
                  to={`${match.path}/Settings`}
                  // style={navStyle}
                  activeStyle={activeLink}
                >
                  <p>Settings</p>
                </NavLink>
                <Link url="https://qrite.com/integrations" external removeUnderline>
                  Discover integrations
                </Link>
                <Link url="http://support.qrite.com/" external removeUnderline>
                  Help
                </Link>
              </div>
              <hr
                style={{
                  border: '0',
                  borderTop: '1px solid lightgray',
                  marginRight: '50px',
                  marginTop: '0',
                }}
              />
            </div>
          </Stack>

          <div>
            <Switch>
              {/* <PlanSelected.Provider value={CurrentPlan}> */}
              <Route path={`${match.path}`} exact component={Reports} />
              <Route path={`${match.path}/CaseAndActions`} component={CaseAndActions} />
              <Route path={`${match.path}/TakeActions`} component={TakeActions} />

              <Route path={`${match.path}/Settings`} component={Settings} />
              {/* </PlanSelected.Provider> */}
            </Switch>
          </div>
        </>
      )}
    </>
  );
};

export default HeaderNav;
