/* eslint-disable comma-dangle */
/* eslint-disable linebreak-style */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-sequences */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Stack,
  Checkbox,
  Popover,
  OptionList,
  ChoiceList,
  EmptyState,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import '../../../assets/styles/input.css';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const DownloadList = () => {
  const [selected, setSelected] = useState('');
  const [url, setUrl] = useState('');
  const [optionsSelected, setOptionsSelected] = useState('');

  const [popoverActive, setPopoverActive] = useState(true);
  const [checked, setChecked] = useState(false);
  // const [currentPlan, setCurrentPlan] = useState('');
  const handleChange = useCallback(
    (newChecked) => setChecked(newChecked),
    // setExclude(checked),
    []
  );
  // const currentPlanId = useContext(PlanSelected);
  const currentPlanId = 3;
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Choose List
    </Button>
  );
  async function downloadCustomerList(value) {
    const MERCHANT_ID = store.get('merchant_id');
    const DOWNLOAD_LIST = 'downloadCustomerList';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    if (value.includes('happy') && value.includes('unhappy') && value.includes('passives')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: true,
        passive: true,
        unhappy: true,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('happy') && value.includes('unhappy')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: true,
        passive: false,
        unhappy: true,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('happy') && value.includes('passives')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: true,
        passive: true,
        unhappy: false,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('unhappy') && value.includes('passives')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: false,
        passive: true,
        unhappy: true,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('unhappy')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: false,
        passive: false,
        unhappy: true,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('happy')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: true,
        passive: false,
        unhappy: false,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    } else if (value.includes('passives')) {
      const data = {
        merchant_id: parseInt(MERCHANT_ID),
        filename: 'customer_data',
        happy: false,
        passive: true,
        unhappy: false,
        exclude_previous: checked,
      };
      const response = await apiCaller(DOWNLOAD_LIST, headers, data);
      const URL = response.data.url;
      setUrl(URL);
    }
  }
  const handleSelect = useCallback((val) => (setSelected(val), downloadCustomerList(val), []));
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
  }
  useEffect(() => {
    GetSessionToken();
  }, []);
  return (
    <div>
      {currentPlanId === 3 ? (
        <Stack vertical>
          <Stack alignment="center">
            <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
              <OptionList
                onChange={setOptionsSelected}
                options={[
                  { value: 'SMS List', label: 'SMS List' },
                  { value: 'Email List', label: 'Email List' },
                ]}
                selected={optionsSelected}
                allowMultiple
              />
            </Popover>

            <Checkbox label="Exclude previous exported" checked={checked} onChange={handleChange} />
            {/* </div> */}
          </Stack>
          <ChoiceList
            allowMultiple
            choices={[
              {
                label: 'Happy',
                value: 'happy',
              },
              {
                label: 'Unhappy',
                value: 'unhappy',
              },
              {
                label: 'Passives',
                value: 'passives',
              },
            ]}
            selected={selected}
            onChange={handleSelect}
          />

          <Button disabled={url === ''} primary download>
            <a href={url} download style={{ textDecoration: 'none', color: 'white' }}>
              Download
            </a>
          </Button>
        </Stack>
      ) : (
        <EmptyState image={DisableEmo}>
          <p>This feature has been disabled because of plan selected</p>
        </EmptyState>
      )}
    </div>
  );
};

export default DownloadList;
