/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-const-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect } from 'react';
import { Stack, Card, TextStyle, Layout, Button, Heading, DisplayText } from '@shopify/polaris';
import { Redirect } from '@shopify/app-bridge/actions';
import { useHistory } from 'react-router-dom';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../store/CustomStore';
import apiCaller from '../../helper/API/apiCaller';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;

const data = [
  {
    amount: '',
    planName: 'Free',
    planHighlights: 'CSAT & NPS question.',
    offerings1: 'Template questions.',
    offerings2: 'Customization feedback email & sms.',
    offerings3: 'Thank you page - Shopify Checkout.',
    offerings4: 'Send text & emails*.',
    offerings5: 'Alert management.',
    offerings6: 'Basic Reports.',
    informations: '*pay as you go',
  },
  {
    amount: '',
    planName: 'Growth',
    planHighlights: 'All of free plan.',
    offerings1: 'Thank you page customization.',
    offerings2: 'Filter and analyzing tools.',
    offerings3: 'Reward feedback on thank you page.',
    offerings4: 'Ask automatically reviews to promoters.',
    offerings5: 'Customize review email and sms.',
    offerings6: '',
    informations: '',
  },
  {
    amount: '',
    planName: 'Advanced',
    planHighlights: 'All of growth plan.',
    offerings1: 'Need care e-mails.',
    offerings2: 'Download lists.',
    offerings3: 'Advanced reporting, feedback per:',
    offerings4: '- product',
    offerings5: '- product category',
    offerings6: '- order growth',
    offerings7: '- product option',
    offerings8: '- channel (sms, email and thank you page)',
    offerings9: '- Geolocation',
    informations: '',
  },
];

const ChoosePlan = () => {
  const history = useHistory();

  async function paymentRedirect(url) {
    // await window.open(url);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });

    const redirect = Redirect.create(app);
    // Removed widnow.location with shopify provided redirect
    redirect.dispatch(Redirect.Action.REMOTE, url);
  }

  async function setPlan(id) {
    // store.set('planSelected', plan);
    // store.set('planSelectedID', id);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    const SHOPNAME = store.get('shop-name');
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    // !redirected;
    const url = 'setMerchantPlan';

    const data = {
      shop_id: SHOPNAME,
      plan_id: id,
    };
    await apiCaller(url, headers, data)
      .then((response) => {
        const { url } = response.data;

        paymentRedirect(url);
      })
      .catch(() => {});
  }

  async function checkChargeId(ID, host) {
    console.log('INSIDE CONFIRM PAYMENT', ID, host);
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const MERCHANT_ID = store.get('merchant_id');
    const CONFIRM_PAYMENT_URL = 'confirmPayment';
    const data = {
      merchant_id: MERCHANT_ID,
      shopify_payment_id: ID,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    apiCaller(CONFIRM_PAYMENT_URL, headers, data)
      .then((response) => {
        const { message } = response.data;
        if (message === 'verified') {
          store.remove('chargeID');
          history.push('/wizard');
        }
      })
      .catch(() => {});
    store.remove('chargeID');
  }
  async function getplans(sessionToken) {
    const url = 'getPlans';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const SHOPNAME = store.get('shop-name');

    // <-----API CALLLER------->
    await apiCaller(url, headers, SHOPNAME).then((response) => {
      const details = response.data;

      for (const key in details) {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          // eslint-disable-next-line eqeqeq
          if (details[key].name.toLowerCase() == item.planName.toLowerCase()) {
            item.planId = details[key].id;
            item.price = details[key].price;
          }
        });
      }
    });
  }
  async function GetSessionToken(host) {
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    if (sessionToken) {
      getplans(sessionToken);
    }
  }

  useEffect(() => {
    const host = store.get('HOST');
    const ID = store.get('chargeID');
    // console.log('ID  CONFIRM PAYMENT', ID);
    if (host) {
      GetSessionToken(host);
      if (ID) {
        checkChargeId(ID, host);
      }
    }
  }, []);

  return (
    <Layout>
      {data.map((planItem) => (
        <Layout.Section oneThird>
          <div key={planItem.planId} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Card
              key={planItem.planId}
              sectioned
              title={
                <Stack vertical spacing="loose">
                  <Stack spacing="extraTight">
                    <Stack.Item>USD</Stack.Item>

                    <DisplayText>
                      <h1 style={{ fontWeight: 'bold' }}> {planItem.price}/month </h1>
                    </DisplayText>
                  </Stack>

                  <Button
                    primary
                    // disabled={disabled}
                    onClick={() => setPlan(planItem.planId, planItem.planName)}
                  >
                    Choose this Plan
                  </Button>

                  <Stack vertical>
                    <Heading>{planItem.planName}</Heading>
                    <p>{planItem.planHighlights}</p>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings1}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings2}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings3}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings4}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings5}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings6}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings7}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings8}</p>
                    </TextStyle>
                    <TextStyle variation="subdued">
                      <p>{planItem.offerings9}</p>
                    </TextStyle>
                    <TextStyle variation="positive">
                      <p>{planItem.informations}</p>
                    </TextStyle>
                  </Stack>
                </Stack>
              }
            />
          </div>
        </Layout.Section>
      ))}
    </Layout>
  );
};

export default ChoosePlan;
