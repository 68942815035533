import React from 'react';

import {

  Stack,

} from '@shopify/polaris';

const NPSTiles = () => (
  <Stack wrap={false} distribution="equalSpacing">
    {/* <NPSTiles /> */}
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14 20.2891C16.7891 20.2891 18.4766 18.0078 18.4766 14.3672V14.3516C18.4766 10.7031 16.7891 8.4375 14 8.4375C11.2188 8.4375 9.52344 10.7031 9.52344 14.3516V14.3672C9.52344 18.0078 11.2188 20.2891 14 20.2891ZM14 18.4219C12.6719 18.4219 11.9141 16.9141 11.9141 14.3672V14.3516C11.9141 11.7969 12.6719 10.3047 14 10.3047C15.3359 10.3047 16.0938 11.7969 16.0938 14.3516V14.3672C16.0938 16.9141 15.3359 18.4219 14 18.4219Z"
          fill="black"
        />
      </svg>
      <svg
        width="6"
        height="2"
        viewBox="0 0 6 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.703125 1.58203H5.29688V0.474609H0.703125V1.58203Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M13.8047 20H16.1641V8.72656H13.8125L10.8984 10.75V12.875L13.6641 10.9531H13.8047V20Z"
          fill="black"
        />
      </svg>
      <svg
        width="6"
        height="2"
        viewBox="0 0 6 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.703125 1.58203H5.29688V0.474609H0.703125V1.58203Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M9.99219 20H18.125V18.1016H13.1562V17.9219L15.2422 15.9766C17.2969 14.0781 17.9609 13.0156 17.9609 11.7344V11.7109C17.9609 9.76562 16.3359 8.4375 14.0156 8.4375C11.5469 8.4375 9.85938 9.92188 9.85938 12.0859L9.86719 12.1172H12.0469V12.0781C12.0469 11.0078 12.8047 10.2734 13.9062 10.2734C14.9844 10.2734 15.6406 10.9453 15.6406 11.9062V11.9297C15.6406 12.7188 15.2109 13.2891 13.625 14.8281L9.99219 18.4141V20Z"
          fill="black"
        />
      </svg>
      <svg
        width="5"
        height="10"
        viewBox="0 0 5 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.2666 10H4.49707V0.135742H3.27344L0.648438 2.02246V3.32129L3.15723 1.50293H3.2666V10Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14 20.25C16.6328 20.25 18.3906 18.8672 18.3906 16.8203V16.8047C18.3906 15.2734 17.2969 14.3047 15.6172 14.1484V14.1016C16.9375 13.8281 17.9688 12.9141 17.9688 11.5078V11.4922C17.9688 9.69531 16.4062 8.47656 13.9844 8.47656C11.6172 8.47656 10.0312 9.78906 9.86719 11.8047L9.85938 11.8984H12.0312L12.0391 11.8281C12.1328 10.8906 12.875 10.2891 13.9844 10.2891C15.0938 10.2891 15.7422 10.8672 15.7422 11.8047V11.8203C15.7422 12.7344 14.9766 13.3594 13.7891 13.3594H12.5312V15.0391H13.8203C15.1875 15.0391 15.9922 15.6328 15.9922 16.7109V16.7266C15.9922 17.6797 15.2031 18.3516 14 18.3516C12.7812 18.3516 11.9766 17.7266 11.875 16.8516L11.8672 16.7656H9.60938L9.61719 16.8672C9.77344 18.8828 11.4531 20.25 14 20.25Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.875977 11H7.26074V9.89258H2.58496V9.7832L4.82715 7.46582C6.61133 5.62695 7.09668 4.80664 7.09668 3.67871V3.66504C7.09668 2.07227 5.77734 0.90332 4.05469 0.90332C2.16797 0.90332 0.814453 2.16113 0.807617 3.91113L0.821289 3.91797L1.99707 3.9248L2.00391 3.91113C2.00391 2.74902 2.79004 1.97656 3.97266 1.97656C5.13477 1.97656 5.83887 2.75586 5.83887 3.79492V3.80859C5.83887 4.66992 5.46973 5.18262 4.21191 6.54297L0.875977 10.1523V11Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14.8672 20H17.1172V17.9297H18.5859V16.0391H17.1172V8.72656H13.8047C12.3047 11.0391 10.7734 13.5391 9.34375 16.0781V17.9297H14.8672V20ZM11.4688 16.1016V15.9766C12.4844 14.1562 13.6953 12.1953 14.7891 10.5156H14.9141V16.1016H11.4688Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.875977 11H7.26074V9.89258H2.58496V9.7832L4.82715 7.46582C6.61133 5.62695 7.09668 4.80664 7.09668 3.67871V3.66504C7.09668 2.07227 5.77734 0.90332 4.05469 0.90332C2.16797 0.90332 0.814453 2.16113 0.807617 3.91113L0.821289 3.91797L1.99707 3.9248L2.00391 3.91113C2.00391 2.74902 2.79004 1.97656 3.97266 1.97656C5.13477 1.97656 5.83887 2.75586 5.83887 3.79492V3.80859C5.83887 4.66992 5.46973 5.18262 4.21191 6.54297L0.875977 10.1523V11Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14.1016 20.2891C16.6328 20.2891 18.3828 18.6719 18.3828 16.2969V16.2812C18.3828 14.1328 16.8672 12.5859 14.7656 12.5859C13.6875 12.5859 12.8125 13.0547 12.3594 13.8125H12.2188L12.5 10.6016H17.7109V8.72656H10.6641L10.1406 15.3984H12.1641C12.2891 15.1562 12.4766 14.9453 12.6875 14.7734C13.0625 14.4531 13.5469 14.2891 14.1172 14.2891C15.3125 14.2891 16.1641 15.125 16.1641 16.3281V16.3438C16.1641 17.5547 15.3203 18.4062 14.1094 18.4062C13.0469 18.4062 12.2734 17.7422 12.1094 16.9141L12.0938 16.8594H9.95312L9.96094 16.9688C10.0938 18.8516 11.6875 20.2891 14.1016 20.2891Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="10"
        viewBox="0 0 8 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.10059 10H6.30371V7.95605H7.70508V6.85547H6.30371V0.135742H4.51953C3.13867 2.19336 1.62793 4.59961 0.294922 6.83496V7.95605H5.10059V10ZM1.55273 6.8623V6.78027C2.62598 4.96875 3.91797 2.93164 5.02539 1.27734H5.10742V6.8623H1.55273Z"
          fill="black"
        />
      </svg>
    </Stack>

    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14.1641 20.2891C16.7031 20.2891 18.4844 18.5938 18.4844 16.2891V16.2734C18.4844 14.1406 17 12.5781 14.8047 12.5781C13.25 12.5781 12.3047 13.3516 11.8984 14.2266H11.7422C11.7422 14.1406 11.7422 14.0547 11.75 13.9688C11.8359 11.9219 12.5469 10.3203 14.1797 10.3203C15.0938 10.3203 15.7344 10.7969 16 11.5L16.0312 11.5703H18.3359L18.3203 11.4922C17.9766 9.72656 16.3516 8.4375 14.1719 8.4375C11.2891 8.4375 9.50781 10.6797 9.50781 14.4531V14.4688C9.50781 18.4375 11.5469 20.2891 14.1641 20.2891ZM12.0938 16.3203V16.3125C12.0938 15.1484 13 14.3125 14.1641 14.3125C15.3438 14.3125 16.2031 15.1719 16.2031 16.3516V16.3672C16.2031 17.5234 15.2891 18.4219 14.1484 18.4219C13 18.4219 12.0938 17.5078 12.0938 16.3203Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.01367 10.2324C6.0166 10.2324 7.44531 8.84473 7.44531 6.85547V6.8418C7.44531 4.93457 6.1123 3.54688 4.25977 3.54688C3.36426 3.54688 2.5918 3.90918 2.1543 4.57227H2.04492L2.37988 1.23633H6.89844V0.135742H1.40918L0.889648 5.80273H1.95605C2.0791 5.57031 2.23633 5.37891 2.40723 5.21484C2.83105 4.81152 3.3916 4.61328 4.04785 4.61328C5.32617 4.61328 6.24219 5.5498 6.24219 6.8623V6.87598C6.24219 8.20898 5.33984 9.14551 4.02734 9.14551C2.87207 9.14551 2.01758 8.39355 1.90137 7.4502L1.89453 7.39551H0.711914L0.71875 7.4707C0.862305 9.0498 2.1748 10.2324 4.01367 10.2324Z"
          fill="black"
        />
      </svg>
    </Stack>

    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M10.8047 20H13.2734L17.9844 10.625V8.71875H9.92188V10.6016H15.6094V10.7344L10.8047 20Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="11"
        viewBox="0 0 8 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.01367 10.2324C6.0166 10.2324 7.44531 8.84473 7.44531 6.85547V6.8418C7.44531 4.93457 6.1123 3.54688 4.25977 3.54688C3.36426 3.54688 2.5918 3.90918 2.1543 4.57227H2.04492L2.37988 1.23633H6.89844V0.135742H1.40918L0.889648 5.80273H1.95605C2.0791 5.57031 2.23633 5.37891 2.40723 5.21484C2.83105 4.81152 3.3916 4.61328 4.04785 4.61328C5.32617 4.61328 6.24219 5.5498 6.24219 6.8623V6.87598C6.24219 8.20898 5.33984 9.14551 4.02734 9.14551C2.87207 9.14551 2.01758 8.39355 1.90137 7.4502L1.89453 7.39551H0.711914L0.71875 7.4707C0.862305 9.0498 2.1748 10.2324 4.01367 10.2324Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M14 20.25C16.7109 20.25 18.5781 18.8984 18.5781 16.9453V16.9297C18.5781 15.4609 17.4766 14.3672 15.9453 14.0703V14.0234C17.2578 13.6953 18.1641 12.75 18.1641 11.5234V11.5078C18.1641 9.75781 16.4453 8.47656 14 8.47656C11.5469 8.47656 9.83594 9.75781 9.83594 11.5078V11.5234C9.83594 12.75 10.7344 13.7031 12.0547 14.0234V14.0703C10.5156 14.375 9.42188 15.4609 9.42188 16.9297V16.9453C9.42188 18.8984 11.2891 20.25 14 20.25ZM14 13.3125C12.9141 13.3125 12.1641 12.6719 12.1641 11.75V11.7344C12.1641 10.8125 12.9219 10.1797 14 10.1797C15.0703 10.1797 15.8281 10.8125 15.8281 11.7344V11.75C15.8281 12.6719 15.0781 13.3125 14 13.3125ZM14 18.5312C12.7578 18.5312 11.8828 17.8047 11.8828 16.7891V16.7734C11.8828 15.7578 12.75 15.0234 14 15.0234C15.25 15.0234 16.1094 15.7578 16.1094 16.7734V16.7891C16.1094 17.8047 15.2344 18.5312 14 18.5312Z"
          fill="black"
        />
      </svg>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.12988 11.2324C6.11914 11.2324 7.58203 9.80371 7.58203 7.83496V7.82129C7.58203 5.92773 6.20117 4.51953 4.34863 4.51953C3.01562 4.51953 2.08594 5.2373 1.72363 6.04395H1.59375C1.59375 5.96875 1.59375 5.88672 1.60059 5.81152C1.67578 3.75391 2.40723 2.00391 4.19824 2.00391C5.19629 2.00391 5.88672 2.5166 6.1875 3.34375L6.21484 3.41895H7.45215L7.43164 3.33008C7.09668 1.87402 5.86621 0.90332 4.21191 0.90332C1.81934 0.90332 0.417969 2.87207 0.417969 6.23535V6.24902C0.417969 9.96094 2.3252 11.2324 4.12988 11.2324ZM1.8877 7.82129V7.81445C1.8877 6.5293 2.8584 5.59277 4.13672 5.59277C5.41504 5.59277 6.34473 6.53613 6.34473 7.84863V7.8623C6.34473 9.14062 5.35352 10.1455 4.11621 10.1455C2.86523 10.1455 1.8877 9.12012 1.8877 7.82129Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M13.8438 8.42969C11.3047 8.42969 9.52344 10.1328 9.52344 12.4375V12.4531C9.52344 14.5859 11 16.1484 13.1953 16.1484C14.7578 16.1484 15.6953 15.3672 16.1016 14.5H16.2578C16.2578 14.5859 16.2578 14.6719 16.2578 14.75C16.1719 16.8047 15.4531 18.4062 13.8203 18.4062C12.9141 18.4062 12.2734 17.9297 12 17.2266L11.9766 17.1562H9.66406L9.67969 17.2266C10.0312 18.9922 11.6484 20.2812 13.8281 20.2812C16.7188 20.2812 18.5 18.0391 18.5 14.2656V14.25C18.5 10.2891 16.4531 8.42969 13.8438 8.42969ZM13.8438 14.4062C12.6641 14.4062 11.8047 13.5547 11.8047 12.3672V12.3516C11.8047 11.2031 12.7188 10.3047 13.8594 10.3047C15.0078 10.3047 15.9062 11.2188 15.9062 12.3984V12.4141C15.9062 13.5781 15.0078 14.4062 13.8438 14.4062Z"
          fill="black"
        />
      </svg>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.85742 11H4.08789V1.13574H2.86426L0.239258 3.02246V4.32129L2.74805 2.50293H2.85742V11ZM10.2402 11.2324C12.4209 11.2324 13.7334 9.24316 13.7334 6.07129V6.05762C13.7334 2.88574 12.4209 0.90332 10.2402 0.90332C8.05957 0.90332 6.76074 2.88574 6.76074 6.05762V6.07129C6.76074 9.24316 8.05957 11.2324 10.2402 11.2324ZM10.2402 10.1592C8.8252 10.1592 7.99805 8.58691 7.99805 6.07129V6.05762C7.99805 3.54199 8.8252 1.9834 10.2402 1.9834C11.6553 1.9834 12.4961 3.54199 12.4961 6.05762V6.07129C12.4961 8.58691 11.6553 10.1592 10.2402 10.1592Z"
          fill="black"
        />
      </svg>
    </Stack>
    <Stack vertical alignment="center">
      <svg
        width="20"
        height="20"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
          fill="#F2F7FE"
        />
        <path
          d="M8.32812 20H10.6875V8.72656H8.33594L5.42188 10.75V12.875L8.1875 10.9531H8.32812V20ZM18.0938 20.2891C20.8828 20.2891 22.5703 18.0078 22.5703 14.3672V14.3516C22.5703 10.7031 20.8828 8.4375 18.0938 8.4375C15.3125 8.4375 13.6172 10.7031 13.6172 14.3516V14.3672C13.6172 18.0078 15.3125 20.2891 18.0938 20.2891ZM18.0938 18.4219C16.7656 18.4219 16.0078 16.9141 16.0078 14.3672V14.3516C16.0078 11.7969 16.7656 10.3047 18.0938 10.3047C19.4297 10.3047 20.1875 11.7969 20.1875 14.3516V14.3672C20.1875 16.9141 19.4297 18.4219 18.0938 18.4219Z"
          fill="black"
        />
      </svg>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.61133 11.1641C5.58691 11.1641 7.03613 9.94727 7.03613 8.2998V8.28613C7.03613 6.88477 6.05859 5.98926 4.62305 5.86621V5.83887C5.85352 5.5791 6.71484 4.74512 6.71484 3.52832V3.51465C6.71484 2.01758 5.47754 0.97168 3.59766 0.97168C1.75195 0.97168 0.480469 2.04492 0.323242 3.65137L0.316406 3.71973H1.49902L1.50586 3.65137C1.6084 2.65332 2.43555 2.03809 3.59766 2.03809C4.80078 2.03809 5.47754 2.63281 5.47754 3.66504V3.67871C5.47754 4.66309 4.65723 5.3877 3.48145 5.3877H2.29883V6.42676H3.53613C4.91699 6.42676 5.78516 7.10352 5.78516 8.31348V8.32715C5.78516 9.37305 4.90332 10.0977 3.61133 10.0977C2.29883 10.0977 1.40332 9.42773 1.30762 8.45703L1.30078 8.38867H0.118164L0.125 8.4707C0.254883 10.0293 1.57422 11.1641 3.61133 11.1641ZM12.5186 11.2324C14.5078 11.2324 15.9707 9.80371 15.9707 7.83496V7.82129C15.9707 5.92773 14.5898 4.51953 12.7373 4.51953C11.4043 4.51953 10.4746 5.2373 10.1123 6.04395H9.98242C9.98242 5.96875 9.98242 5.88672 9.98926 5.81152C10.0645 3.75391 10.7959 2.00391 12.5869 2.00391C13.585 2.00391 14.2754 2.5166 14.5762 3.34375L14.6035 3.41895H15.8408L15.8203 3.33008C15.4854 1.87402 14.2549 0.90332 12.6006 0.90332C10.208 0.90332 8.80664 2.87207 8.80664 6.23535V6.24902C8.80664 9.96094 10.7139 11.2324 12.5186 11.2324ZM10.2764 7.82129V7.81445C10.2764 6.5293 11.2471 5.59277 12.5254 5.59277C13.8037 5.59277 14.7334 6.53613 14.7334 7.84863V7.8623C14.7334 9.14062 13.7422 10.1455 12.5049 10.1455C11.2539 10.1455 10.2764 9.12012 10.2764 7.82129Z"
          fill="black"
        />
      </svg>
    </Stack>
  </Stack>
);

export default NPSTiles;
