/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { EmptyState, Page } from '@shopify/polaris';

import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';

const DisabledAutoReview = () => {
  return (
    <Page>
      <EmptyState image={DisableEmo}>
        <p>This feature has been disabled because of plan selected</p>
        <p>Please toggle to the Next step by clicking on the Sidenav Option</p>
      </EmptyState>
    </Page>
  );
};

export default DisabledAutoReview;
