/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect } from 'react';
import { TextStyle, Stack, Button, Heading, SettingToggle, TextContainer } from '@shopify/polaris';

import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../store/CustomStore';
import NeedCare from './NeedCare';
import DisabledAddlogo from './Disabled/DisabledAddlogo';
import apiCaller from '../../helper/API/apiCaller';
import imageUploader from '../../helper/API/imageUploader';
import '../../helper/imageUploader/imageuploader.css';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const AddLogo = (props) => {
  const [nav, setNav] = useState(false);
  const [uploadUrl, setUploadUrl] = useState('');
  const [logo, setLogo] = useState('');

  const [active, setActive] = useState(true);
  // const [currentPlan, setCurrentPlan] = useState('');
  var SubscriptionId = props.planId;
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  // const SubscriptionId = useContext(PlanSelected);
  const [baseImage, setBaseImage] = useState('');
  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    const base64 = await convertBase64(file);
    setBaseImage(base64);
    setLogo(file);
  };

  const contentStatus = active ? 'Disable' : ' Enable';
  const textStatus = active ? ' Enabled' : ' Disabled';

  async function navigate() {
    const headers = {
      'Content-Type': 'image/jpeg',
      Authorizer: sessionToken,
    };
    console.log('UPLOAD URL', uploadUrl);
    store.set('uploadS3url', uploadUrl);

    imageUploader(uploadUrl, headers, logo);

    setNav(!nav);
    props.changeColor('Need Care');
  }

  async function getImageUrl(sessionToken) {
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    // const SHOPNAME = store.get('shop-name');
    const MerchantId = store.get('merchant_id');
    const url = 'getLogoUrl';
    const data = {
      merchant_id: MerchantId,
      format: 'jpg',
      is_upload: true,
    };

    apiCaller(url, headers, data).then((response) => {
      console.log('RESPONSE', response);
      setUploadUrl(response.data);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getImageUrl(sessionToken);
  }
  useEffect(() => {
    // const planData = store.get('planSelected');
    // setCurrentPlan(planData);
    // // SubscriptionId = props.planId;
    // console.log('REVIEWING:', SubscriptionId);
    // console.log('REVIEWING:', SubscriptionId);

    GetSessionToken();
  }, []);

  return (
    <>
      {SubscriptionId !== 1 ? (
        <>
          {' '}
          {nav === false ? (
            <div
              style={{
                padding: '20px',
                width: '100%',
              }}
            >
              <TextContainer spacing="loose">
                <Heading>Upload Logo</Heading>
                <TextStyle variation="subdued">
                  <p style={{ fontSize: '12px' }}>Make your customers feel at home.</p>
                </TextStyle>
              </TextContainer>

              <div
                style={{
                  marginTop: '20px',
                }}
              >
                <SettingToggle enabled={active}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p>Add Logo : &nbsp;</p>
                      <TextStyle variation="strong">{textStatus}</TextStyle>.
                    </div>
                    {active ? (
                      <div style={{ color: '#bf0711' }}>
                        <Button onClick={handleToggle}>{contentStatus}</Button>
                      </div>
                    ) : !active ? (
                      <Button primary onClick={handleToggle}>
                        {contentStatus}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </SettingToggle>
              </div>
              {!active ? (
                <Stack distribution="trailing">
                  <div style={{ marginTop: '100px' }}>
                    <Button primary onClick={() => navigate()}>
                      Next: Need Care
                    </Button>
                  </div>
                </Stack>
              ) : (
                <Stack vertical distribution="equalSpacing">
                  <div style={{ marginTop: '20px' }}>
                    <div className="upload-btn-wrapper">
                      <Stack vertical>
                        <Button size="slim" type="button" className="btn">
                          Upload{' '}
                        </Button>
                        <input
                          type="file"
                          name="myfile"
                          accept=".jpg, .png, .jpeg"
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                        />
                        {logo !== '' ? (
                          <img src={baseImage} style={{ width: '400px' }} alt="logo" />
                        ) : (
                          ''
                        )}
                      </Stack>
                    </div>

                    <Stack distribution="trailing">
                      <div style={{ marginTop: '20px' }}>
                        <Button primary onClick={() => navigate()}>
                          Next: Need Care
                        </Button>
                      </div>
                    </Stack>
                  </div>
                </Stack>
              )}
            </div>
          ) : nav === true ? (
            <NeedCare />
          ) : (
            ''
          )}
        </>
      ) : (
        <DisabledAddlogo />
      )}
    </>
  );
};

export default AddLogo;
