/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Card, Icon, Stack, Heading, DisplayText,
} from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import emo1 from '../../../assets/emos/Sad.svg';
import emo2 from '../../../assets/emos/ok.svg';
import emo3 from '../../../assets/emos/oh.svg';
import emo4 from '../../../assets/emos/happy.svg';
import emo5 from '../../../assets/emos/amazing.svg';
// import '../../../assets/styles/ProComponentStyles.css';

const data = [
  {
    index: '0',
    emogi: emo1,
    value: '2%',
  },
  {
    index: '1',
    emogi: emo2,
    value: '5%',
  },
  {
    index: '2',
    emogi: emo3,
    value: '6%',
  },
  {
    index: '3',
    emogi: emo4,
    value: '25%',
  },
  {
    index: '4',
    emogi: emo5,
    value: '62%',
  },
];
const CSATFeedback = ({ score }) => (
  <>
    <div id="#CSAT-Container">
      <Card
        sectioned
        title={(
          <Stack vertical>
            <Stack alignment="baseline">
              <Heading>CSAT feedback</Heading>
              <Icon source={InfoMinor} color="subdued" />
            </Stack>

            <DisplayText>{ score }</DisplayText>
            <Stack alignment="baseline" distribution="fillEvenly" spacing="loose">
              {data.map((data) => (
                <Stack
                  vertical
                  alignment="leading"
                  distribution="leading"
                  spacing="tight"
                  key={data.index}
                >
                  <img alt="emos" src={data.emogi} />
                  <Heading>{data.value}</Heading>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      />
    </div>
  </>
);

export default CSATFeedback;
