/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  Navigation, Card, Page, Icon, TextStyle, Stack,
} from '@shopify/polaris';
import {
  ChecklistMajor,
  DiscountsMajor,
  HeartMajor,
  ChecklistAlternateMajor,
  DraftOrdersMajor,
  FormsMajor,
} from '@shopify/polaris-icons';
import FeedbackChannel from './FeedBackChannel';
import Question from './Questions';
import AddLogo from './AddLogo';
import NeedCare from './NeedCare';
import AutoReview from './AutoReview';
import RewardFeedback from './RewardFeedback';
// import DisabledAddlogo from './Disabled/DisabledAddlogo';
// import DisabledNeedCare from './Disabled/DisabledNeedCare';
// import DisabledAutoReview from './Disabled/DisabledAutoReview';
// import DisabledRewardFeedback from './Disabled/DisabledRewardFeedback';
import Header from '../../assets/QriteHeader/Header';
// import { PlanSelected } from '../../helper/context/planSelected';
// import Subscription from '../../Pages/Subscription';
import store from '../../store/CustomStore';

const items = [
  {
    label: 'Feedback Channel',
    icon: DraftOrdersMajor,
  },
  {
    label: 'Questions',
    icon: ChecklistAlternateMajor,
  },
  {
    label: 'Add Logo',
    icon: ChecklistMajor,
  },
  {
    label: 'Need Care',
    icon: HeartMajor,
  },
  {
    label: 'Auto Review',
    icon: FormsMajor,
  },
  {
    label: 'Reward Feedback',
    icon: DiscountsMajor,
  },
];
const Sidenav = () => {
  const [active, setActive] = useState('Feedback Channel');
  // const [current, setCurrent] = useState('');
  // const SubscriptionId = useContext(PlanSelected);
  // const SubscriptionId = 3;
  const Subscription = store.get('planSelectedID');

  function toggle(value) {
    setActive(value);
    // setCurrent(value);
  }

  const styles = {
    background: '#f2f0f0',
    padding: ' 15px 20px ',

    cursor: 'pointer',
    textAlign: 'left',
    border: '0px',
    boxShadow: '0 0 4px #e4e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    color: 'black',
  };

  const activeStyles = {
    background: 'white',
    padding: ' 15px 20px ',

    cursor: 'pointer',
    textAlign: 'left',
    border: 'none',
    boxShadow: '0 0 4px #e4e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
    color: '#007F5F',
    borderLeft: '3px solid green',
  };
  // useEffect(() => {
  //   Subscription = store.get('planSelectedID');
  // }, []);

  return (
    <Page>
      <Header />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '800px' }}>
          <Card>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  backgroundColor: '#f2f0f0',
                  borderRadius: '10px 0px 0px 10px',
                }}
              >
                <Navigation>
                  {items.map((item) => (
                    <button
                      style={active === item.label ? activeStyles : styles}
                      onClick={() => toggle(item.label)}
                      key={item.label}
                    >
                      <Stack>
                        <Icon
                          source={item.icon}
                          color={active === item.label ? 'success' : 'default'}
                          backdrop={false}
                        />
                        <h1 style={{ marginLeft: '10px' }}>
                          <TextStyle variation="strong">{item.label}</TextStyle>
                        </h1>
                      </Stack>
                    </button>
                  ))}
                </Navigation>
              </div>
              {/* Switching tabs based on nested Ternary operator */}
              {active == 'Feedback Channel' ? (
                <FeedbackChannel changeColor={(value) => setActive(value)} />
              ) : active == 'Questions' ? (
                <Question changeColor={(value) => setActive(value)} />
              ) : active === 'Add Logo' ? (
                <AddLogo changeColor={(value) => setActive(value)} planId={Subscription} />
              ) : active === 'Need Care' ? (
                <NeedCare changeColor={(value) => setActive(value)} planId={Subscription} />
              ) : active === 'Auto Review' ? (
                <AutoReview changeColor={(value) => setActive(value)} planId={Subscription} />
              ) : active === 'Reward Feedback' ? (
                <RewardFeedback planId={Subscription} />
              ) : (
                ''
              )}
              {/* {SubscriptionId === 1 && active === 'Feedback Channel' ? (
                <FeedbackChannel changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 1 && active === 'Questions' ? (
                <Question changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 1 && active === 'Add Logo' ? (
                <DisabledAddlogo
                  changeColor={(value) => setActive(value)}
                />
              ) : SubscriptionId === 1 && active === 'Need Care' ? (
                <DisabledNeedCare changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 1 && active === 'Auto Review' ? (
                <DisabledAutoReview changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 1 && active === 'Reward Feedback' ? (
                <DisabledRewardFeedback />
              ) : (
                ''
              )}
              {SubscriptionId === 2 && active === 'Feedback Channel' ? (
                <FeedbackChannel changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 2 && active === 'Questions' ? (
                <Question changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 2 && active === 'Add Logo' ? (
                <AddLogo changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 2 && active === 'Need Care' ? (
                <DisabledNeedCare changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 2 && active === 'Auto Review' ? (
                <AutoReview changeColor={(value) => setActive(value)} />
              ) : SubscriptionId === 2 && active === 'Reward Feedback' ? (
                <RewardFeedback />
              ) : (
                ''
              )}
              {SubscriptionId === 3 && active === 'Feedback Channel' ? (
                <FeedbackChannel changeColor={(value) => setActive(value)} />
              ) // using changeColor we are lifting state up from child to parent
                : SubscriptionId === 3 && active === 'Questions' ? (
                  <Question changeColor={(value) => setActive(value)} />
                ) : SubscriptionId === 3 && active === 'Add Logo' ? (
                  <AddLogo changeColor={(value) => setActive(value)} />
                ) : SubscriptionId === 3 && active === 'Need Care' ? (
                  <NeedCare changeColor={(value) => setActive(value)} />
                ) : SubscriptionId === 3 && active === 'Auto Review' ? (
                  <AutoReview changeColor={(value) => setActive(value)} />
                ) : SubscriptionId === 3 && active === 'Reward Feedback' ? (
                  <RewardFeedback />
                ) : (
                  ''
                )} */}
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default Sidenav;
