/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
import {
  Card, Tabs, Heading, EmptyState, Stack, TextStyle,
} from '@shopify/polaris';
import PerProductGraph from './ProComponent/Graphs/PerProductGraph';
import ProductGraph from './ProComponent/Graphs/ProductGraph';
import OrderSizeGraph from './ProComponent/Graphs/OrderSize';
import ChannelTypeGraph from './ProComponent/Graphs/ChannelTypeGraph';
import GeoGraph from './ProComponent/Graphs/GeoGraph';
import ProductOption from './ProComponent/Graphs/ProductOption';
import store from '../../../store/CustomStore';

const ProComponent = () => {
  // const [subscription, setSubscription] = useState('');
  // console.log('pro:', match);
  const [selected, setSelected] = useState(0);
  const subscription = store.get('planSelectedID');

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  // const subscribed = false;
  const lock = (
    <svg width="44" height="60" viewBox="0 0 44 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31 15C31 10.037 26.963 6 22 6C17.037 6 13 10.037 13 15V23H31V15ZM37 23V15C37 6.716 30.284 0 22 0C13.716 0 7 6.716 7 15V23H2C0.895 23 0 23.895 0 25V45C0 53.284 6.716 60 15 60H29C37.284 60 44 53.284 44 45V25C44 23.895 43.105 23 42 23H37ZM22 50C20.343 50 19 48.657 19 47V35C19 33.343 20.343 32 22 32C23.657 32 25 33.343 25 35V47C25 48.657 23.657 50 22 50Z"
        fill="#8C9196"
      />
    </svg>
  );

  const tabs = [
    {
      id: 'product',
      content: 'Product',
      accessibilityLabel: 'All products',
      comp: <ProductGraph />,
    },
    {
      id: 'product_category',
      content: 'Product Category',
      comp: <PerProductGraph />,
    },
    {
      id: 'order_size',
      content: 'Order Size',
      comp: <OrderSizeGraph />,
    },
    {
      id: 'product_option',
      content: 'Product Option',
      comp: <ProductOption />,
    },
    {
      id: 'channel_type',
      content: 'Channel Type',
      comp: <ChannelTypeGraph />,
    },
    {
      id: 'geo',
      content: 'Geo',
      comp: <GeoGraph />,
    },
  ];

  return (
    <div style={{ marginTop: '80px' }}>
      <Stack vertical>
        <Stack.Item>
          <Heading>Advanced reporting</Heading>
          <TextStyle variation="subdued">
            Learn more about the feedback related to the product, order size, related categories and
            where your happy consumers live.
          </TextStyle>
        </Stack.Item>
        <Stack.Item>
          {' '}
          <Card>
            <div>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                {subscription !== 3 ? (
                  <EmptyState
                    image={lock}
                    heading={(
                      <Stack vertical>
                        {lock}
                        <Heading>Upgrade to unlock Advanced analytics</Heading>
                      </Stack>
                    )}
                    action={{ content: 'Upgrade to Advanced Plan' }}
                  />
                ) : (
                  <>{tabs[selected].comp}</>
                )}
              </Tabs>
            </div>
          </Card>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default ProComponent;
