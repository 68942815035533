/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Select,
  useIndexResourceState,
  Card,
  IndexTable,
  TextStyle,
  Stack,
  Frame,
} from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import Pagination from '../../../helper/Pagination';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const ManualRevewTable = () => {
  const [selected, setSelected] = useState('-');
  const [option2, setOptions2] = useState('');
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSubOptionsChange = useCallback((value) => setOptions2(value), []);
  const resourceName = {
    singular: 'customer',
    plural: 'customers',
  };
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    // GetCustomersList(sessionToken);
  }
  async function getCustomer() {
    await GetSessionToken();
    const url = 'getCustomer';

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const MERCHANT_ID = store.get('merchant_id');
    // if (selected == '-') {
    const data = { merchant_id: parseInt(MERCHANT_ID) };
    await apiCaller(url, headers, data).then((response) => {
      const CUSTOMERS_DATA = response.data;
      setCustomers(CUSTOMERS_DATA);
    });
    // } else
    if (selected == 'happy') {
      const data = { merchant_id: parseInt(MERCHANT_ID), happy: true };
      await apiCaller(url, headers, data).then((response) => {
        const CUSTOMERS_DATA = response.data;
        setCustomers(CUSTOMERS_DATA);
      });
    } else if (selected == 'unhappy') {
      const data = { merchant_id: parseInt(MERCHANT_ID), unhappy: true };
      await apiCaller(url, headers, data).then((response) => {
        const CUSTOMERS_DATA = response.data;
        setCustomers(CUSTOMERS_DATA);
      });
    } else if (selected == 'passive') {
      const data = { merchant_id: parseInt(MERCHANT_ID), passive: true };
      await apiCaller(url, headers, data).then((response) => {
        const CUSTOMERS_DATA = response.data;
        setCustomers(CUSTOMERS_DATA);
      });
    }
  }
  // // eslint-disable-next-line no-var
  // var count = 0;
  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const postsPerPage = 10;
  const {
    selectedResources, allResourcesSelected, handleSelectionChange,
  } = useIndexResourceState(customers);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  async function sendEmail(valueSelected) {
    await GetSessionToken();
    const orderIds = [];
    const customerIds = [];
    customers.forEach((items) => {
      valueSelected.forEach((value) => {
        if (items.shopify_order_id == value) {
          orderIds.push(items.shopify_order_id);
          customerIds.push(items.customer_id);
        }
      });
    });
    const MERCHANT_ID = store.get('merchant_id');

    // const ORDER_ID = sID;
    const URL = 'sendCustomerNotification';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      customerIds,
      orderIds,
      notificationType: 'email',
      merchantId: parseInt(MERCHANT_ID),
    };
    await apiCaller(URL, headers, data).then((response) => {
      const { status } = response;
      if (status === 200) {
        toast.success('Email sent!');
      }
    });
  }
  async function sendSMSes(valueSelected) {
    await GetSessionToken();
    const orderIds = [];
    const customerIds = [];
    customers.forEach((items) => {
      valueSelected.forEach((value) => {
        if (items.shopify_order_id == value) {
          orderIds.push(items.shopify_order_id);
          customerIds.push(items.customer_id);
        }
      });
    });
    const MERCHANT_ID = store.get('merchant_id');

    // const ORDER_ID = sID;
    const URL = 'sendCustomerNotification';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      customerIds,
      orderIds,
      notificationType: 'sms',
      merchantId: parseInt(MERCHANT_ID),
    };
    await apiCaller(URL, headers, data).then((response) => {
      const { status } = response;
      if (status === 200) {
        toast.success('SMS sent!');
      }
    });
  }
  const promotedBulkActions = [
    {
      content: 'Send Emails',
      onAction: () => {
        sendEmail(selectedResources);
      },
    },
    {
      content: 'Send SMSes',
      onAction: () => {
        sendSMSes(selectedResources);
      },
    },
  ];
  const options = [
    { label: '-', value: '-' },
    { label: 'Happy', value: 'happy' },
    { label: 'Unhappy', value: 'unhappy' },
    { label: 'Passives', value: 'passive' },
  ];
  const subOptions = [
    { label: '-', value: '-' },
    { label: 'No review request sent', value: 'No review request sent' },
    {
      label: 'Only review request sent via SMS',
      value: 'Only review request sent via SMS',
    },
    {
      label: 'Only review request sent via Email',
      value: 'Only review request sent via Email',
    },
    {
      label: 'Review request sent via Email and SMS',
      value: 'Review request sent via Email and SMS',
    },
  ];

  const rowMarkup = customers.map((item, index) => (
    <IndexTable.Row
      id={item.shopify_order_id}
      key={item.shopify_order_id}
      selected={selectedResources.includes(item.shopify_order_id)}
      position={index}
    >
      <IndexTable.Cell>
        <TextStyle variation="strong">{item.customer_id}</TextStyle>
      </IndexTable.Cell>
      <IndexTable.Cell>{item.email}</IndexTable.Cell>
      {/* <IndexTable.Cell>{item.shopify_order_id[0]}</IndexTable.Cell> */}

      <IndexTable.Cell>{item.shopify_order_id}</IndexTable.Cell>
    </IndexTable.Row>
  ));
  useEffect(() => {
    try {
      getCustomer();
    } catch (error) {
      console.log(error);
    }
  }, [selected]);
  return (
    <Frame>
      <Card sectioned>
        <ToastContainer />
        <Card.Section>
          <Stack distribution="trailing">
            <Select
              label="Filter by"
              labelInline
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            <Select
              label="Filter by"
              labelInline
              options={subOptions}
              onChange={handleSubOptionsChange}
              value={option2}
            />
          </Stack>
        </Card.Section>
        <Card.Section>
          <IndexTable
            resourceName={resourceName}
            itemCount={customers.length}
            selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
            onSelectionChange={handleSelectionChange}
            promotedBulkActions={promotedBulkActions}
            headings={[
              { title: 'CustomerId' },
              { title: 'Emails' },
              // { title: 'Type' },
              { title: 'Orders' },
            ]}
            lastColumnSticky
          >
            {rowMarkup}
          </IndexTable>
        </Card.Section>
        <Pagination
          currentPage={currentPage}
          postsPerPage={postsPerPage}
          totalPosts={customers.length}
          paginate={paginate}
        />
      </Card>
    </Frame>
  );
};

export default ManualRevewTable;
