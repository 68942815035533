/* eslint-disable import/no-extraneous-dependencies */
import {
  Layout, Page,
} from '@shopify/polaris';
import React from 'react';

import Metrics from './C&ASubComponents/Metrics';
import Footer from '../../helper/Footer';

const CaseAndActions = () => (
  <Page>
    <Layout>
      <Layout.Section>
        <Metrics />
      </Layout.Section>
      <Layout.Section>
        <Footer />
      </Layout.Section>
    </Layout>
  </Page>
);

export default CaseAndActions;
