/* eslint-disable import/prefer-default-export */
export const COLUMNS = [
  {
    Header: 'Feedback - Rating',
    id: '1',
  },
  {
    Header: 'Feedback Text',
    id: '2',
  },
  {
    Header: 'Customer Name',
    id: '3',
  },
  {
    Header: 'Order Number',
    id: '5',
  },
  {
    Header: 'Customer Phone',
    id: '4',
  },
  {
    Header: 'Customer Email',
    id: '7',
  },
];
