import React, { useEffect } from 'react';
import {
  useHistory,
} from 'react-router-dom';

const Loading = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push('/wizard');
    }, 3000);
  }, []);
  return (
    <div>
      <h1>Looding</h1>
    </div>
  );
};

export default Loading;
