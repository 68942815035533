/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-var */
import React, { useEffect, useState } from 'react';
import {
  EmptyState, Page, Button, Stack,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { useHistory } from 'react-router-dom';
import { getSessionToken } from '@shopify/app-bridge-utils';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const DisabledRewardFeedback = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  async function navigate() {
    setLoading(true);
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');

    const feedbackSchedule = store.get('feedbackSchedule');

    store.get('RewardFeedbackText', '');
    store.get('RewardFeedbackCode', '');

    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,
        open_text_question: openTextQuestion,
        logo_url: null,
        need_care_notification: {
          email: '',
        },
        reward_feedback: {
          text: '',
          code: '',
        },
        auto_review: {
          channels: '',

          url: '',
        },
        language: 'english',
        review_schedule: '',
        feedback_schedule: feedbackSchedule,
      },
      // send_welcome_email: true,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      const MESSAGE = response.data.message;
      if (MESSAGE === 'Success') {
        setLoading(false);
      }
    });
    history.push('/dashboard');
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
  }
  useEffect(() => {
    GetSessionToken();
  }, []);
  return (
    // <div>
    <Page>
      <EmptyState image={DisableEmo}>
        <p>This feature has been disabled because of plan selected</p>
      </EmptyState>
      <Stack distribution="trailing">
        <Button
          primary
          loading={loading}
          onClick={() => {
            navigate();
          }}
        >
          Get Started
        </Button>
      </Stack>
    </Page>
    // </div>
  );
};
export default DisabledRewardFeedback;
