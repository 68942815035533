/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState, useCallback,
} from 'react';
import {
  Button,
  Modal,
  TextContainer,
  Stack,
  Subheading,
  Collapsible,
  TextStyle,
  Icon,
} from '@shopify/polaris';
import { ChevronUpMinor, ChevronDownMinor } from '@shopify/polaris-icons';

const BreakupModal = ({
  emailUsage,
  smsUsage,
  growthEmailUsage,
  growthSmsUsage,
  freeEmailUsage,
  freeSmsUsage,
}) => {
  const [active, setActive] = useState(false);
  const [openEmail, setOpenEmail] = useState(true);
  const [openSms, setOpenSms] = useState(true);

  const handleToggle = useCallback(
    () => setOpenEmail((openEmail) => !openEmail),
    [],
  );
  const handleToggleSms = useCallback(
    () => setOpenSms((openSms) => !openSms),
    [],
  );

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = (
    <Button plain removeUnderline onClick={handleChange}>
      See Breakup
    </Button>
  );
  return (
    <div>
      <Modal
        activator={activator}
        open={active}
        onClose={handleChange}
        title="Usage Breakup"
      >
        <Modal.Section>
          <TextContainer>
            <Stack distribution="fill">
              <Subheading>Emails</Subheading>
              <Stack alignment="center">
                <Subheading>Usage</Subheading>
                <Button
                  plain
                  onClick={handleToggle}
                  ariaExpanded={openEmail}
                  ariaControls="basic-collapsible"
                >
                  {openEmail === true ? (
                    <Icon source={ChevronUpMinor} color="base" />
                  ) : openEmail === false ? (
                    <Icon source={ChevronDownMinor} color="base" />
                  ) : (
                    ''
                  )}
                </Button>
              </Stack>
            </Stack>
            <Collapsible
              open={openEmail}
              id="basic-collapsible"
              transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
              expandOnPrint
            >
              <div
                style={{
                  background: '#EDEEEF',
                  // height: '130px',
                  width: '60%',
                  borderRadius: '5px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <Stack distribution="fill" alignment="center">
                  <Subheading>Advanced</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">
                        {emailUsage.count}
                      </TextStyle>
                      <TextStyle variation="subdued">
                        {emailUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Stack distribution="fill" alignment="center">
                  <Subheading>Growth</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">
                        {growthEmailUsage.count}
                      </TextStyle>
                      <TextStyle variation="subdued">
                        {growthEmailUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Stack distribution="fill" alignment="center">
                  <Subheading>Free</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">
                        {freeEmailUsage.count}
                      </TextStyle>
                      <TextStyle variation="subdued">
                        {freeEmailUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
              </div>
            </Collapsible>
          </TextContainer>
        </Modal.Section>
        <Modal.Section>
          <TextContainer>
            <Stack distribution="fill">
              <Subheading>SMS</Subheading>
              <Stack alignment="center">
                <Subheading>Usage</Subheading>
                <Button
                  plain
                  onClick={handleToggleSms}
                  ariaExpanded={openSms}
                  ariaControls="basic-collapsible"
                >
                  {openSms === true ? (
                    <Icon source={ChevronUpMinor} color="base" />
                  ) : openSms === false ? (
                    <Icon source={ChevronDownMinor} color="base" />
                  ) : (
                    ''
                  )}
                </Button>
              </Stack>
            </Stack>
            <Collapsible
              open={openSms}
              id="basic-collapsible"
              transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
              expandOnPrint
            >
              <div
                style={{
                  background: '#EDEEEF',

                  width: '60%',
                  borderRadius: '5px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}
              >
                <Stack distribution="fill" alignment="center">
                  <Subheading>Advanced</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">{smsUsage.count}</TextStyle>
                      <TextStyle variation="subdued">
                        {smsUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Stack distribution="fill" alignment="center">
                  <Subheading>Growth</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">
                        {growthSmsUsage.count}
                      </TextStyle>
                      <TextStyle variation="subdued">
                        {growthSmsUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
                <Stack distribution="fill" alignment="center">
                  <Subheading>Free</Subheading>
                  <Stack.Item>
                    <Stack alignment="baseline" distribution="trailing">
                      <TextStyle variation="strong">
                        {freeSmsUsage.count}
                      </TextStyle>
                      <TextStyle variation="subdued">
                        {freeSmsUsage.usage}
                      </TextStyle>
                    </Stack>
                  </Stack.Item>
                </Stack>
              </div>
            </Collapsible>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
};

export default BreakupModal;
