/* eslint-disable camelcase */
import axios from 'axios';

const { REACT_APP_API_BASE_URL_ADVANCED_REPORTING } = process.env;

// const REACT_APP_API_BASE_URL = 'https://e0jncsnv2e.execute-api.eu-central-1.amazonaws.com/prod/dropdownList';
export default function apiCaller(endPoint, headers, data) {
  const url = `${REACT_APP_API_BASE_URL_ADVANCED_REPORTING}${endPoint}`;
  return axios({
    method: 'post',
    url,
    headers,
    data,
  });
}
