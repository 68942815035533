import axios from 'axios';

export default function apiCaller(url, headers, data) {
  return (
    axios({
      method: 'put',
      url,
      headers,
      data,

    }));
}
