/* eslint-disable camelcase */
import axios from 'axios';

const { REACT_APP_API_BASE_URL } = process.env;
export default function apiCaller(endPoint, headers, data) {
  const url = `${REACT_APP_API_BASE_URL}${endPoint}`;
  return (
    axios({
      method: 'post',
      url,
      headers,
      data,

    }));
}
