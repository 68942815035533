/* eslint-disable brace-style */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-var */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, withRouter } from 'react-router-dom';

import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { Redirect } from '@shopify/app-bridge/actions';
import { Provider } from '@shopify/app-bridge-react';
import axios from 'axios';
import store from './store/CustomStore';
import { ShopStore } from './store/PullStore';
import Wizard from './Pages/Wizard';
import Subscription from './Pages/Subscription';
import Dashboard from './Pages/Dashboard';
import ComparePlans from './Components/Dashboard/Settings/ComparePlans';
// import Test from './Components/test';
import './App.css';
import apiCaller from './helper/API/apiCaller';
import { PlanSelected } from './helper/context/planSelected';
import Loading from './Pages/Loading';

var n = require('nonce')();
// var store = require('store');
const {
  REACT_APP_API_Key,
  REACT_APP_NAME,
  REACT_APP_REDIRECT_URL,
  REACT_APP_SCOPES,
  REACT_APP_API_SECRET_KEY,
} = process.env;

const PERMISSION_URL = `/oauth/authorize?client_id=${REACT_APP_API_Key}&scope=${REACT_APP_SCOPES}&redirect_uri=${REACT_APP_REDIRECT_URL}`;

function App() {
  const [appBridgeConfig, setAppBridgeConfig] = useState();
  const [initialized, setInitialized] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [registered, setRegistered] = useState(false);
  const [Nonce, setNonce] = useState('');
  // const [chargeID, setChargeID] = useState('');
  const [response, setResponse] = useState([]);
  const history = useHistory();
  // const CONTEXTCHARGEID = useContext(ConfirmPaymentChargeId);
  var sessionToken = '';

  const initiateAuth = async (shopName) => {
    // console.log('Initiate Auth inside');
    if (window.top === window.self) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      // here we are setting up params value from return url in to the localstorage to grab it for further use
      if (params.charge_id) {
        const { shopName } = params;
        const { charge_id } = params;
        // setChargeID(charge_id);
        // setId(charge_id);
        store.set('chargeID', charge_id);

        window.location.assign(
          `https://${shopName}/admin/apps/${REACT_APP_NAME}/wizard?charge_id=${charge_id}`
        );
      } else {
        window.location.assign(`https://${shopName}/admin${PERMISSION_URL}`);
      }

      // If the current window is the 'child', change the parent's URL with ShopifyApp.redirect
    } else {
      // ------------------------Redirect done from here right now-------------------------------------
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        const { host } = params;
        const app = createApp({
          apiKey: REACT_APP_API_Key,
          host,
        });
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.ADMIN_PATH, PERMISSION_URL);
      } catch (error) {
        // console.log('ERROR', error);
      }
    }
  };

  const checkIfAuthorized = async (shop, hmac, host) => {
    try {
      const GET_SHOP_DETAILS_ENDPOINT = 'getShopDetails';
      const app = createApp({
        apiKey: REACT_APP_API_Key,
        host,
      });
      // console.log('app create APP:-', app);
      const SESSION_TOKEN = await getSessionToken(app);
      const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorizer: SESSION_TOKEN,
      };
      const data = {
        shop,
      };
      const response = await apiCaller(GET_SHOP_DETAILS_ENDPOINT, headers, data);
      // console.log('RESPONSE GET SHOP DETAILS', response);
      const dataRecieved = response.data.info[0];
      ShopStore.update((s) => {
        s.shopDetails = dataRecieved;
      });
      const initial_params = response.data.info[0].id;
      const { plan_id } = response.data.info[0];
      // console.log('APP JS PLAN ID', plan_id);
      // localStorage.setItem('merchant_id', initial_params);
      store.set('merchant_id', initial_params);
      store.set('planSelectedID', plan_id);

      const shopItem = response.data.info[0];
      // console.log('SHOPITEM:', shopItem);
      setCurrentPlan(shopItem.plan_id);
      // const AUTHORIZED = shopItem.authorized;
      const PlanId = shopItem.plan_id;
      const ActiveUser = shopItem.active;
      const REGISTER_REDIRECT_URL = `https://${shop}/admin/apps/${REACT_APP_NAME}`;
      // EMBEDDED URL
      const redirect = Redirect.create(app);
      // Removed widnow.location with shopify provided redirect

      // CurrentPlan = PlanId;

      if (shopItem?.authorized) {
        if (PlanId == null) {
          // console.log('MAIN PAGE');
          // history.push('/');
          // redirect.dispatch(Redirect.Action.REMOTE, REGISTER_REDIRECT_URL);
          redirect.dispatch(Redirect.Action.APP, '/');
        } else if (PlanId !== null) {
          if (ActiveUser) {
            // await history.push('/dashboard');
            redirect.dispatch(Redirect.Action.APP, '/dashboard');
          } else if (!ActiveUser) {
            // setCurrentPlan(PlanId);
            // await history.push('/wizard');
            redirect.dispatch(Redirect.Action.APP, '/wizard');
          }
        }
      } else if (!shopItem?.authorized) {
        await initiateAuth();
      }
      setResponse(shopItem);
    } catch (error) {
      console.log('ERROR', error);
    }
  };

  const registerShop = async (code, host, shop, hmac, timestamp) => {
    // console.log('Register Shop Inside');
    try {
      const app = createApp({
        apiKey: REACT_APP_API_Key,
        host,
      });
      // console.log('app create APP:-', app);
      const SESSION_TOKEN = await getSessionToken(app);
      const REGISTER_ENDPOINT = 'registerShop';
      const headers = {
        'Access-Control-Allow-Origin': '*',
        Authorizer: SESSION_TOKEN,
      };

      const data = {
        code,
        hmac,
        host,
        shop,
        timestamp,
      };
      // console.log('DATA', data);

      const response = await apiCaller(REGISTER_ENDPOINT, headers, data);
      // console.log('RESPONSE REGISTER SHOP', response);
    } catch (error) {
      console.log(error);
    }
  };

  const preCheck = async () => {
    // console.log('Pre Check Reached');
    // Parse shop and host from URL Search Params. These are the query params in the iframe.
    const urlSearchParams = new URLSearchParams(window.location.search);
    // console.log('urlSearchParams:-', urlSearchParams);
    const params = Object.fromEntries(urlSearchParams.entries());
    // console.log('params:-', params);

    const { code, host, shop, hmac, timestamp, shopName } = params;
    try {
      const body = { shop_name: shop || shopName };
      // console.log('BODY FOR CHECK IF AUTHORIZED', body);
      const end_point = 'checkAuthorized';
      const headers = {
        'Access-Control-Allow-Origin': '*',
        // Authorizer: SESSION_TOKEN,
      };
      const response = await apiCaller(end_point, headers, body);
      // console.log('Check If Authorized', response);
      const { authorized } = response.data;
      if (authorized) {
        // localStorage.setItem('shop-name', params.shop);
        store.set('shop-name', params.shop);
        store.set('HOST', params.host);
        // localStorage.setItem('HOST', params.host);

        // --------------------//
        // if (!code) {

        // }

        if (params.host) {
          const app = createApp({
            apiKey: REACT_APP_API_Key,
            host: params.host,
          });
          // console.log('app create APP:-', app);
          const SESSION_TOKEN = await getSessionToken(app);
          sessionToken = SESSION_TOKEN;
          // console.log('SESSION TOKEN', sessionToken);
        }

        /*
If code is present, that means we are here after a successful Oauth Redirect.
Let's register this shop with our backend.
After that, check and redirect to shopify admin if we're not.
*/
        if (code) {
          // console.log('IF code inside', code);
          await registerShop(code, host, shop, hmac, timestamp);
          if (window.top !== window.self) {
            const app = createApp({
              apiKey: REACT_APP_API_Key,
              host,
            });
            const REGISTER_REDIRECT_URL = `https://${shop}/admin/apps/${REACT_APP_NAME}`;
            // EMBEDDED URL
            const redirect = Redirect.create(app);
            // Removed widnow.location with shopify provided redirect
            redirect.dispatch(Redirect.Action.REMOTE, REGISTER_REDIRECT_URL);
            // window.location.assign(`https://${shop}/admin/apps/${REACT_APP_NAME}`);
          }
        } else if (host) {
          // console.log('IF Host inside');
          const app = createApp({
            apiKey: REACT_APP_API_Key,
            host: params.host,
          });
          const SESSION_TOKEN = await getSessionToken(app);
          sessionToken = SESSION_TOKEN;
          /*
   If host is there (and not code), that means we are in shopify admin.
   Let's check if the shop is authorized and setup app bridge config.
  */
          await checkIfAuthorized(shop, hmac, host);

          await setAppBridgeConfig({
            apiKey: REACT_APP_API_Key,
            host,
          });
          setInitialized(true);
        } else {
          // If host is not there, that means we aren't in shopify admin, so initiate Auth
          initiateAuth(shop, params);
        }
      } else if (!authorized) {
        if (!code) {
          const nonce = n();
          window.location.assign(
            `https://${shop}/admin${PERMISSION_URL}&state=${nonce}&grant_options[]={per-user}`
          );
        } else if (code) {
          // console.log('IF code inside when not authorized', code);
          await registerShop(code, host, shop, hmac, timestamp);
          if (window.top !== window.self) {
            await checkIfAuthorized(shop, hmac, host);
          }
        }
      }
    } catch (error) {
      if (!code) {
        const nonce = n();
        window.location.assign(
          `https://${shop}/admin${PERMISSION_URL}&state=${nonce}&grant_options[]={per-user}`
        );
      } else if (code) {
        // console.log('CheckIfFAILED NOw creating', code);
        await registerShop(code, host, shop, hmac, timestamp);
        if (window.top !== window.self) {
          await checkIfAuthorized(shop, hmac, host);
        }
      }
    }
  };

  useEffect(() => {
    store.set('oAuth', JSON.stringify(response));
  }, [response]);

  useEffect(() => {
    preCheck();
  }, []);
  return (
    <div className="App">
      {appBridgeConfig && initialized && (
        <Provider config={appBridgeConfig}>
          <AppProvider i18n={enTranslations}>
            <Router>
              <Switch>
                <PlanSelected.Provider value={currentPlan}>
                  <Route path="/wizard" component={Wizard} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/compareplans" component={ComparePlans} />
                  <Route path="/loading" component={Loading} />
                  <Route path="/" exact component={Subscription} />
                </PlanSelected.Provider>
              </Switch>
            </Router>
          </AppProvider>
        </Provider>
      )}
    </div>
  );
}

export default withRouter(App);
