/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Stack } from '@shopify/polaris';

const Pagination = ({
  postsPerPage, totalPosts, paginate, currentPage,
}) => {
  const pageNumbers = [];
  //   console.log(postsPerPage, totalPosts)
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    // math.ciel is used for rounding off the value
    pageNumbers.push(i);
    // console.log(pageNumbers);
  }

  const styles = {
    width: '20px',
    height: '20px',
    border: 'none',
    borderRadius: '6px',
    fontSize: '12px',
    textAlign: 'center',
    margin: '0px 0px 10px',
    cursor: 'pointer',
  };
  const activeStyles = {
    width: '20px',
    height: '20px',
    border: 'none',
    borderRadius: '6px',
    fontSize: '12px',
    textAlign: 'center',
    margin: '0px 0px 10px',
    cursor: 'pointer',
    background: '#EA5569',
    color: '#FFFF',
    fontWeight: 'bold',
  };

  return (
    <Stack alignment="center" distribution="center" spacing="loose">
      {pageNumbers.map((number) => (
        <>
          <button
            style={currentPage === number ? activeStyles : styles}
            onClick={() => paginate(number)}
            key={number}
          >
            {number}
          </button>
        </>
      ))}
    </Stack>
  );
};

export default Pagination;
