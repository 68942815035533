/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable radix */
/* eslint-disable no-var */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Page, Stack, Heading, Icon, DisplayText, Banner, Spinner,
} from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import AsyncSelect from 'react-select/async';

import { getSessionToken } from '@shopify/app-bridge-utils';
import createApp from '@shopify/app-bridge';
import { ToastContainer, toast } from 'react-toastify';
import store from '../../../../../store/CustomStore';
import apiCallerAdvancedGraphs from '../../../../../helper/API/apiCallerAdvancedGraphs';
import FilterData from '../../../../../helper/FilterData';

const selectOptionsStyles = { margin: '20px 0px 0px 0px' };
const { REACT_APP_API_Key } = process.env;
const today = new Date();
const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
var filterDate = {
  from_date: startDay.toISOString().split('T')[0],
  to_date: nextweek.toISOString().split('T')[0],
};
var selectedIDs = [];
var RESPONSE = [];
// var feedbackValues = [];
// var PROMOTERS = [];
// var DETRACTORS = [];
// var PASSIVES = [];
function findValues(arr) {
  RESPONSE = [];
  for (var i in arr) {
    RESPONSE.push(arr[i]);
  }
}

const ProductOption = () => {
  const [selected, setSelected] = useState([]);
  const [productOption, setProductOption] = useState();
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState('');
  // const [inputValue, setValue] = useState('');
  // handle selection
  const notify = () => toast.error('No Record Found !', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const toastMarkup = (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
  const options = {
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    // indexAxis: 'y',
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          // forces step size to be 50 units
          stepSize: 2,
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: 10,
        ticks: {
          // forces step size to be 50 units
          stepSize: 2,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          // forces step size to be 50 units
          stepSize: 2,
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        // max: 10,
        ticks: {
          // forces step size to be 50 units
          // stepSize: 10,
        },
      },
    },
  };
  const state = {
    labels: RESPONSE.map((value) => value.label),
    // labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Detractor',
        fill: false,
        backgroundColor: '#E85146',
        borderColor: '#E85146',
        borderWidth: 1,
        data: RESPONSE.map((value) => value.detractor),
      },
      {
        label: 'Passive',
        fill: false,
        backgroundColor: '#F79937',
        borderColor: '#F79937',
        borderWidth: 1,
        data: RESPONSE.map((value) => value.passive),
      },
      {
        label: 'Promoter',
        fill: false,
        backgroundColor: '#26968A',
        borderColor: '#26968A',
        borderWidth: 1,
        data: RESPONSE.map((value) => value.promoter),
      },
    ],
  };

  async function getProductFeedback(productIds) {
    const host = store.get('HOST');
    const MERCHANT_ID = store.get('merchant_id');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const type = store.get('typeOfQuestion');
    if (selectedIDs.length !== 0) {
      setLoading(true);
      const endPoint = 'optionFeedbackValue';
      const headers = { Authorizer: sessionToken };
      const data = {
        merchant_id: MERCHANT_ID,
        // product_id: productIds,
        options: productIds,
        from_date: filterDate.from_date,
        to_date: filterDate.to_date,
        type,
      };
      try {
        const response = await apiCallerAdvancedGraphs(endPoint, headers, data);
        const values = response.data.body;
        setScore(Math.trunc(values.total));
        findValues(values);
        setLoading(false);
      } catch (error) {
        console.log('Error', error.message);
        notify();
      }
    }
    setLoading(false);
  }
  const handleChange = (value) => {
    setSelected(value);
    selectedIDs = value;
    if (selectedIDs.length !== 0) {
      const productIds = value?.map((a) => a.value);
      setProductOption(productIds);
      getProductFeedback(productIds);
    } else {
      RESPONSE = [];
    }
  };
  const handleFilterDate = (value) => {
    filterDate = { from_date: value.start, to_date: value.end };
    getProductFeedback(productOption);
  };
  async function getDropdDownList() {
    const MERCHANT_ID = store.get('merchant_id');
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const endPoint = 'dropdownList';
    const headers = { Authorizer: sessionToken };
    const body = {
      type: 'product_option',
      merchant_id: MERCHANT_ID,
    };
    const response = await apiCallerAdvancedGraphs(endPoint, headers, body);
    const data = response.data.body;
    return data;
  }

  useEffect(async () => {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    // console.log('SessionToken', sessionToken);
  }, []);

  return (
    <Page>
      <Stack vertical>
        <div id="react-select" style={selectOptionsStyles}>
          <Stack distribution="fillEvenly" spacing="loose">
            <DisplayText size="large">Score by Product Option</DisplayText>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={selected}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              loadOptions={getDropdDownList}
              // onInputChange={handleInputChange}
              onChange={handleChange}
              isMulti
            />
          </Stack>
        </div>
        <div style={selectOptionsStyles}>
          <Stack distribution="fillEvenly" spacing="loose" alignment="center">
            <Stack.Item>
              <Stack vertical>
                <Stack.Item>
                  <Stack alignment="center">
                    <Heading>Score</Heading>
                    <Icon source={InfoMinor} color="base" />
                  </Stack>
                </Stack.Item>
                <Stack.Item>
                  <DisplayText>{score}</DisplayText>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item>
              {selected.length !== 0 ? (
                <FilterData filterDate={(value) => handleFilterDate(value)} />
              ) : (
                ''
              )}
            </Stack.Item>
          </Stack>
        </div>
        {loading ? (
          <Spinner accessibilityLabel="Spinner example" size="large" />
        ) : (
          <div>
            {selected.length === 0 ? (
              <Bar width={50} height={25} data={state} options={options} />
            ) : selected.length !== 0 && selected.length < 6 ? (
              <Bar width={50} height={25} data={state} options={options2} />
            ) : (
              <Banner title="You can select Max 5 products" />
            )}
          </div>
        )}
      </Stack>
      {toastMarkup}
    </Page>
  );
};

export default ProductOption;
