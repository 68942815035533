/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable linebreak-style */

import React, { useState, useCallback, useEffect } from 'react';
import {
  ChoiceList,
  Heading,
  Stack,
  Button,
  Popover,
  OptionList,
  TextStyle,
  Link,
  Tag,
} from '@shopify/polaris';
// import store from 'store-js';
import store from '../../store/CustomStore';
import Questions from './Questions';
// import apiCaller from '../../helper/API/apiCaller';

const FeedBackChannel = (props) => {
  const [nav, setNav] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [selected, setSelected] = useState('');
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const options = [
    { label: 'Directly', value: '0 minute' },
    { label: '15 minute', value: '15 minute' },
    { label: '30 minute', value: '30 minute' },
    { label: '1 hour', value: '1 hour' },
    { label: '1.5 hour', value: '1.5 hour' },
    { label: '2 hour', value: '2 hour' },
    { label: '4 hour', value: '4 hour' },
    { label: '6 hour', value: '6 hour' },
    { label: '8 hour', value: '8 hour' },
    { label: '1 day', value: '1 day' },
    { label: '1.5 day', value: '1.5 day' },
    { label: '2 day', value: '2 day' },
    { label: '3 day', value: '3 day' },
    { label: '4 day', value: '4 day' },
  ];
  const activator = (
    <Stack alignment="center">
      <Button onClick={togglePopoverActive} disclosure>
        Schedule
      </Button>
      {schedule.map((item) => (
        <Stack>
          <Tag>{item}</Tag>
        </Stack>
      ))}
    </Stack>
  );
  async function navigate() {
    store.set('feedbackChannels', selected);
    store.set('feedbackSchedule', schedule);

    setNav(!nav);
    props.changeColor('Questions');
  }

  const handleChange = useCallback(
    (value) => setSelected(value),

    [],
  );

  useEffect(() => {
    const channels = store.get('feedbackChannels');
    const schedule = store.get('feedbackSchedule');
    if (channels) {
      setSelected(channels);
      setSchedule(schedule);
    }
  }, []);

  return (
    <>
      {nav === false ? (
        <div
          style={{
            padding: ' 20px ',
            width: '100%',
          }}
        >
          <Stack vertical distribution="fill">
            <Stack Item>
              <ChoiceList
                allowMultiple
                title={(
                  <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Heading>Choose your feedback channels</Heading>
                    <TextStyle variation="subdued">
                      <p style={{ fontSize: '12px', marginTop: '10px' }}>
                        Choose where to send the feedback request to your customers after the order
                        is fulfilled.
                      </p>
                    </TextStyle>
                    {/* {selected == 'sms' ? ( */}
                    <TextStyle variation="subdued">
                      <p style={{ fontSize: '12px', marginTop: '10px' }}>
                        Check our support page before you turn on SMS to make sure the SMS will be
                        delivered. We charge based on sent messages also if they are not delivered. &nbsp;
                        <Link
                          url="https://support.qrite.com/docs/supported-countries-for-sms/"
                          removeUnderline
                          external
                        >
                          Find out more.
                        </Link>
                      </p>
                    </TextStyle>
                    {/* ) : ''} */}
                  </div>
                )}
                choices={[
                  { label: 'Thank You Page', value: 'thank_you' },
                  { label: 'SMS*', value: 'sms' },
                  { label: 'Email*', value: 'email' },
                ]}
                selected={selected}
                onChange={handleChange}
              />
              <TextStyle variation="subdued">
                <p>
                  *Usage charges may apply,
                  {' '}
                  <Link url="https://qrite.com/pricing" removeUnderline external>
                    See pricing
                  </Link>
                </p>
              </TextStyle>
            </Stack>
            <Stack Item>
              <Stack vertical>
                <Heading>Schedule your Feedback</Heading>
                <TextStyle variation="subdued">
                  <p style={{ fontSize: '12px' }}>
                    Choose when to send the feedback request to your customers after the order is
                    fulfilled.
                  </p>
                </TextStyle>
                <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
                  <OptionList
                    // title="Manage sales channels availability"
                    onChange={setSchedule}
                    options={options}
                    selected={schedule}
                    allowMultiple
                  />
                </Popover>
              </Stack>
            </Stack>

            <Stack distribution="trailing">
              <div style={{ marginTop: '100px' }}>
                {schedule.length === 0 ? (
                  <Button disabled>Next: Questions</Button>
                ) : (
                  <Button primary onClick={() => navigate()}>
                    Next: Questions
                  </Button>
                )}
              </div>
            </Stack>
          </Stack>
        </div>
      ) : nav === true ? (
        <Questions schedule={schedule} selected={selected} />
      ) : (
        ''
      )}
    </>
  );
};

export default FeedBackChannel;
