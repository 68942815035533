/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useCallback } from 'react';
import {
  Popover, Button, Card, DatePicker, Stack, Icon, TextStyle,
} from '@shopify/polaris';

import { CalendarMinor } from '@shopify/polaris-icons';

const selectStyles = { padding: '10px', border: '1px solid lightgray', borderRadius: '5px' };
const FilterData = (props) => {
  const [popoverActive, setPopoverActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [custom, setCustom] = useState(false);
  const [selected, setSelected] = useState('1 week');
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');

  const currentyear = new Date().getFullYear();
  const currentmonth = new Date().getMonth();
  const [{ year, month }, setDate] = useState({
    year: currentyear,
    month: currentmonth,
  });

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(),
    end: new Date(),
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
    // console.log('Date', selectedDates),
    // props.updateDateChange(selectedDates.end.toLocaleDateString()),
  );
  // eslint-disable-next-line prefer-const
  let range = [];

  function rangeCreator(val) {
    if (val === '1 week') {
      const today = new Date();
      const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
      range = {
        end: nextweek.toISOString().split('T')[0],
        start: startDay.toISOString().split('T')[0],
      };
      setStartDay(startDay.toLocaleDateString());
      setEndDay(nextweek.toLocaleDateString());
      // props.rangeSelected(range);
      props.filterDate(range);
    } else if (val === '1 month') {
      const today = new Date();
      const startDay = new Date(today.getFullYear(), today.getMonth());
      const nextweek = new Date(today.getFullYear(), today.getMonth() + 1);
      range = {
        end: nextweek.toISOString().split('T')[0],
        start: startDay.toISOString().split('T')[0],
      };
      setStartDay(startDay.toLocaleDateString());
      setEndDay(nextweek.toLocaleDateString());
      props.filterDate(range);
    } else if (val === '1 year') {
      const today = new Date();
      const startDay = new Date(today.getFullYear(), 0, 1);
      const nextweek = new Date(today.getFullYear() + 1, 0, 1);
      range = {
        end: nextweek.toISOString().split('T')[0],
        start: startDay.toISOString().split('T')[0],
      };
      setStartDay(startDay.toLocaleDateString());
      setEndDay(nextweek.toLocaleDateString());
      props.filterDate(range);
    } else if (val === 'All Time') {
      const today = new Date();
      const startDay = new Date(today.getFullYear(), 0, 1);
      const nextweek = new Date(today.getFullYear() + 1, 0, 1);
      range = {
        end: null,
        start: null,
      };
      setStartDay(startDay.toLocaleDateString());
      setEndDay(nextweek.toLocaleDateString());
      props.filterDate(range);
    }
  }

  // const handleSelectChange = useCallback((value) => setSelected(value),
  //   rangeCreator(selected),
  //   []);
  function handleSelectChange(value) {
    setCustom(true);
    setSelected(value);
    rangeCreator(value);
  }

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  function setDateRange() {
    setDisabled(false);

    props.filterDate(selectedDates);
  }
  // console.log('FILTERDATA', props.filterDate);

  const activator = (
    <Button size="slim" onClick={togglePopoverActive}>
      <Stack alignment="center">
        <Icon source={CalendarMinor} color="base" />
        <TextStyle variation="strong">Filter</TextStyle>
      </Stack>
    </Button>
  );
  return (
    <Stack alignment="center">
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
        <Card sectioned>
          {/* <Stack vertical spacing="loose"> */}
          <Stack vertical>
            <Stack.Item>
              <select
                onChange={(event) => handleSelectChange(event.target.value)}
                defaultValue="1 week"
                style={selectStyles}
              >
                <option value="1 week">1 week</option>
                <option value="1 month">1 month</option>
                <option value="1 year">1 year</option>
                <option value="All Time">All Time</option>

                <option value="Custom">Custom</option>
                {/* {options.map((item) => (
                    <option value={item.value} sele>
                      {item.label}
                    </option>
                  ))} */}
              </select>
            </Stack.Item>
            {selected === 'Custom' ? (
              <>
                {' '}
                <Stack.Item>
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={setSelectedDates}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    disableDatesAfter={new Date()}
                    allowRange
                  />
                </Stack.Item>
                <Stack alignment="trailing" distribution="trailing">
                  <Button onClick={() => setDateRange()} primary size="slim">
                    Filter
                  </Button>
                </Stack>
              </>
            ) : (
              ''
            )}
          </Stack>
          {/* <Button size="slim" primary>Submit</Button>
          </Stack> */}
        </Card>
      </Popover>
      {disabled === false ? (
        <Stack alignment="center">
          <TextStyle variation="subdued">compared from</TextStyle>
          <h1>{selectedDates.start.toLocaleDateString()}</h1>

          <TextStyle variation="subdued">to</TextStyle>
          <h1>{selectedDates.end.toLocaleDateString()}</h1>
        </Stack>
      ) : custom === true ? (
        <Stack alignment="center">
          <TextStyle variation="subdued">compared from</TextStyle>
          <h1>{startDay}</h1>

          <TextStyle variation="subdued">to</TextStyle>
          <h1>{endDay}</h1>
        </Stack>
      ) : (
        <TextStyle variation="subdued">compared for one Week</TextStyle>
      )}
    </Stack>
  );
};

export default FilterData;
