/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  Stack, Button, TextField, SettingToggle, TextStyle, Heading,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { Route, useHistory } from 'react-router-dom';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../store/CustomStore';
import DisabledRewardFeedback from './Disabled/DisabledRewardFeedback';
import apiCaller from '../../helper/API/apiCaller';
// import { PlanSelected } from '../../helper/context/planSelected';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
// eslint-disable-next-line no-unused-vars
const RewardFeedback = (props) => {
  const [rewardCode, setRewardCode] = useState('');
  const [rewardText, setRewardText] = useState('');
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [currentPlan, setCurrentPlan] = useState('');
  const history = useHistory();
  const SubscriptionId = props.planId;

  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'Enabled' : 'Disabled';

  const handleRewardCodeChange = useCallback((newValue) => setRewardCode(newValue), []);
  const handleRewardTextChange = useCallback((newValue) => setRewardText(newValue), []);

  async function navigate() {
    setLoading(true);
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    store.set('RewardFeedbackText', rewardText);
    store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,
        open_text_question: openTextQuestion,
        logo_url: uploadS3url === undefined ? null : { image_1: uploadS3url },
        need_care_notification: needCareEmail === undefined ? { email: '' } : {
          email: needCareEmail,
        },
        reward_feedback: {
          text: rewardText,
          code: rewardCode,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
      // send_welcome_email: true,
    };
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      const MESSAGE = response.data.message;
      if (MESSAGE === 'Success') {
        setLoading(false);
      }
    });
    history.push('/dashboard');
  }

  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
  }
  useEffect(() => {
    GetSessionToken();
  }, []);
  // useEffect(() => {
  //   const planData = store.get('planSelected');
  //   setCurrentPlan(planData);
  //   console.log('planId', SubscriptionId);
  // });

  return (
    <>
      {SubscriptionId !== 1 ? (
        <div
          style={{
            padding: '20px',
            paddingTop: '20px',
            width: '100%',
          }}
        >
          <TextStyle variation="strong">
            <Heading>Reward customer feedback with a discount code</Heading>
          </TextStyle>
          <TextStyle variation="subdued">
            <p style={{ fontSize: '12px' }}>
              Give customers a discount code for sharing their feedback to encourage another
              purchase
            </p>
          </TextStyle>
          {' '}
          <div
            style={{
              marginTop: '20px',
            }}
          >
            <SettingToggle enabled={active}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex' }}>
                  <p>Reward Feedback : &nbsp;</p>
                  <TextStyle variation="strong">{textStatus}</TextStyle>
                  .
                </div>
                {active ? (
                  <div style={{ color: '#bf0711' }}>
                    <Button onClick={handleToggle}>{contentStatus}</Button>
                  </div>
                ) : !active ? (
                  <Button primary onClick={handleToggle}>
                    {contentStatus}
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </SettingToggle>
          </div>
          {!active ? (
            <Stack distribution="trailing">
              <div style={{ marginTop: '100px' }}>
                <Route
                  render={() => (
                    <Button primary loading={loading} onClick={() => navigate()}>
                      Get Started
                    </Button>
                  )}
                />
              </div>
            </Stack>
          ) : (
            <div style={{ marginTop: '20px' }}>
              <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                <TextStyle variation="strong">Custom Reward Text</TextStyle>
                <TextField
                  value={rewardText}
                  onChange={handleRewardTextChange}
                  multiline={4}
                  placeholder="Enter your reward text, explain the discount your customer gets after submitting feedback."
                />
              </div>
              <div style={{ paddingTop: '20px' }}>
                <TextStyle variation="strong">Reward Code</TextStyle>
                <TextField
                  value={rewardCode}
                  onChange={handleRewardCodeChange}
                  placeholder="Enter the shopify discount code here"
                />

                <TextStyle variation="subdued">
                  <p style={{ fontSize: '12px' }}>
                    You can create a coupon code on the Shopify Discount page and past the general
                    code here
                  </p>
                </TextStyle>
              </div>
              <div style={{ marginTop: '50px' }}>
                <Stack distribution="trailing">
                  {rewardCode !== '' && rewardText !== '' ? (
                    <Route
                      render={() => (
                        <Button primary loading={loading} onClick={() => navigate()}>
                          Get Started
                        </Button>
                      )}
                    />
                  ) : (
                    <Button disabled> Get Started</Button>
                  )}
                </Stack>
              </div>
            </div>
          )}
        </div>
      ) : (
        <DisabledRewardFeedback />
      )}
    </>
  );
};

export default RewardFeedback;
