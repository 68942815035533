/* eslint-disable arrow-body-style */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import { FooterHelp, Link } from '@shopify/polaris';

import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
// import store from '../store/CustomStore';
import Sidenav from '../Components/wizard/Sidenav';
import store from '../store/CustomStore';
// import { PlanSelected } from '../helper/context/planSelected';
import apiCaller from '../helper/API/apiCaller';

// var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const Wizard = ({ match }) => {
  async function getShopDetailAPiCall() {
    const GET_SHOP_DETAILS_ENDPOINT = 'getShopDetails';
    const host = store.get('HOST');
    const shop = store.get('shop-name');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const SESSION_TOKEN = await getSessionToken(app);
    const headers = {
      'Access-Control-Allow-Origin': '*',
      Authorizer: SESSION_TOKEN,
    };
    const data = {
      shop,
    };
    const response = await apiCaller(GET_SHOP_DETAILS_ENDPOINT, headers, data);
    // const initial_params = response.data.info[0].id;
    const { plan_id } = response.data.info[0];
    // console.log('WIZARD', plan_id);
    store.set('planSelectedID', plan_id);
  }
  useEffect(() => {
    getShopDetailAPiCall();
  }, []);
  return (
    <>
      {/* <PlanSelected.Provider value={currentPlan}> */}
      <Sidenav match={match} />
      {/* </PlanSelected.Provider> */}

      <FooterHelp>
        Need help? &nbsp;
        <Link removeUnderline url="https://qrite.com/support/book-a-consultation" external>
          Book a consultation
        </Link>
      </FooterHelp>
    </>
  );
};

export default Wizard;
