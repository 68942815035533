/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect,
} from 'react';

import {
  Stack, Button, TextContainer, Heading, TextStyle, SettingToggle,
} from '@shopify/polaris';
import '../../assets/styles/input.css';
import store from '../../store/CustomStore';
import DisabledNeedCare from './Disabled/DisabledNeedCare';
// import apiCaller from '../../helper/API/apiCaller';
// import { PlanSelected } from '../../helper/context/planSelected';
import AutoReview from './AutoReview';
// import Subscription from '../../Pages/Subscription';

// var currentPlanId;
const NeedCare = (props) => {
  const [nav, setNav] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [needCareEmail, setNeedCareEmail] = useState('Please Provide an Email');
  const [email, setEMail] = useState('');
  const [message, setMessage] = useState('');
  const [active, setActive] = useState(true);
  // const [currentPlan, setCurrentPlan] = useState('');
  const SubscriptionId = props.planId;
  // const SubscriptionId = useContext(PlanSelected);

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'Enabled' : 'Disabled';
  async function navigate() {
    store.set('needCareEmail', email);
    setNav(!nav);
    props.changeColor('Auto Review');
  }

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (event) => {
    const mail = event.target.value;
    if (emailRegex.test(mail)) {
      setIsValid(true);
      setEMail(mail);
      setMessage('Your email looks good!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
    }
  };
  useEffect(() => {
    const email = store.get('needCareEmail');
    setNeedCareEmail(email);
  }, []);
  return (
    <>
      {SubscriptionId == 3 ? (
        <>
          {nav === false ? (
            <div
              style={{
                padding: '20px',
                width: '100%',
              }}
            >
              <TextContainer spacing="loose">
                <Heading>Receive “need care” emails.</Heading>
                <TextStyle variation="subdued">
                  <p>Get real emails when a customer submits a need care feedback.</p>
                </TextStyle>
              </TextContainer>

              <div
                style={{
                  marginTop: '20px',
                }}
              >
                <SettingToggle enabled={active}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p>Need Care : &nbsp;</p>
                      <TextStyle variation="strong">{textStatus}</TextStyle>
                      .
                    </div>
                    {active ? (
                      <div style={{ color: '#bf0711' }}>
                        <Button onClick={handleToggle}>{contentStatus}</Button>
                      </div>
                    ) : !active ? (
                      <Button primary onClick={handleToggle}>
                        {contentStatus}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </SettingToggle>
              </div>
              {!active ? (
                <Stack distribution="trailing">
                  <div style={{ marginTop: '100px' }}>
                    <Button primary onClick={() => navigate()}>
                      Next: Auto Review
                    </Button>
                  </div>
                </Stack>
              ) : (
                <div style={{ marginTop: '20px' }}>
                  <div style={{ height: '100px', paddingTop: '20px' }}>
                    <input
                      className="input-field"
                      type="email"
                      placeholder={needCareEmail}
                      onChange={validateEmail}
                    />
                    <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
                  </div>
                  <Stack distribution="trailing">
                    {message === 'Your email looks good!' ? (
                      <Button primary onClick={() => navigate()}>
                        Next: Auto Review
                      </Button>
                    ) : (
                      <Button disabled>Next: Auto Review</Button>
                    )}
                  </Stack>
                </div>
              )}
            </div>
          ) : nav === true ? (
            <AutoReview />
          ) : (
            ''
          )}
          {' '}
        </>
      ) : (
        <DisabledNeedCare />
      )}
    </>
  );
};

export default NeedCare;
