/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-boolean-value */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  ChoiceList,
  Heading,
  Stack,
  Button,
  SettingToggle,
  TextStyle,
  Link,
  TextContainer,
  Tag,
  Popover,
  OptionList,
  Card,
  EmptyState,
  Subheading,
  Spinner,
} from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../../store/CustomStore';
import apiCaller from '../../../helper/API/apiCaller';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
// import { PlanSelected } from '../../../helper/context/planSelected';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const options = [
  { label: 'directly', value: '0 minute' },
  { label: '15 minute', value: '15 minute' },
  { label: '30 minute', value: '30 minute' },
  { label: '1 hour', value: '1 hour' },
  { label: '1.5 hour', value: '1.5 hour' },
  { label: '2 hour', value: '2 hour' },
  { label: '4 hour', value: '4 hour' },
  { label: '6 hour', value: '6 hour' },
  { label: '8 hour', value: '8 hour' },
  { label: '1 day', value: '1 day' },
  { label: '1.5 day', value: '1.5 day' },
  { label: '2 day', value: '2 day' },
  { label: '3 day', value: '3 day' },
  { label: '4 day', value: '4 day' },

];

const AutoReview = () => {
  const [active, setActive] = useState(false);
  const [modeSelected, setModeSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [reviewFormUrl, setReviewFormUrl] = useState('');
  const [edit, setEdit] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  // const [currentPlan, setCurrentPlan] = useState('');
  // const [schedule, setSchedule] = useState('directly');
  const [currentChannel, setCurrentChannel] = useState('');
  const [popoverActive, setPopoverActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const currentPlanID = store.get('planSelectedID');

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  const validateEmail = (event) => {
    const mail = event.target.value;
    if (urlRegex.test(mail)) {
      setIsValid(true);
      setReviewFormUrl(mail);
      store.get('autoReviewUrl', mail);
      setMessage('Valid Url!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid url!');
    }
  };

  const handleChange = useCallback(
    (value) => setModeSelected(value),
    [],
  );
  const handleOptionsChange = useCallback(
    (value) => setSelected(value),
    [],
  );

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Stack alignment="center">
      <Button onClick={togglePopoverActive} disclosure>
        Schedule
      </Button>
      {selected !== '' ? selected.map((item) => (
        <Stack>

          <Tag>{item}</Tag>

        </Stack>
      )) : ''}
    </Stack>

  );
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'Enabled' : 'Disabled';
  async function getShopDetails(sessionToken) {
    setFetching(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then(
      (response) => {
        const ReviewUrl = response.data.info[0].auto_review?.url;
        const reviewChannels = response.data.info[0].auto_review?.channels;
        const reviewSchedule = response.data.info[0].review_schedule;
        if (reviewChannels !== null) {
          setCurrentChannel(reviewChannels);
        }
        if (reviewSchedule !== null) {
          setSelected(reviewSchedule);
        }

        if (ReviewUrl) {
          setReviewFormUrl(ReviewUrl);
        }
        handleToggle();
        setFetching(false);
        // setModeSelected(reviewChannels);
      },
    );
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getShopDetails(sessionToken);
  }
  async function navigate() {
    store.set('autoReviewChannels', modeSelected);
    store.set('autoReviewUrl', reviewFormUrl);
    store.set('autoReviewSchedule', selected);
    // const host = store.get('HOST');
    // const app = createApp({
    //   apiKey: REACT_APP_API_Key,
    //   host,
    // });
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    // store.set('RewardFeedbackText', rewardText);
    // store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestion,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: RewardTextPrevious,
          code: RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
      toast.success('Your Updates are caught!');
    }
  }

  useEffect(() => {
    GetSessionToken();
  }, []);
  return (
    <Card>
      {fetching == true ? (
        <Spinner accessibilityLabel="Spinner example" size="large" />
      ) : fetching == false ? (
        <>
          <ToastContainer />
          {currentPlanID !== 1 ? (
            <div
              style={{
                padding: '20px ',
                // width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-evenly',
              }}
            >
              <ToastContainer />

              <div
                style={{
                  width: '100%',

                  marginBottom: '20px',
                }}
              >
                <SettingToggle enabled={active}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p>Auto Review : &nbsp;</p>
                      <TextStyle variation="strong">{textStatus}</TextStyle>
                      .
                    </div>
                    {active ? (
                      <div style={{ color: '#bf0711' }}>
                        <Button onClick={handleToggle}>{contentStatus}</Button>
                      </div>
                    ) : !active ? (
                      <Button primary onClick={handleToggle}>
                        {contentStatus}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </SettingToggle>
              </div>
              <Stack vertical={true}>
                {active ? (
                  <>
                    <TextContainer spacing="loose">
                      <Heading>Setup Auto Review</Heading>
                      <TextStyle variation="subdued">
                        <p style={{ fontSize: '12px' }}>
                          Activate Auto Review to request reviews from customers that submit
                          positive feedback
                        </p>
                      </TextStyle>
                    </TextContainer>
                    <div style={{ paddingTop: '40px' }}>
                      <Stack vertical>
                        <TextStyle variation="subdued">
                          <p style={{ fontSize: '12px', marginTop: '10px' }}>
                            Check our support page before you turn on SMS to make sure the SMS will
                            be delivered. We charge based on sent messages also if they are not
                            delivered. &nbsp;
                            <Link
                              url="https://support.qrite.com/docs/supported-countries-for-sms/"
                              removeUnderline
                              external
                            >
                              Find out more.
                            </Link>
                          </p>
                        </TextStyle>
                        <Stack alignment="center">
                          <Subheading>Current Channels:</Subheading>
                          {currentChannel
                            ? currentChannel.map((item) => <Tag>{item}</Tag>)
                            : ''}
                        </Stack>

                        {edit === false ? (
                          <>
                            <Heading>Channels</Heading>
                            <p>
                              Select the channel you want to ask your happy customers for a review.
                            </p>
                            <ChoiceList
                              allowMultiple
                              choices={[
                                { label: 'SMS*', value: 'sms' },
                                { label: 'Email*', value: 'email' },
                              ]}
                              selected={modeSelected}
                              onChange={handleChange}
                            />
                            <TextStyle variation="subdued">
                              <p>
                                *Usage charges may apply.
                                {' '}
                                <Link url="https://qrite.com/pricing" removeUnderline={true}>
                                  See pricing
                                </Link>
                                {' '}
                              </p>
                            </TextStyle>
                          </>
                        ) : edit === true ? (
                          <Button primary size="slim" onClick={() => setEdit(!edit)}>
                            Edit
                          </Button>
                        ) : (
                          ''
                        )}
                      </Stack>
                    </div>
                    <div style={{ paddingTop: '40px' }}>
                      <Stack vertical spacing="tight">
                        <Heading>Review Form URL</Heading>
                        <TextStyle variation="subdued">
                          <p>
                            On which platform do you want the review? Add a link where you want
                            customers to submit a review (Facebook, Google Reviews, etc).
                          </p>
                        </TextStyle>
                        <input
                          className="input-field"
                          type="url"
                          placeholder={reviewFormUrl}
                          onChange={validateEmail}
                        />
                        <Link url="https://help.shopify.com/manual" removeUnderline={true}>
                          Where can I find my company review form URL?
                        </Link>
                      </Stack>
                      <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
                    </div>
                    <div style={{ paddingTop: '30px', paddingBottom: '40px' }}>
                      <Popover
                        active={popoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                      >
                        <OptionList
                          onChange={handleOptionsChange}
                          options={options}
                          selected={selected}
                          allowMultiple
                        />
                      </Popover>
                      <TextStyle variation="subdued">
                        <p style={{ fontSize: '12px' }}>
                          Choose when to send the feedback request to your customers after the order
                          is fulfilled.
                        </p>
                      </TextStyle>
                    </div>

                    <Stack distribution="trailing">
                      {message === 'Valid Url!' || modeSelected !== '' || selected !== '' ? (
                        <Button primary loading={loading} onClick={() => navigate()}>
                          Save
                        </Button>
                      ) : (
                        <Button disabled>Save</Button>
                      )}
                    </Stack>
                  </>
                ) : (
                  ''
                )}
              </Stack>
            </div>
          ) : (
            <EmptyState image={DisableEmo}>
              <p>This feature has been disabled because of plan selected</p>
            </EmptyState>
          )}
        </>
      ) : (
        ''
      )}
    </Card>
  );
};

export default AutoReview;
