/* eslint-disable no-var */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-boolean-value */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  OptionList,
  Heading,
  ChoiceList,
  Stack,
  Button,
  SettingToggle,
  TextStyle,
  Link,
  TextContainer,
  Popover,
  Tag,
} from '@shopify/polaris';
import store from '../../store/CustomStore';
import RewardFeedback from './RewardFeedback';
import DisabledAutoReview from './Disabled/DisabledAutoReview';
// import apiCaller from '../../helper/API/apiCaller';
// import { PlanSelected } from '../../helper/context/planSelected';

const options = [
  { label: 'Directly', value: '0 minute' },
  { label: '15 minute', value: '15 minute' },
  { label: '30 minute', value: '30 minute' },
  { label: '1 hour', value: '1 hour' },
  { label: '1.5 hour', value: '1.5 hour' },
  { label: '2 hour', value: '2 hour' },
  { label: '4 hour', value: '4 hour' },
  { label: '6 hour', value: '6 hour' },
  { label: '8 hour', value: '8 hour' },
  { label: '1 day', value: '1 day' },
  { label: '1.5 day', value: '1.5 day' },
  { label: '2 day', value: '2 day' },
  { label: '3 day', value: '3 day' },
  { label: '4 day', value: '4 day' },

];

const AutoReview = (props) => {
  const [nav, setNav] = useState(false);
  const [active, setActive] = useState(true);
  const [modeSelected, setModeSelected] = useState('');
  const [reviewFormUrl, setReviewFormUrl] = useState('');
  const [url, setUrl] = useState('Please Enter a URL');
  const [selected, setSelected] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  // const [currentPlan, setCurrentPlan] = useState('');
  const SubscriptionId = props.planId;

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  async function navigate() {
    store.set('autoReviewChannels', modeSelected);

    store.set('autoReviewUrl', reviewFormUrl);
    store.set('autoReviewSchedule', selected);

    setNav(!nav);
    props.changeColor('Reward Feedback');
  }
  const validateEmail = (event) => {
    const mail = event.target.value;
    if (urlRegex.test(mail)) {
      setIsValid(true);
      setReviewFormUrl(mail);
      setMessage('Valid Url!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid url!');
    }
  };

  const handleChange = useCallback(
    (value) => setModeSelected(value),

    [],
  );

  const activator = (
    <Stack alignment="center">
      <Button onClick={togglePopoverActive} disclosure>
        Schedule
      </Button>
      { selected.map((item) => (
        <Stack>

          <Tag>{item}</Tag>

        </Stack>
      )) }
    </Stack>

  );

  const handleToggle = useCallback(() => setActive((active) => !active), []);

  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'Enabled' : 'Disabled';
  useEffect(() => {
    const channels = store.get('autoReviewChannels');
    const url = store.get('autoReviewUrl');
    const schedule = store.get('autoReviewSchedule');
    if (channels) {
      setModeSelected(channels);
      setUrl(url);
      setSelected(schedule);
    }
  }, []);
  return (
    <>
      {SubscriptionId !== 1 ? (
        <>
          {' '}
          {nav === false ? (
            <div
              style={{
                padding: '20px ',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-evenly',
              }}
            >
              <TextContainer spacing="loose">
                <Heading>Setup Auto Review</Heading>
                <TextStyle variation="subdued">
                  <p style={{ fontSize: '12px' }}>
                    Activate Auto Review to request reviews from customers that submit positive
                    feedback
                  </p>
                </TextStyle>
              </TextContainer>

              <div
                style={{
                  width: '100%',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
              >
                <SettingToggle enabled={active}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p>Auto Review : &nbsp;</p>
                      <TextStyle variation="strong">{textStatus}</TextStyle>
                      .
                    </div>
                    {active ? (
                      <div style={{ color: '#bf0711' }}>
                        <Button onClick={handleToggle}>{contentStatus}</Button>
                      </div>
                    ) : !active ? (
                      <Button primary onClick={handleToggle}>
                        {contentStatus}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </SettingToggle>
              </div>
              <Stack vertical={true}>
                {active ? (
                  <>
                    <div style={{ paddingTop: '40px' }}>
                      <ChoiceList
                        allowMultiple
                        title={(
                          <Stack vertical>
                            <Heading>Channels</Heading>
                            <p>
                              Select the channel you want to ask your happy customers for a review.
                            </p>
                          </Stack>
                        )}
                        choices={[
                          { label: 'SMS*', value: 'sms' },
                          { label: 'Email*', value: 'email' },
                        ]}
                        selected={modeSelected}
                        onChange={handleChange}
                      />
                      <TextStyle variation="subdued">
                        <p>
                          *Usage charges may apply.
                          {' '}
                          <Link url="https://qrite.com/pricing" removeUnderline={true}>
                            See pricing
                          </Link>
                          {' '}
                        </p>
                      </TextStyle>
                    </div>
                    <div style={{ paddingTop: '40px' }}>
                      <Stack vertical spacing="tight">
                        <Heading>Review Form URL</Heading>
                        <TextStyle variation="subdued">
                          <p>
                            On which platform do you want the review? Add a link where you want
                            customers to submit a review (Facebook, Google Reviews, etc).
                          </p>
                        </TextStyle>
                        <input
                          className="input-field"
                          type="url"
                          // value={reviewFormUrl}
                          placeholder={url}
                          onChange={validateEmail}
                        />
                        <Link
                          url="https://help.shopify.com/manual"
                          external={true}
                          removeUnderline={true}
                        >
                          Where can I find my company review form URL?
                        </Link>
                      </Stack>
                      <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
                    </div>
                    <div style={{ paddingTop: '30px', paddingBottom: '40px' }}>
                      <Popover
                        active={popoverActive}
                        activator={activator}
                        onClose={togglePopoverActive}
                      >
                        <OptionList
                          // title="Manage sales channels availability"
                          onChange={setSelected}
                          options={options}
                          selected={selected}
                          allowMultiple
                        />
                      </Popover>
                    </div>

                    <Stack distribution="trailing">
                      {message === 'Valid Url!' && modeSelected !== '' && selected.length !== 0 ? (
                        <Button primary onClick={() => navigate()}>
                          Next: Reward Feedback
                        </Button>
                      ) : (
                        <Button disabled>Next: Reward Feedback</Button>
                      )}
                    </Stack>
                  </>
                ) : !active ? (
                  <div style={{ marginTop: '50px' }}>
                    <Stack distribution="trailing">
                      <Button primary onClick={() => navigate()}>
                        Next: Reward Feedback
                      </Button>
                    </Stack>
                  </div>
                ) : (
                  ''
                )}
                {' '}
              </Stack>
            </div>
          ) : nav === true ? (
            <RewardFeedback />
          ) : (
            ''
          )}
        </>
      ) : (
        <DisabledAutoReview />
      )}
    </>
  );
};

export default AutoReview;
