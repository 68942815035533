/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import {
  Card,
  Icon,
  Stack,
  Heading,
  DisplayText,
} from '@shopify/polaris';
import { InfoMinor } from '@shopify/polaris-icons';
import NPSTiles from '../../../assets/Feedback Tiles/NPSTiles';
// import NPSIndicator from '../../../assets/Feedback Tiles/NPSIndicator';

const NPSFeedback = ({ score }) => (
  <>
    <Card
      sectioned
      title={(
        <Stack vertical>
          <Stack>
            <Heading>NPS feedback</Heading>
            <Icon source={InfoMinor} color="subdued" />
          </Stack>
          <Stack.Item fill>
            <DisplayText>{score}</DisplayText>
          </Stack.Item>
          <NPSTiles />
          {/* <NPSIndicator /> */}
        </Stack>
      )}
    />
  </>
);

export default NPSFeedback;
