/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useCallback, useEffect,
} from 'react';
import {
  Card,
  TextStyle,
  Stack,
  TextField,
  Heading,
  Button,
  EmptyState,
  SettingToggle,
  Spinner,
} from '@shopify/polaris';
import { ToastContainer } from 'react-toastify';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../../store/CustomStore';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
import apiCaller from '../../../helper/API/apiCaller';
// import { PlanSelected } from '../../../helper/context/planSelected';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const RewardFeedback = () => {
  const [rewardCode, setRewardCode] = useState('');
  const [code, setCode] = useState('Enter the shopify discount code here');
  const [text, setText] = useState(
    'Enter your reward text, explain the discount your customer gets after submitting feedback.',
  );
  const [rewardText, setRewardText] = useState('');
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const currentPlanID = store.get('planSelectedID');

  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'Enabled' : 'Disabled';

  const handleRewardCodeChange = useCallback((newValue) => setRewardCode(newValue), []);
  const handleRewardTextChange = useCallback((newValue) => setRewardText(newValue), []);
  async function getShopDetails(sessionToken) {
    setFetching(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const data = response.data.info[0];
      const rewardCode = data.reward_feedback?.code;
      const rewardText = data.reward_feedback?.text;

      setRewardCode(rewardCode);
      setRewardText(rewardText);
      if (rewardText) {
        handleToggle();
      }
      setFetching(false);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getShopDetails(sessionToken);
  }
  async function navigate() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    setLoading(true);
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    store.set('RewardFeedbackText', rewardText);
    store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestion,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: rewardText !== '' ? rewardText : RewardTextPrevious,
          code: rewardCode !== '' ? rewardCode : RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
    }
  }

  useEffect(() => {
    GetSessionToken();
    const rewardText = store.get('RewardFeedbackText');
    const rewardCode = store.get('RewardFeedbackCode');
    if (rewardText) {
      setText(rewardText);
      setCode(rewardCode);
    }
  }, []);
  return (
    <div>
      <Card sectioned>
        {fetching == true ? (
          <Spinner accessibilityLabel="Spinner example" size="large" />
        ) : fetching == false ? (
          <>
            {currentPlanID !== 1 ? (
              <>
                <ToastContainer />
                <SettingToggle enabled={active}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <p>RewardFeedback : &nbsp;</p>
                      <TextStyle variation="strong">{textStatus}</TextStyle>
                      .
                    </div>
                    {active ? (
                      <div style={{ color: '#bf0711' }}>
                        <Button onClick={handleToggle}>{contentStatus}</Button>
                      </div>
                    ) : !active ? (
                      <Button primary onClick={handleToggle}>
                        {contentStatus}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </SettingToggle>
                {active ? (
                  <>
                    <div style={{ marginTop: '20px' }}>
                      <TextStyle variation="strong">
                        <Heading>Reward customer feedback with a discount code</Heading>
                      </TextStyle>
                      <TextStyle variation="subdued">
                        <p style={{ fontSize: '12px' }}>
                          Give customers a discount code for sharing their feedback to encourage
                          another purchase
                        </p>
                      </TextStyle>

                      <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <TextStyle variation="strong">Custom Reward Text</TextStyle>
                        <TextField
                          value={rewardText}
                          onChange={handleRewardTextChange}
                          placeholder={text}
                          multiline={4}
                        />
                      </div>
                      <div style={{ paddingTop: '20px' }}>
                        <TextStyle variation="strong">Reward Code</TextStyle>
                        <TextField
                          value={rewardCode}
                          onChange={handleRewardCodeChange}
                          placeholder={code}
                        />

                        <TextStyle variation="subdued">
                          <p style={{ fontSize: '12px' }}>
                            You can create a coupon code on the Shopify Discount page and past the
                            general code here
                          </p>
                        </TextStyle>
                      </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <Stack distribution="trailing">
                        <Button primary loading={loading} onClick={() => navigate()}>
                          Save
                        </Button>
                      </Stack>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <EmptyState image={DisableEmo}>
                <p>This feature has been disabled because of plan selected</p>
              </EmptyState>
            )}
          </>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
};

export default RewardFeedback;
