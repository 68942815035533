/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-loop-func */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import {
  Card,
  Tabs,
  Stack,
  DisplayText,
  EmptyState,
  SkeletonBodyText,
  Spinner,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import FilterData from '../../../helper/FilterData';
import { COLUMNS } from '../../React Table/ColumnHeader';
import apiCaller from '../../../helper/API/apiCaller';
import './Helper/tableStyles.css';
import Pagination from '../../../helper/Pagination';
import FILTER_IMAGE from '../../../assets/EmptyStateSvg/filter.svg';
import '../../../assets/styles/react_paginateStyles.css';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const today = new Date();
const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
var filterDate = {
  from_date: startDay.toISOString().split('T')[0],
  to_date: nextweek.toISOString().split('T')[0],
};
const Metrics = () => {
  const [feedback, setFeedback] = useState([]);
  const [counts, setCounts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [view, setView] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState(0);

  const feedbackDataPerPage = 10;
  const pagesVisited = pageNumber * feedbackDataPerPage;
  const pageCount = Math.ceil(feedback.length / feedbackDataPerPage);
  var FilterValue = '';
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //  <-----------------------||||||||||||||||--------------------------------------->

  const tabs = [
    {
      id: 'All',
      content: (
        <Stack vertical>
          <p>All</p>
          {fetching ? (
            <Spinner accessibilityLabel="Spinner example" size="small" />
          ) : (
            <DisplayText>{counts.Total}</DisplayText>
          )}
        </Stack>
      ),
      panelID: 'All',
    },
    {
      id: 'Unhappy',
      content: (
        <Stack vertical>
          <p>Unhappy</p>
          {fetching ? (
            <Spinner accessibilityLabel="Spinner example" size="small" />
          ) : (
            <DisplayText>{counts.detractor}</DisplayText>
          )}
        </Stack>
      ),
      panelID: 'Unhappy',
    },
    {
      id: 'Passives',
      content: (
        <Stack vertical>
          <p>Passives</p>
          {fetching ? (
            <Spinner accessibilityLabel="Spinner example" size="small" />
          ) : (
            <DisplayText>{counts.passive}</DisplayText>
          )}
        </Stack>
      ),
      panelID: 'Passives',
    },
    {
      id: 'Happy',
      content: (
        <Stack vertical>
          <p>Happy</p>
          {fetching ? (
            <Spinner accessibilityLabel="Spinner example" size="small" />
          ) : (
            <DisplayText>{counts.promoter}</DisplayText>
          )}
        </Stack>
      ),
      panelID: 'Happy',
    },
  ];
  function setTabId() {
    FilterValue = tabs[selected].id;
  }
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    setTabId(),

    // ,

    [],
  );

  async function getFilteredData() {
    setFetching(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    const URL = 'getFeedbacks';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const MERCHANT_ID = store.get('merchant_id');
    const OFFSET = 0;
    const LIMIT = 100;
    const data = {
      merchant_id: parseInt(MERCHANT_ID),
      offset: OFFSET,
      limit: LIMIT,
      from: filterDate.from_date,
      to: filterDate.to_date,
    };
    // try {
    await apiCaller(URL, headers, data).then((response) => {
      const Total = response.data.counts.total;
      const DATA = response.data.data;
      const promoter = DATA.filter((item) => item.value > 8).length;
      const passive = DATA.filter((item) => item.value >= 6 && item.value <= 8).length;
      const detractor = DATA.filter((item) => item.value < 6).length;
      const COUNTS = {
        promoter,
        passive,
        detractor,
        Total,
      };
      setFeedback(DATA);
      setCounts(COUNTS);
      setView(true);
      setFetching(false);
    });
  }

  const displayFeedback = feedback
    .filter((option) => {
      if (FilterValue === 'All') {
        return option;
      }
      if (FilterValue === 'Unhappy') {
        return option.value < 6;
      }
      if (FilterValue === 'Passives') {
        return option.value >= 6 && option.value <= 8;
      }
      if (FilterValue === 'Happy') {
        return option.value > 8;
      }
    })
    .slice(pagesVisited, pagesVisited + feedbackDataPerPage)
    .map((d) => (
      <tbody key={d.id}>
        <tr key={d.id}>
          <td data-label="Feedback - Rating">{d.value}</td>
          <td data-label="Feedback Text">{d.open_text}</td>
          <td data-label="Customer Name">{d.customer_name}</td>
          <td data-label="Order Number">{d.shopify_order_id}</td>
          <td data-label="Customer Phone">{d.customer_phone}</td>
          <td data-label="Customer Email">{d.customer_email}</td>
        </tr>
      </tbody>
    ));
  const handleFilterDate = (value) => {
    filterDate = { from_date: value.start, to_date: value.end };
    getFilteredData();
  };
  // async function GetSessionToken() {
  //   const today = new Date();
  //   const startDay = new Date(
  //     today.getFullYear(),
  //     today.getMonth(),
  //     today.getDate(),
  //   );
  //   const nextweek = new Date(
  //     today.getFullYear(),
  //     today.getMonth(),
  //     today.getDate() + 7,
  //   );
  //   const START_DATE = startDay.toISOString();
  //   const END_DATE = nextweek.toISOString();
  //   getFilteredData(START_DATE, END_DATE);
  // }
  useEffect(() => {
    getFilteredData();
  }, []);

  return (
    <>
      <div
        style={{
          // padding: '30px 0px ',
          paddingBottom: '15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          width: 'fit-content',
        }}
      >
        <FilterData filterDate={(value) => handleFilterDate(value)} />
      </div>

      <Card>
        <Tabs tabs={tabs} fitted selected={selected} onSelect={handleTabChange} />

        {fetching ? (
          <EmptyState>
            <SkeletonBodyText />
          </EmptyState>
        ) : !fetching ? (
          <>
            <table className="styled-table">
              <thead>
                <tr>
                  {COLUMNS.map((value) => (
                    <th style={{ padding: '30px 10px' }} key={value.id}>
                      {value.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              {displayFeedback}
            </table>
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName="paginationButtons"
              previousLinkClassName="previousButton"
              nextLinkClassName="nextButton"
              activeClassName="paginationActive"
            />
          </>
        ) : (
          ''
        )}
      </Card>
    </>
  );
};

export default Metrics;
