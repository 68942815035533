/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  TextStyle,
  Stack,
  TextField,
  Button,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { ToastContainer, toast } from 'react-toastify';
import { getSessionToken } from '@shopify/app-bridge-utils';
import JoDit from '../../../assets/TextEditor/JoDit';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

const { REACT_APP_API_Key } = process.env;
const FeedBackEmail = () => {
  const [emailContent, setEmailContent] = useState('');
  const [subject, setSubject] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [requesting, setRequesting] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;
  const variables = {
    feedback_link: '{feedback_link}',
    name: '{merchant_name}',
    url: '{merchant_url}',
    email: '{merchant_email}',
    phone: '{merchant_telephone}',
    plan: '{qrite_plan}',
    rating: '{feedback_rating}',
    explanation: '{feedback_explanation}',
    channel: '{qrite_channels}',
    shopName: '{shop_name}',
  };
  async function sendTestEmail() {
    setRequesting(true);
    const MERCHANT_ID = store.get('merchant_id');
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);

    const ENDPOINT = 'sendTestNotification';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      merchantId: parseInt(MERCHANT_ID),
      content: emailContent,
      subject,
      type: 'email',
      to: email,
    };
    const response = await apiCaller(ENDPOINT, headers, data);
    const { message } = response.data;
    if (message == 'success') {
      setRequesting(false);
      toast.success('Test Email sent!');
    }
  }

  const validateEmail = (event) => {
    const mail = event.target.value;
    if (emailRegex.test(mail)) {
      setIsValid(true);
      setEmail(mail);
      setMessage('Your email looks good!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
    }
  };

  const handleSubjectChange = useCallback(
    (newValue) => setSubject(newValue),
    [],
  );
  // async function GetSessionToken() {
  //   const host = store.get('HOST');
  //   const app = createApp({
  //     apiKey: REACT_APP_API_Key,
  //     host,
  //   });
  //   sessionToken = await getSessionToken(app);
  // }
  // useEffect(() => {
  //   GetSessionToken();
  // }, []);
  return (
    <Card title="Feedback Email" sectioned>
      <ToastContainer />
      <Stack vertical>
        <TextField
          label={<TextStyle variation="strong">Subject</TextStyle>}
          value={subject}
          placeholder="Please Enter Subject"
          onChange={handleSubjectChange}
        />
        <JoDit content={(value) => setEmailContent(value)} />

        <Stack Item vertical>
          <TextStyle variation="strong">Available Variables:</TextStyle>
          <Stack>
            <p>{variables.name}</p>
            <p>{variables.feedback_link}</p>
            <p>{variables.email}</p>
            <p>{variables.phone}</p>
            <p>{variables.plan}</p>
            <p>{variables.channel}</p>
            <p>{variables.explanation}</p>
            <p>{variables.rating}</p>
            <p>{variables.shopName}</p>
          </Stack>
        </Stack>
        <div style={{ marginTop: '10px' }}>
          <Stack vertical>
            <TextStyle variation="strong">Send Test Email</TextStyle>
            <TextStyle variation="strong">Feedback Form Email</TextStyle>
            <input
              className="input-field"
              type="email"
              placeholder="info@qrite.com"
              onChange={validateEmail}
            />
            <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
          </Stack>
        </div>
        <Stack>
          <Button primary loading={requesting} size="slim" onClick={() => sendTestEmail()}>
            Send test mail
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FeedBackEmail;
