/* eslint-disable operator-linebreak */
/* eslint-disable linebreak-style */
/* eslint-disable eqeqeq */

/* eslint-disable no-nested-ternary */

/* eslint-disable camelcase */

/* eslint-disable no-var */

/* eslint-disable radix */

import React, { useState, useEffect } from 'react';

// eslint-disable-next-line object-curly-newline
import { Stack, Subheading, TextStyle, Heading, ProgressBar } from '@shopify/polaris';

import createApp from '@shopify/app-bridge';

import { getSessionToken } from '@shopify/app-bridge-utils';

import BreakupModal from './previews/BreakupModal';

import apiCaller from '../../../helper/API/apiCaller';

import store from '../../../store/CustomStore';

var sessionToken = '';

const { REACT_APP_API_Key } = process.env;

const BillUsage = () => {
  // const [plan, setPlan] = useState('');

  const [advancedEmail, setAdvancedEmail] = useState({ count: 0, usage: 0 });

  const [advancedSMS, setAdvancedSMS] = useState({ count: 0, usage: 0 });

  const [growthEmail, setGrowthEmail] = useState({ count: 0, usage: 0 });

  const [growthSMS, setGrowthSMS] = useState({ count: 0, usage: 0 });

  const [freeEmail, setFreeEmail] = useState({ count: 0, usage: 0 });

  const [freeSMS, setFreeSMS] = useState({ count: 0, usage: 0 });

  const [totalEmail, setTotalEmail] = useState({ count: 0, usage: 0 });

  const [totalSMS, setTotalSMS] = useState({ count: 0, usage: 0 });

  const currentPlan = store.get('planSelected');

  async function getUsageTracking(sessionToken) {
    const headers = {
      'Content-Type': 'application/json',

      Authorizer: sessionToken,
    };

    const URL = 'getUsageCounts';

    const MERCHANT_ID = store.get('merchant_id');

    const data = {
      merchant_id: MERCHANT_ID,
    };

    await apiCaller(URL, headers, data).then((response) => {
      // if (AdvancedData) {

      const AdvancedEmailCount = response.data.Advanced.emailCount;

      const AdvancedEmailUsage = response.data.Advanced.emailUsage;

      const AdvancedSmsCount = response.data.Advanced.smsCount;

      const AdvancedSmsUsage = response.data.Advanced.smsUsage;

      setAdvancedEmail({
        count: AdvancedEmailCount,

        usage: AdvancedEmailUsage,
      });

      setAdvancedSMS({
        count: AdvancedSmsCount,

        usage: AdvancedSmsUsage,
      });

      // }

      // if (GrowthData) {

      const GrowthEmailCount = response.data.Growth.emailCount;

      const GrowthEmailUsage = response.data.Growth.emailUsage;

      const GrowthSmsCount = response.data.Growth.smsCount;

      const GrowthSmsUsage = response.data.Growth.smsUsage;

      setGrowthEmail({
        count: GrowthEmailCount,

        usage: GrowthEmailUsage,
      });

      setGrowthSMS({
        count: GrowthSmsCount,

        usage: GrowthSmsUsage,
      });

      // }

      // if (FreeData) {

      const FreeEmailCount = response.data.Free.emailCount;

      const FreeEmailUsage = response.data.Free.emailUsage;

      const FreeSmsCount = response.data.Free.smsCount;

      const FreeSmsUsage = response.data.Free.smsUsage;

      setFreeEmail({
        count: FreeEmailCount,

        usage: FreeEmailUsage,
      });

      setFreeSMS({
        count: FreeSmsCount,

        usage: FreeSmsUsage,
      });

      // }

      const TOTALEMAILCOUNT =
        parseInt(AdvancedEmailCount) + parseInt(GrowthEmailCount) + parseInt(FreeEmailCount);

      const TOTALEMAILUSAGE =
        parseInt(AdvancedEmailUsage) + parseInt(GrowthEmailUsage) + parseInt(FreeEmailUsage);

      const TOTALSMSCOUNT =
        parseInt(AdvancedSmsCount) + parseInt(GrowthSmsCount) + parseInt(FreeSmsCount);

      const TOTALSMSUSAGE =
        parseInt(AdvancedSmsUsage) + parseInt(GrowthSmsUsage) + parseInt(FreeSmsUsage);

      setTotalEmail({
        count: Math.round(TOTALEMAILCOUNT),

        usage: TOTALEMAILUSAGE,
      });

      setTotalSMS({
        count: Math.round(TOTALSMSCOUNT),

        usage: TOTALSMSUSAGE,
      });
    });
  }

  async function GetSessionToken() {
    const host = store.get('HOST');

    const app = createApp({
      apiKey: REACT_APP_API_Key,

      host,
    });

    sessionToken = await getSessionToken(app);

    getUsageTracking(sessionToken);
  }

  useEffect(() => {
    GetSessionToken();
  }, []);

  return (
    <Stack vertical>
      <Stack.Item>
        <Heading>Current Usage</Heading>
      </Stack.Item>

      <Stack.Item>
        <Stack alignment="baseline">
          <TextStyle variation="subdued">Plan</TextStyle>

          <Stack alignment="baseline">
            <Subheading>{currentPlan}</Subheading>

            <BreakupModal
              emailUsage={advancedEmail}
              smsUsage={advancedSMS}
              growthEmailUsage={growthEmail}
              growthSmsUsage={growthSMS}
              freeEmailUsage={freeEmail}
              freeSmsUsage={freeSMS}
            />
          </Stack>
        </Stack>
      </Stack.Item>

      <Stack vertical distribution="equalSpacing">
        <Stack.Item>
          <Stack alignment="center">
            <Subheading>EMAIL</Subheading>

            <div style={{ width: '200px' }}>
              <ProgressBar progress={totalEmail.usage} />
            </div>

            <Heading>{totalEmail.count}</Heading>
          </Stack>
        </Stack.Item>

        <Stack.Item>
          <Stack alignment="center">
            <Subheading>SMS</Subheading>

            <div style={{ width: '200px', marginLeft: '11px' }}>
              <ProgressBar progress={totalSMS.usage} />
            </div>

            <Heading>{totalSMS.count}</Heading>
          </Stack>
        </Stack.Item>
      </Stack>
    </Stack>
  );
};

export default BillUsage;
