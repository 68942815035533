/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  Stack,
  Card,
  Layout,
  Subheading,
  TextStyle,
  Button,
  Link,
  Modal,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import BillUsage from './BillUsage';
import ChangePlan from './UpgradePlanComponents/ChangePlan';
import apiCaller from '../../../helper/API/apiCaller';
// import { PlanSelected } from '../../../helper/context/planSelected';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const PlanDetails = () => {
  const [status, setStatus] = useState('');
  const [memberSince, setMemberSince] = useState('');
  const [active, setActive] = useState(false);
  const currentPlanId = store.get('planSelectedID');

  const handleChange = useCallback(() => setActive(!active), [active]);

  const activator = <Button primary onClick={handleChange}>Upgrade Plan</Button>;
  const data = [
    {
      id: '1',
      title: 'Member since',
      content: memberSince,
    },
    {
      id: '2',
      title: 'Current Plan',
      content: currentPlanId === 3 ? 'Advanced'
        : currentPlanId === 2 ? 'Growth'
          : currentPlanId === 1 ? 'Free' : '',
    },
    {
      id: '3',
      title: 'Status',
      content: status === true ? 'Active' : status === false ? 'Not Active' : '',
    },
  ];

  async function getShopDetails(sessionToken) {
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const MemberDate = response.data.info[0].created_at;
      const Active = response.data.info[0].active;
      // const OpenTextQuestion = response.data.info[0].open_text_question;
      const clipped = MemberDate.split('T')[0];
      setMemberSince(clipped);
      setStatus(Active);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getShopDetails(sessionToken);
  }

  useEffect(() => {
    GetSessionToken();
  }, []);

  const ChangePlanModal = (

    <Modal large activator={activator} open={active} onClose={handleChange}>
      <Modal.Section>
        <ChangePlan />
      </Modal.Section>
    </Modal>

  );
  return (
    <div style={{ width: '615px', marginLeft: '-20px' }}>
      <Layout.Section>
        <Card>
          <Card.Section>
            <Stack>
              {data.map((data) => (
                <Stack vertical spacing="tight" key={data.id}>
                  <Subheading>{data.title}</Subheading>
                  <TextStyle variation="subdued">{data.content}</TextStyle>
                </Stack>
              ))}
            </Stack>
          </Card.Section>
          <Card.Section>
            <Stack vertical>
              <p>
                Grow your business by upgrading
              </p>

            </Stack>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '20px',
              }}
            >
              <Stack vertical>
                {/* <Button primary>Upgrade Plan</Button> */}
                {ChangePlanModal}
                <p>
                  View our
                  {' '}
                  <Link removeUnderline url="https://qrite.com/terms" external> terms of service</Link>
                  {' '}
                  and
                  {' '}
                  <Link removeUnderline url="https://qrite.com/privacy" external>privacy policy</Link>
                </p>
              </Stack>
            </div>
          </Card.Section>
          <Card.Section>
            <BillUsage />
          </Card.Section>
        </Card>
      </Layout.Section>
    </div>
  );
};

export default PlanDetails;
