/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable radix */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useCallback } from 'react';
import {
  Card,
  TextStyle,
  Stack,
  TextField,
  Button,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ToastContainer, toast } from 'react-toastify';
import '../../../assets/styles/textarea.css';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

const { REACT_APP_API_Key } = process.env;
const ReviewSms = () => {
  const [value, setValue] = useState(`Hi Hemanth, thank you for your purchase from {shop_name}. We would love to get feedback to make your experience better next time!
{feedback_link}`);
  const [char, setChar] = useState(0);
  const [url, setUrl] = useState('');
  const [number, setNumber] = useState();
  const [requesting, setRequesting] = useState(false);
  // const [number, setNumber] = useState('');
  const variables = {
    feedback_link: '{feedback_link}',
    name: '{merchant_name}',
    url: '{merchant_url}',
    email: '{merchant_email}',
    phone: '{merchant_telephone}',
    plan: '{qrite_plan}',
    rating: '{feedback_rating}',
    explanation: '{feedback_explanation}',
    channel: '{qrite_channels}',
    shopName: '{shop_name}',
  };

  const handleChange = (val) => {
    setValue(val);
    setChar(val.length);
  };
  const handleUrlChange = useCallback(
    (newValue) => setUrl(newValue),
    // setChar(newValue),
    [],
  );

  async function sendTestSms() {
    setRequesting(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const MERCHANT_ID = store.get('merchant_id');

    const ENDPOINT = 'sendTestNotification';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      merchantId: parseInt(MERCHANT_ID),

      content: value,
      type: 'sms',
      to: number,
    };
    const response = await apiCaller(ENDPOINT, headers, data);
    const { message } = response.data;
    if (message == 'success') {
      setRequesting(false);
      toast.success('Test SMS sent!');
    }
  }
  // async function GetSessionToken() {
  //   const host = store.get('HOST');
  //   const app = createApp({
  //     apiKey: REACT_APP_API_Key,
  //     host,
  //   });
  //   sessionToken = await getSessionToken(app);
  // }
  // useEffect(() => {
  //   GetSessionToken();
  // }, []);

  return (
    <Card sectioned>
      <ToastContainer />
      <Stack vertical>
        <textarea value={value} onChange={(e) => handleChange(e.target.value)} />
        <p
          style={{
            fontSize: '12px',
            textAlign: 'right',
            color: '#E95669',
            fontWeight: 'bold',
          }}
        >
          {char <= 160 ? `${char}/ 1` : `${char}/ 2`}
        </p>
        <Stack Item vertical>
          <TextStyle variation="strong">Available Variables:</TextStyle>
          <Stack>
            <p>{variables.name}</p>
            <p>{variables.feedback_link}</p>
            <p>{variables.email}</p>
            <p>{variables.phone}</p>
            <p>{variables.plan}</p>
            <p>{variables.channel}</p>
            <p>{variables.explanation}</p>
            <p>{variables.rating}</p>
            <p>{variables.shopName}</p>
          </Stack>
        </Stack>
        <TextField
          label={<TextStyle variation="strong">Review Form URL</TextStyle>}
          value={url}
          placeholder="qrite.com"
          onChange={handleUrlChange}
        />

        <Button plain textAlign="left">
          Where can I find my company review form URL?
        </Button>

        <Stack vertical>
          <TextStyle variation="strong">Send Test SMS</TextStyle>
          <TextStyle variation="subdued">
            <p> Don't forget to include your country code! Format: +[countrycode without 0]</p>
          </TextStyle>
        </Stack>
        <PhoneInput placeholder="Enter phone number" value={number} onChange={setNumber} />

        <Stack>
          <Button primary size="slim" loading={requesting} onClick={() => sendTestSms()}>
            Send test SMS
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ReviewSms;
