/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';

import {
  RadioButton,
  Stack,
  TextContainer,
  Select,
  Form,
  Button,
  FormLayout,
  TextField,
  Heading,
  Card,
  TextStyle,
} from '@shopify/polaris';
import store from '../../store/CustomStore';
import Addlogo from './AddLogo';
// import apiCaller from '../../helper/API/apiCaller';

import NPS from '../../assets/FeedbackCat/NPS';
import CSAT from '../../assets/FeedbackCat/CSAT';

const feedbackStyles = { display: 'flex', paddingTop: '10px' };
const Questions = (props) => {
  const [nav, setNav] = useState(false);
  const [type, setType] = useState('');
  const [npsQuestion, setNPSQuestion] = useState('');

  const [openText, setOpenText] = useState('false');
  const [csatQuestion, setCSATQuestion] = useState(
    ' Would you recommend our products/services to your friends and family?',
  );

  const [csatCustomQuestion, setCSATCustomQuestion] = useState('Enter Custom Question');
  const [openTextQuestion, setOpenTextQuestion] = useState('');
  // const [questionsSelected, setQuesttionsSelected] = useState([]);

  function questionRecieved(val) {
    setNPSQuestion(val);
  }
  const handleQuestion = (e) => {
    setCSATCustomQuestion(e.target.value);
  };
  // const handleQuestion = useCallback((newValue) => setCSATCustomQuestion(newValue), []);
  const handleQuestion2 = useCallback((newValue) => setOpenTextQuestion(newValue), []);

  async function navigate() {
    if (type === 'net_promoter_score') {
      store.set('Question', npsQuestion);
    } else if (
      type === 'customer_satisfaction_score'
      && csatQuestion !== 'Custom question option selected!'
    ) {
      store.set('Question', csatQuestion);
    } else if (
      type === 'customer_satisfaction_score'
      && csatQuestion === 'Custom question option selected!'
    ) {
      store.set('Question', csatCustomQuestion);
    }
    store.set('typeOfQuestion', type);
    if (openText === 'true') {
      store.set('showOpenText', true);
    } else if (openText === 'false') {
      store.set('showOpenText', false);
    }
    store.set('openTextQuestion', openTextQuestion);
    setNav(!nav);
    props.changeColor('Add Logo');
  }

  const handleCSATQuestionSelectChange = useCallback(
    (selected) => setCSATQuestion(selected),

    [],
  );

  const handleChange = useCallback(
    (_checked, value) => setType(value),

    [],
  );
  // function checkOpenTextField(val) {
  //   if (val === 'yes') {
  //     setOpenTextQuestion(true);
  //   } else if (val === 'no') {
  //     setOpenTextQuestion(false);
  //   }
  // }
  const handleOpenTextSelect = useCallback(
    (_checked, openText) => setOpenText(openText),

    [],
  );

  const handleSubmit = useCallback(() => {
    setSelected(selected);
    setValue(value);
    setOpenText(openText);
  }, []);

  const options = [
    {
      label: 'Would you recommend our products or services to your..',
      value: 'Would you recommend our products or services to your friend or family member?',
    },
    {
      label: 'Did our product range meet your expectations?',
      value: 'Did our product range meet your expectations?',
    },
    {
      label: 'How was your experience with our website?',
      value: 'How was your experience with our website?',
    },
    {
      label: 'How likely are you to buy again from us?',
      value: 'How likely are you to buy again from us?',
    },
    {
      label: 'Create Your own Question?',
      value: 'Custom question option selected!',
    },
  ];

  useEffect(() => {
    const type = store.get('typeOfQuestion');
    const question = store.get('Question');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    if (type) {
      setType(type);
      {
        type == 'net_promoter_score'
          ? setNPSQuestion(question)
          : type == 'customer_satisfaction_score'
            ? setCSATQuestion(question)
            : '';
      }
      { showOpenText == true ? setOpenText('true') : showOpenText == false ? setOpenText('false') : '';
      }
      { openTextQuestion ? setOpenTextQuestion(openTextQuestion) : '';
      }
    }
  }, []);

  return (
    <>
      {nav === false ? (
        <div style={{ padding: '20px', width: '100%' }}>
          <TextContainer spacing="loose">
            <Heading>Select the type of question you want to ask.</Heading>
          </TextContainer>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Stack vertical>
                <div style={feedbackStyles}>
                  <Stack vertical>
                    <RadioButton
                      label={(
                        <div style={{ width: '80%' }}>
                          <Heading>Net Promoter Score</Heading>
                          <TextStyle variation="subdued">
                            <p style={{ fontSize: '12px' }}>
                              Select this if you want to measure customer loyalty and how likely
                              they are to refer your products and services to others.
                            </p>
                          </TextStyle>
                        </div>
                      )}
                      checked={type === 'net_promoter_score'}
                      id="net_promoter_score"
                      name="net_promoter_score"
                      onChange={handleChange}
                    />
                    <NPS npsQuestion={(nps) => questionRecieved(nps)} />
                  </Stack>
                </div>

                <div style={feedbackStyles}>
                  <Stack>
                    <RadioButton
                      label={(
                        <div style={{ width: '80%' }}>
                          <Heading>Customer Satisfaction Score</Heading>
                          <TextStyle variation="subdued">
                            <p style={{ fontSize: '12px' }}>
                              Select this if you want to measure customer satisfaction, it can help
                              provide useful data for changing and improving your business practices
                              to better meet the needs of your clients.
                            </p>
                          </TextStyle>
                        </div>
                      )}
                      id="customer_satisfaction_score"
                      name="customer_satisfaction_score"
                      checked={type === 'customer_satisfaction_score'}
                      onChange={handleChange}
                    />

                    {/* //////////////////////////////////////////// */}
                    <div
                      style={{
                        width: '380px',
                        marginBottom: '20px',
                        marginLeft: '30px',
                      }}
                    >
                      <Card sectioned>
                        <div
                          style={{
                            padding: '5px',
                            textAlign: 'center',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div style={{ textAlign: 'left', padding: '10px' }}>
                            <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                              {csatQuestion !== 'Custom question option selected!' ? (
                                [csatQuestion]
                              ) : (
                                <textarea
                                  placeholder="Enter your custom question"
                                  // value={csatCustomQuestion}
                                  onChange={(e) => handleQuestion(e)}
                                />
                                // <TextField
                                //   value={csatCustomQuestion}
                                //   onChange={handleQuestion}
                                //   multiline={4}
                                //   align="left"
                                // />
                              )}
                            </h1>
                          </div>
                          <CSAT />
                        </div>
                      </Card>
                    </div>
                  </Stack>
                </div>
              </Stack>

              {type === 'customer_satisfaction_score' ? (
                <div
                  style={{
                    width: '100%',
                    // height: '100px',
                    marginLeft: '30px',
                    marginTop: '20px',
                    paddingRight: '90px',
                  }}
                >
                  <Select
                    label=" Select the type of question you want to ask."
                    placeholder="Select a Question."
                    options={options}
                    onChange={handleCSATQuestionSelectChange}
                    value={csatQuestion}
                  />
                </div>
              ) : (
                ''
              )}
              <hr
                style={{
                  border: '0',
                  borderTop: '1px solid lightgray',
                  marginRight: '50px',
                  marginTop: '0',
                }}
              />
              <div
                style={{
                  marginLeft: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* <input type="checkbox" /> */}
                <div style={{ width: '80%' }}>
                  <Heading>Add Open text field</Heading>
                  <TextStyle variation="subdued">
                    <p style={{ fontSize: '12px' }}>
                      Select this if you want to ask the customer to explain their NPS or CSAT
                      score.
                    </p>
                  </TextStyle>
                </div>
                <Stack spacing="extraLoose">
                  <RadioButton
                    label="Yes"
                    checked={openText == 'true'}
                    id="true"
                    name="open text"
                    onChange={handleOpenTextSelect}
                  />
                  <RadioButton
                    label="No"
                    id="false"
                    name="close text"
                    checked={openText == 'false'}
                    onChange={handleOpenTextSelect}
                  />
                </Stack>
              </div>
              {openText === 'true' ? (
                <div style={{ width: '82%', marginLeft: '30px' }}>
                  <TextField
                    value={openTextQuestion}
                    placeholder="Can you explain your choice ?"
                    onChange={handleQuestion2}
                    multiline={2}
                    align="left"
                  />
                </div>
              ) : (
                ''
              )}
              <Stack distribution="trailing">
                {type && openText !== '' ? (
                  <Button primary onClick={() => navigate()}>
                    Next: Add Logo
                  </Button>
                ) : (
                  <Button disabled>Next: Add Logo</Button>
                )}
              </Stack>
            </FormLayout>
          </Form>
        </div>
      ) : nav === true ? (
        <Addlogo
          feedbackProps={feedbackProps}
          type={type}
          // questions={questionsSelected}
          showOpen={openText}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Questions;
