import React from 'react';
import { Stack } from '@shopify/polaris';

const Footer = () => (
  <div style={{ margin: '80px' }}>
    <Stack distribution="center" alignment="center">
      <svg
        width="109"
        height="52"
        viewBox="0 0 109 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.2754 17.992C26.8958 16.328 25.2074 15.2006 22.3288 15.2006C16.8434 15.2006 13.3923 19.5021 13.3923 25.1181C13.3923 30.7341 16.8434 35.0813 22.3288 35.0813C25.4751 35.0813 27.2706 33.7625 28.2754 32.2483V41.6665H34.4527V15.5501H28.2754V17.992ZM25.9445 29.12C24.9768 29.6675 23.845 29.8409 22.76 29.6078C21.6751 29.3747 20.711 28.7511 20.0476 27.8532C19.3841 26.9553 19.0665 25.8443 19.1539 24.7273C19.2413 23.6103 19.7278 22.5635 20.5227 21.7821C21.3176 21.0006 22.3666 20.5378 23.4744 20.4798C24.5821 20.4218 25.6729 20.7727 26.5434 21.467C27.414 22.1613 28.005 23.1517 28.2062 24.2535C28.4074 25.3554 28.2052 26.4936 27.6371 27.456L28.2672 29.7315L25.9445 29.12Z"
          fill="#E95569"
        />
        <path
          d="M44.6946 18.2624H44.6164L44.6534 15.5501H38.5173V34.6528H44.6534V26.1664C44.6534 24.0365 45.1558 22.7968 45.613 22.177C45.9505 21.7414 46.3818 21.3892 46.8741 21.147C47.3665 20.9048 47.9069 20.779 48.4545 20.7792C49.2216 20.7568 49.9833 20.9163 50.6783 21.2451H50.946L51.2919 15.5501C50.6861 15.2901 50.0304 15.1707 49.3728 15.2006C47.3591 15.2006 45.7694 16.1699 44.6946 18.2624Z"
          fill="#E95569"
        />
        <path
          d="M59.5736 15.5501H53.4375V34.6528H59.5736V15.5501Z"
          fill="#E95569"
        />
        <path
          d="M70.8327 10.4H64.6554V15.5875H61.9704V20.2758H64.6554V27.6765C64.6554 30.5885 65.0384 32.1734 65.998 33.2966C66.9575 34.4198 68.4153 35.0813 70.6021 35.0813C72.0727 35.0693 73.5352 34.8595 74.9509 34.4573L74.6461 29.615C74.0542 29.8325 73.4309 29.9506 72.8012 29.9645C71.9776 29.9645 71.6152 29.8106 71.3434 29.4986C71.0716 29.1866 70.808 28.7622 70.808 27.1357V20.2758H74.9879V15.5875H70.8327V10.4Z"
          fill="#E95569"
        />
        <path
          d="M86.1606 15.2006C80.5598 15.2006 75.9557 18.8032 75.9557 25.1181C75.9557 31.4329 80.2139 35.0813 86.0452 35.0813C91.8766 35.0813 94.4093 32.4438 95.0229 31.9779L92.6055 28.7622C90.9226 29.9028 88.9526 30.5349 86.9265 30.5843C84.435 30.5843 82.3636 29.3363 82.0959 26.5907H95.5418C95.5418 26.5907 95.62 25.4675 95.62 24.5398C95.6365 18.9571 91.7613 15.2006 86.1606 15.2006ZM82.133 23.0672C82.1658 22.0462 82.5905 21.078 83.3171 20.3675C84.0437 19.657 85.0154 19.2598 86.0267 19.2598C87.038 19.2598 88.0097 19.657 88.7363 20.3675C89.4629 21.078 89.8876 22.0462 89.9205 23.0672H82.133Z"
          fill="#E95569"
        />
      </svg>
    </Stack>
  </div>
);

export default Footer;
