/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import {
  Layout, Page, EmptyState, DisplayText,
} from '@shopify/polaris';
import Graph from './Reports SubComponents/Graph';
// import TotalFeedback from './Reports SubComponents/TotalFeedback';
import CSATFeedback from './Reports SubComponents/CSATFeedback';
import NPSFeedback from './Reports SubComponents/NPSFeedback';
import ProComponent from './Reports SubComponents/ProComponent';
import Footer from '../../helper/Footer';
import ComingSoon from '../../assets/EmptyStateSvg/cominSoon.svg';
import store from '../../store/CustomStore';
// import { PullStore } from '../../store/PullStore';
// import { PlanSelected } from '../../helper/context/planSelected';

const Reports = () => {
  const [score, setScore] = useState('');
  const PLUGGOFF = false;
  const activeFeedbackType = store.get('typeOfQuestion');
  // const CurrentPlanId = useContext(PlanSelected);

  return (
    <Page>
      {PLUGGOFF ? (
        <EmptyState image={ComingSoon}>
          <DisplayText>Coming Soon...</DisplayText>
        </EmptyState>
      ) : !PLUGGOFF ? (
        <>
          <Layout>
            <Layout.Section>
              <Graph captureScore={(value) => setScore(value)} />
            </Layout.Section>
            {activeFeedbackType === 'customer_satisfaction_score' ? (
              <Layout.Section>
                <CSATFeedback score={score} />
              </Layout.Section>
            ) : activeFeedbackType === 'net_promoter_score' ? (
              <Layout.Section>
                <NPSFeedback score={score} />
              </Layout.Section>
            ) : '' }

            <Layout.Section>
              <ProComponent />
            </Layout.Section>
          </Layout>
          <Layout.Section>
            <Footer />
          </Layout.Section>
        </>
      ) : (
        ''
      )}
    </Page>
  );
};

export default Reports;
