/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable max-len */
import React from 'react';
import {
  Page, Layout, TextStyle, Stack, Heading, Card, Link,
} from '@shopify/polaris';
import DownloadList from './TakeActionsSubComponents/DownloadList';
import ExportData from './TakeActionsSubComponents/ExportData';
import NeedCare from './TakeActionsSubComponents/NeedCare';
import ManualReview from './TakeActionsSubComponents/ManualReviewTable';
import Footer from '../../helper/Footer';

const TakeActions = () => (
  <Page>
    <Layout.AnnotatedSection
      title={(
        <Stack vertical>
          <Heading>Manual-Review</Heading>
          <TextStyle variation="subdued">
            <p style={{ fontSize: '12px' }}>
              Request reviews from you customers who gave you feedback.
            </p>
          </TextStyle>
          <TextStyle variation="subdued">
            <p style={{ fontSize: '12px' }}>
              Get more social proof on your store by asking customers for a product review. You can
              customize your request message in the Settings.
            </p>
          </TextStyle>

          <TextStyle>
            <p style={{ fontSize: '12px' }}>
              Do you want to ask automatically for a review? Go to settings and enable Auto Review.
            </p>
          </TextStyle>
          <TextStyle variation="subdued">
            <p style={{ fontSize: '12px', marginTop: '10px' }}>
              Check our support page before you turn on SMS to make sure the SMS will be delivered.
              We charge based on sent messages also if they are not delivered. &nbsp;
              <Link
                url="https://support.qrite.com/docs/supported-countries-for-sms/"
                removeUnderline
                external
              >
                Find out more.
              </Link>
            </p>
          </TextStyle>
          <Link
            url="https://qrite.com/support/book-a-consultatio
n"
            removeUnderline
            external
          >
            <p> Learn more about manual review. </p>
          </Link>
        </Stack>
      )}
    >
      <ManualReview />
    </Layout.AnnotatedSection>
    <Layout.AnnotatedSection
      title={(
        <Stack vertical>
          <Heading>Receive “need care” emails</Heading>
          <TextStyle variation="subdued">
            <p>
              Stay on top of your negative feedback. Get email notifications for every unhappy
              customer with feedback and order information to reach out to them in a timely manner.
            </p>
          </TextStyle>
        </Stack>
      )}
    >
      <NeedCare />
    </Layout.AnnotatedSection>
    <Layout.AnnotatedSection
      title={(
        <Stack vertical>
          <Heading>Download customer lists.</Heading>
          <TextStyle variation="subdued">
            <p>
              Create effective email marketing campaigns by downloading segmented lists of happy,
              unhappy and passive customers.
            </p>
            <p>
              Select exclude previous exported if you only want to download new customers who were
              added after your previous exported list.
            </p>
          </TextStyle>
        </Stack>
      )}
    >
      <Card
        sectioned
        title={(
          <Stack vertical>
            <TextStyle variation="strong">Download List</TextStyle>
            <DownloadList />
          </Stack>
        )}
      />
    </Layout.AnnotatedSection>
    <Layout.AnnotatedSection
      title={(
        <Stack vertical>
          <Heading>Export feedback data</Heading>
          <TextStyle variation="subdued">
            <p>
              Put your feedback to work. Download all your data to understand how customers feel
              about you and your products.
            </p>
          </TextStyle>
        </Stack>
      )}
    >
      <Card
        sectioned
        title={(
          <Stack vertical>
            <Heading>Export all your data</Heading>
            <ExportData />
          </Stack>
        )}
      />
    </Layout.AnnotatedSection>
    <Layout.Section>
      <Footer />
    </Layout.Section>
  </Page>
);
export default TakeActions;
