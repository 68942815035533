/* eslint-disable object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { DisplayText, Page, EmptyState, FooterHelp, Link, Spinner } from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import ChoosePlan from '../Components/Subscription/ChoosePlan';
import Header from '../assets/QriteHeader/Header';
import apiCaller from '../helper/API/apiCaller';
import store from '../store/CustomStore';

const { REACT_APP_API_Key } = process.env;

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  function IdentifyUser(id, name, email) {
    // This is an example script - don't forget to change it!
    FS.identify(id, {
      displayName: name,
      email,
      // TODO: Add your own custom user variables here, details at
      // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
      reviewsWritten_int: 14,
    });
  }

  async function getShopDetails() {
    const host = store.get('HOST');
    const SHOPNAME = store.get('shop-name');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const SESSION_TOKEN = await getSessionToken(app);
    const GET_SHOP_DETAILS_ENDPOINT = 'getShopDetails';
    const headers = {
      'Access-Control-Allow-Origin': '*',
      Authorizer: SESSION_TOKEN,
    };
    const data = {
      shop: SHOPNAME,
    };
    const response = await apiCaller(GET_SHOP_DETAILS_ENDPOINT, headers, data);
    const merchant_id = response.data.info[0].id;
    const { email } = response.data.info[0].shop_info.shop;
    IdentifyUser(merchant_id, SHOPNAME, email);
  }
  useEffect(() => {
    // console.log('Reached');
    setLoading(true);
    getShopDetails();
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
    <Page>
      {loading ? (
        <EmptyState heading={<DisplayText>Please wait while we get you onboard...</DisplayText>}>
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </EmptyState>
      ) : (
        <>
          <Header />

          <ChoosePlan />
          <div
            style={{
              textAlign: 'center',
              paddingTop: '20px',
              fontStyle: 'italic',
            }}
          >
            <p>**Get unlimited feedback interactions until October 2022</p>
          </div>
          <FooterHelp>
            Need help ?
            <Link removeUnderline url="https://qrite.com/support/book-a-consultation" external>
              Book a consultation
            </Link>
          </FooterHelp>
        </>
      )}
    </Page>
  );
};

export default Subscription;
