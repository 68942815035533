/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  Card, Stack, TextStyle, EmptyState, Button,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { ToastContainer, toast } from 'react-toastify';
import store from '../../../store/CustomStore';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
import apiCaller from '../../../helper/API/apiCaller';

var sessionToken = '';

const { REACT_APP_API_Key } = process.env;
const NeedCare = () => {
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [needCareEmail, setNeedCareEmail] = useState('Please Provide an Email');
  const currentPlan = store.get('planSelectedID');

  const emailRegex = /\S+@\S+\.\S+/;
  const validateEmail = (event) => {
    const mail = event.target.value;
    if (emailRegex.test(mail)) {
      setIsValid(true);
      setNeedCareEmail(mail);
      store.get('needCareEmail', mail);
      store.set('needCareEmail', mail);
      setMessage('Your email looks good!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
    }
  };
  async function checkNeedCareEmail(sessionToken) {
    const shop = store.get('shop-name');
    const GET_SHOP_DETAILS_ENDPOINT = 'getShopDetails';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      shop,
    };
    const response = await apiCaller(GET_SHOP_DETAILS_ENDPOINT, headers, data);
    const initial_params = response.data.info[0].id;
    store.get('merchant_id', initial_params);

    const shopItem = response.data.info[0];

    setNeedCareEmail(shopItem.need_care_notification.email);
  }

  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    checkNeedCareEmail(sessionToken);
  }

  async function navigate() {
    // const host = store.get('HOST');
    // const app = createApp({
    //   apiKey: REACT_APP_API_Key,
    //   host,
    // });
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    // store.set('RewardFeedbackText', rewardText);
    // store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestion,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: RewardTextPrevious,
          code: RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
      toast.success('Your Updates are caught!');
    }
  }
  useEffect(() => {
    GetSessionToken();
    // currentPlanId = store.get('planSelectedID');
    // console.log('NEED CARE', currentPlanId);
  }, []);

  return (
    <div>
      <Card sectioned>
        <ToastContainer />
        {currentPlan == 3 ? (
          <Stack vertical>
            {' '}
            <TextStyle variation="strong">Need Care Email</TextStyle>
            <div>
              <input
                className="input-field"
                type="email"
                placeholder={needCareEmail}
                onChange={validateEmail}
              />
              <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
            </div>
            {/* <Stack distribution="trailing">
              <TextStyle variation="positive">
                <p style={{ fontSize: '12px', marginBottom: '10px' }}>
                  Click Apply changes in settings To update your needcare email !
                </p>
              </TextStyle>
            </Stack> */}
            {/* TODO:Might need to change logic behind updating need care email */}
            <Stack distribution="trailing">
              <Button primary loading={loading} onClick={() => navigate()}>
                Save
              </Button>
            </Stack>
          </Stack>
        ) : (
          <EmptyState image={DisableEmo}>
            <p>This feature has been disabled because of plan selected</p>
          </EmptyState>
        )}
      </Card>
    </div>
  );
};

export default NeedCare;
