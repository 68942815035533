/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable no-sequences */
import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';

const JoDit = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState(`Thank you for your feedback! Your positive words really motivated our team to do better.
Would you also maybe drop us a quick product review to help others see how good they are?`);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const updateEmail = (email) => {
    setContent(email);
    props.content(email);
  };
  return (
    <div>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={updateEmail}
      />
    </div>
  );
};

export default JoDit;
