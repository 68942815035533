/* eslint-disable max-len */
import React from 'react';

const CSAT = () => (
  <div
    style={{
      paddingTop: '10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
    }}
  >
    <div>

      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 10.25C16.4061 10.25 10.25 16.4061 10.25 24C10.25 31.5939 16.4061 37.75 24 37.75C25.0498 37.75 26.0737 37.6344 27.0558 37.4103C27.7962 37.2414 28.2595 36.5043 28.0906 35.7639C27.9217 35.0236 27.1846 34.5603 26.4442 34.7292C25.6663 34.9066 24.8477 35 24 35C17.9249 35 13 30.0751 13 24C13 17.9249 17.9249 13 24 13C30.0743 13 34.9986 17.9235 35 23.9975C34.9977 24.6178 34.9426 25.2368 34.8352 25.8477C34.7036 26.5957 35.2033 27.3086 35.9512 27.4401C36.6992 27.5716 37.4121 27.0719 37.5436 26.324C37.6783 25.5582 37.7473 24.7823 37.75 24.0047L37.75 24C37.75 16.4061 31.5939 10.25 24 10.25ZM18.5 21.25C18.5 20.4906 19.1156 19.875 19.875 19.875H19.8888C20.6482 19.875 21.2638 20.4906 21.2638 21.25C21.2638 22.0094 20.6482 22.625 19.8888 22.625H19.875C19.1156 22.625 18.5 22.0094 18.5 21.25ZM26.75 21.25C26.75 20.4906 27.3656 19.875 28.125 19.875H28.1388C28.8982 19.875 29.5138 20.4906 29.5138 21.25C29.5138 22.0094 28.8982 22.625 28.1388 22.625H28.125C27.3656 22.625 26.75 22.0094 26.75 21.25ZM21.5447 29.431C22.1912 28.7712 23.0762 28.3993 24 28.3993C24.9239 28.3993 25.8088 28.7712 26.4554 29.431C26.9868 29.9735 27.8574 29.9824 28.3998 29.4509C28.9422 28.9194 28.9511 28.0489 28.4197 27.5065C27.2559 26.3187 25.6629 25.6493 24 25.6493C22.3371 25.6493 20.7442 26.3187 19.5804 27.5065C19.0489 28.0489 19.0578 28.9194 19.6002 29.4509C20.1426 29.9824 21.0132 29.9735 21.5447 29.431ZM33.6245 28.125C34.016 28.1249 34.389 28.2916 34.6499 28.5834L36.653 30.8233C38.1776 32.4707 38.1052 35.0365 36.4875 36.5953C34.866 38.1578 32.291 38.1311 30.7022 36.5354C29.1209 34.9473 29.098 32.3891 30.645 30.7729L32.5993 28.5842C32.8601 28.2922 33.233 28.1252 33.6245 28.125ZM31.6532 31.7083L32.6393 32.6666C32.1161 33.2048 32.1213 34.0632 32.6509 34.5951C33.1805 35.127 34.0388 35.1359 34.5794 34.615C35.1199 34.0942 35.1428 33.2362 34.631 32.6872L34.6117 32.6661L33.6258 31.5636L32.6789 32.6241L31.6532 31.7083Z"
          fill="#2C3E50"
        />
      </svg>
    </div>

    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 24C13 17.9249 17.9249 13 24 13C30.0751 13 35 17.9249 35 24C35 30.0751 30.0751 35 24 35C17.9249 35 13 30.0751 13 24ZM24 10.25C16.4061 10.25 10.25 16.4061 10.25 24C10.25 31.5939 16.4061 37.75 24 37.75C31.5939 37.75 37.75 31.5939 37.75 24C37.75 16.4061 31.5939 10.25 24 10.25ZM19.875 19.875C19.1156 19.875 18.5 20.4906 18.5 21.25C18.5 22.0094 19.1156 22.625 19.875 22.625H19.8887C20.6481 22.625 21.2637 22.0094 21.2637 21.25C21.2637 20.4906 20.6481 19.875 19.8887 19.875H19.875ZM26.75 21.25C26.75 20.4906 27.3656 19.875 28.125 19.875H28.1387C28.8981 19.875 29.5137 20.4906 29.5137 21.25C29.5137 22.0094 28.8981 22.625 28.1387 22.625H28.125C27.3656 22.625 26.75 22.0094 26.75 21.25ZM21.2921 30.6654C23.5088 29.2777 26.1082 28.6278 28.7171 28.8092C29.4747 28.8619 30.1315 28.2904 30.1842 27.5329C30.2369 26.7753 29.6654 26.1185 28.9079 26.0658C25.7191 25.8441 22.5421 26.6384 19.8329 28.3346C19.1892 28.7375 18.9941 29.586 19.3971 30.2296C19.8 30.8733 20.6485 31.0684 21.2921 30.6654Z"
          fill="#2C3E50"
        />
      </svg>
    </div>

    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 24C13 17.9249 17.9249 13 24 13C30.0751 13 35 17.9249 35 24C35 30.0751 30.0751 35 24 35C17.9249 35 13 30.0751 13 24ZM24 10.25C16.4061 10.25 10.25 16.4061 10.25 24C10.25 31.5939 16.4061 37.75 24 37.75C31.5939 37.75 37.75 31.5939 37.75 24C37.75 16.4061 31.5939 10.25 24 10.25ZM19.875 19.875C19.1156 19.875 18.5 20.4906 18.5 21.25C18.5 22.0094 19.1156 22.625 19.875 22.625H19.8887C20.6481 22.625 21.2637 22.0094 21.2637 21.25C21.2637 20.4906 20.6481 19.875 19.8887 19.875H19.875ZM26.75 21.25C26.75 20.4906 27.3656 19.875 28.125 19.875H28.1387C28.8981 19.875 29.5137 20.4906 29.5137 21.25C29.5137 22.0094 28.8981 22.625 28.1387 22.625H28.125C27.3656 22.625 26.75 22.0094 26.75 21.25ZM19.875 26.75C19.1156 26.75 18.5 27.3656 18.5 28.125C18.5 28.8844 19.1156 29.5 19.875 29.5H28.125C28.8844 29.5 29.5 28.8844 29.5 28.125C29.5 27.3656 28.8844 26.75 28.125 26.75H19.875Z"
          fill="#2C3E50"
        />
      </svg>
    </div>

    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 24C13 17.9249 17.9249 13 24 13C30.0751 13 35 17.9249 35 24C35 30.0751 30.0751 35 24 35C17.9249 35 13 30.0751 13 24ZM24 10.25C16.4061 10.25 10.25 16.4061 10.25 24C10.25 31.5939 16.4061 37.75 24 37.75C31.5939 37.75 37.75 31.5939 37.75 24C37.75 16.4061 31.5939 10.25 24 10.25ZM19.875 19.875C19.1156 19.875 18.5 20.4906 18.5 21.25C18.5 22.0094 19.1156 22.625 19.875 22.625H19.8887C20.6481 22.625 21.2637 22.0094 21.2637 21.25C21.2637 20.4906 20.6481 19.875 19.8887 19.875H19.875ZM26.75 21.25C26.75 20.4906 27.3656 19.875 28.125 19.875H28.1387C28.8981 19.875 29.5137 20.4906 29.5137 21.25C29.5137 22.0094 28.8981 22.625 28.1387 22.625H28.125C27.3656 22.625 26.75 22.0094 26.75 21.25ZM21.5446 27.1627C21.0132 26.6203 20.1426 26.6114 19.6002 27.1429C19.0578 27.6743 19.0489 28.5449 19.5804 29.0873C20.7442 30.2751 22.3371 30.9445 24 30.9445C25.6629 30.9445 27.2558 30.2751 28.4196 29.0873C28.9511 28.5449 28.9422 27.6743 28.3998 27.1429C27.8574 26.6114 26.9868 26.6203 26.4554 27.1627C25.8088 27.8226 24.9238 28.1945 24 28.1945C23.0762 28.1945 22.1912 27.8226 21.5446 27.1627Z"
          fill="#2C3E50"
        />
      </svg>
    </div>

    <div>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 24C13 17.9249 17.9249 13 24 13C30.0751 13 35 17.9249 35 24C35 30.0751 30.0751 35 24 35C17.9249 35 13 30.0751 13 24ZM24 10.25C16.4061 10.25 10.25 16.4061 10.25 24C10.25 31.5939 16.4061 37.75 24 37.75C31.5939 37.75 37.75 31.5939 37.75 24C37.75 16.4061 31.5939 10.25 24 10.25ZM19.875 18.5C19.1156 18.5 18.5 19.1156 18.5 19.875C18.5 20.6344 19.1156 21.25 19.875 21.25H19.8887C20.6481 21.25 21.2637 20.6344 21.2637 19.875C21.2637 19.1156 20.6481 18.5 19.8887 18.5H19.875ZM26.75 19.875C26.75 19.1156 27.3656 18.5 28.125 18.5H28.1387C28.8981 18.5 29.5137 19.1156 29.5137 19.875C29.5137 20.6344 28.8981 21.25 28.1387 21.25H28.125C27.3656 21.25 26.75 20.6344 26.75 19.875ZM17.1529 25.0979C17.1346 25.1874 17.125 25.2801 17.125 25.375C17.125 29.172 20.203 32.25 24 32.25C27.797 32.25 30.875 29.172 30.875 25.375C30.875 24.6156 30.2594 24 29.5 24H18.5C17.8355 24 17.2811 24.4713 17.1529 25.0979ZM24 29.5C22.2039 29.5 20.676 28.3521 20.1097 26.75H27.8903C27.324 28.3521 25.7961 29.5 24 29.5Z"
          fill="#2C3E50"
        />
      </svg>
    </div>
  </div>
);

export default CSAT;
