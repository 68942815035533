/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useCallback } from 'react';
import {
  Page, Frame, Layout, Button, TextStyle, Stack, Heading, Modal,
} from '@shopify/polaris';
// import createApp from '@shopify/app-bridge';
// import { getSessionToken } from '@shopify/app-bridge-utils';
import PlanDetails from './Settings/PlanDetails';
// import store from '../../store/CustomStore';
// import Language from './Settings/Language';
import Questions from './Settings/Questions';
import AddLogo from './Settings/AddLogo';
import RewardFeedback from './Settings/RewardFeedback';
import ReviewEmail from './Settings/ReviewEmail';
import ReviewSms from './Settings/ReviewSms';
import FeedBackChannel from './Settings/FeedbackChannel';
import FeedBackEmail from './Settings/FeedbackEmail';
import FeedbackSms from './Settings/FeedbackSMS';
import Footer from '../../helper/Footer';
import ChangePlan from './Settings/UpgradePlanComponents/ChangePlan';
import AutoReview from './Settings/AutoReview';
// import { PlanSelected } from '../../helper/context/planSelected';
// import apiCaller from '../../helper/API/apiCaller';

// const { REACT_APP_API_Key } = process.env;

// var shopDetails = [];

const Settings = () => {
  const [active, setActive] = useState(false);
  // const [shopDetails, setShopDetails] = useState([]);

  const handleChange = useCallback(() => setActive(!active), [active]);
  // const CurrentPlanId = useContext(PlanSelected);
  const sections = [
    {
      id: 'Feedback Channels',
      subline: 'Via which channel do you want to ask your customers for a feedback?',
      component: <FeedBackChannel />,
    },
    {
      id: 'Questions',
      subline:
        'Localise and change your customer feedback questions to get more insights from buyers.',
      subline2:
        'Give customers the possibility to explain their score by adding an open text field. Very handy if your customer care team needs to reach out to an unhappy customer!',
      component: <Questions />,
    },
    {
      id: 'Add Logo',
      component: <AddLogo />,
    },

    {
      id: 'Auto Review',
      helpingText:
        'Activate Auto Review to request reviews from customers that submit positive feedback',
      component: <AutoReview />,
    },
    {
      id: 'Reward feedback',
      helpingText:
        'Give customers a discount code for sharing their feedback to encourage another purchase.',
      component: <RewardFeedback />,
    },
    {
      id: 'Customize Feedback Email',
      helpingText: 'Customize your feedback email by adding a personal note to request feedback.',
      component: <FeedBackEmail />,
    },
    {
      id: 'Customize Feedback SMS',
      helpingText: 'Customize your feedback SMS by adding a personal note to request feedback.',
      component: <FeedbackSms />,
    },
    {
      id: 'Customize Review Email',
      helpingText: 'Customize your review email by adding a personal note to request reviews.',
      component: <ReviewEmail />,
    },
    {
      id: 'Customize Review SMS',
      helpingText: 'Customize your review SMS by adding a personal note to request reviews.',
      component: <ReviewSms />,
    },
  ];

  const activator = (
    <Button plain onClick={handleChange}>
      compare plan
    </Button>
  );

  const ChangePlanModal = (
    <Modal large activator={activator} open={active} onClose={handleChange}>
      <Modal.Section>
        <ChangePlan />
      </Modal.Section>
    </Modal>
  );

  return (
    <Frame>
      <Page>
        <Layout.AnnotatedSection
          title={(
            <Stack vertical>
              <Heading>Plan details</Heading>
              <TextStyle variation="subdued">
                <p>
                  This is your current plan. You can upgrade or
                  {ChangePlanModal} with different features here with different features and rates.
                </p>
              </TextStyle>
            </Stack>
          )}
        >
          <PlanDetails />
        </Layout.AnnotatedSection>

        {sections.map((section) => (
          <Layout.AnnotatedSection
            key={section.id}
            title={(
              <Stack vertical alignment="fill">
                <div>
                  <Heading>{section.id}</Heading>
                  <TextStyle variation="subdued">
                    <p>{section.subline}</p>
                  </TextStyle>
                  <TextStyle variation="subdued">
                    <p> {section.helpingText} </p>
                  </TextStyle>
                </div>
                <div>
                  <TextStyle variation="subdued">
                    <p>{section.subline2}</p>
                  </TextStyle>
                </div>
              </Stack>
            )}
          >
            {section.component}
          </Layout.AnnotatedSection>
        ))}
        <Layout.Section>
          <Footer />
        </Layout.Section>
      </Page>
    </Frame>
  );
};
export default Settings;
