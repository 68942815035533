import React from 'react';
import { Page, Banner } from '@shopify/polaris';

const ComparePlans = () => (
  <Page
    breadcrumbs={[{ title: 'Settings', url: '/dashboard/settings' }]}
    title="Compare Plans"
  >
    <Banner
      title="You are currently on the Growth "
      action={{ content: 'Dismiss', url: '' }}
      status="info"
      onDismiss={() => {}}
    />
  </Page>
);

export default ComparePlans;
