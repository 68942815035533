/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-new */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

import { Card, Heading, Stack, DisplayText, Spinner } from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import { getSessionToken } from '@shopify/app-bridge-utils';
import createApp from '@shopify/app-bridge';
import apiCallerAdvancedGraphs from '../../../helper/API/apiCallerAdvancedGraphs';
import FilterData from '../../../helper/FilterData';
import store from '../../../store/CustomStore';

const today = new Date();
const startDay = new Date(today.getFullYear(), today.getMonth(), today.getDate());
const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
var filterDate = {
  from_date: startDay.toISOString().split('T')[0],
  to_date: nextweek.toISOString().split('T')[0],
};
const { REACT_APP_API_Key } = process.env;
var RESPONSECSAT = [];
var RESPONSENPS = [];

function findValuesCSAT(arr) {
  RESPONSECSAT = [];
  // eslint-disable-next-line guard-for-in
  for (var i in arr) {
    // console.log(arr[i]);

    RESPONSECSAT.push(arr[i]);
    // console.log('RESPONSE', RESPONSE);
  }
}
function findValuesNPS(arr) {
  RESPONSENPS = [];
  // eslint-disable-next-line guard-for-in
  for (var i in arr) {
    // console.log(arr[i]);

    RESPONSENPS.push(arr[i]);
    // console.log('RESPONSE', RESPONSE);
  }
}
const Graph = ({ captureScore }) => {
  const [loading, setLoading] = useState(false);
  const [scoreNPS, setScoreNPS] = useState('');
  const [countNPS, setCountNPS] = useState('');
  const [scoreCSAT, setScoreCSAT] = useState('');
  const [countCSAT, setCountCSAT] = useState('');
  const MERCHANT_ID = store.get('merchant_id');
  const type = store.get('typeOfQuestion');
  const notify = () =>
    toast.error('No Record Found !', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const toastMarkup = (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
  const state = {
    // labels: [{RESPONSENPS.map((value) => value.months)},{RESPONSECSAT.map((value) => value.months)}],
    labels: [RESPONSENPS.map((value) => value.months), RESPONSECSAT.map((value) => value.months)],
    datasets: [
      // type === 'net_promoter_score'
      //   ?
      {
        label: 'NPS',
        data: [scoreNPS],
        borderWidth: 3,
        backgroundColor: '#9FC840',
        borderColor: '#9FC840',
      },
      // :
      {
        label: 'CSAT',
        data: [scoreCSAT],
        borderWidth: 3,
        backgroundColor: '#E85769',
        borderColor: '#E85769',
      },
    ],
  };

  const LineOptions = {
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  async function getPrimaryNPSFeedbackData() {
    setLoading(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const endPoint = 'primaryGraphFeedbackValue';

    const headers = {
      Authorizer: sessionToken,
    };
    const data = {
      merchant_id: MERCHANT_ID,
      from_date: filterDate.from_date,
      to_date: filterDate.to_date,
      type: 'net_promoter_score',
    };
    try {
      const response = await apiCallerAdvancedGraphs(endPoint, headers, data);
      const values = response.data.body;
      setScoreNPS(Math.trunc(values.total));
      captureScore(Math.trunc(values.total));
      setCountNPS(values.feedback_count);
      findValuesNPS(values);
      setLoading(false);
    } catch (error) {
      console.log('Error', error.message);
      notify();
      setLoading(false);
    }
  }
  async function getPrimaryCSATFeedbackData() {
    setLoading(true);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    const sessionToken = await getSessionToken(app);
    const endPoint = 'primaryGraphFeedbackValue';

    const headers = {
      Authorizer: sessionToken,
    };
    const data = {
      merchant_id: MERCHANT_ID,
      from_date: filterDate.from_date,
      to_date: filterDate.to_date,
      type: 'customer_satisfaction_score',
    };
    try {
      const response = await apiCallerAdvancedGraphs(endPoint, headers, data);
      const values = response.data.body;
      setScoreCSAT(Math.trunc(values.total));
      captureScore(Math.trunc(values.total));
      setCountCSAT(values.feedback_count);
      findValuesCSAT(values);
      setLoading(false);
    } catch (error) {
      console.log('Error', error.message);
      notify();
      setLoading(false);
    }
  }
  const handleFilterDate = (value) => {
    filterDate = { from_date: value.start, to_date: value.end };
    getPrimaryNPSFeedbackData();
    getPrimaryCSATFeedbackData();
  };

  useEffect(() => {
    RESPONSECSAT = [];
    RESPONSENPS = [];
    getPrimaryCSATFeedbackData();
    getPrimaryNPSFeedbackData();
  }, []);

  // useEffect(() => {
  //   // chart();
  //   // getShopDetails();
  // }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <FilterData filterDate={(value) => handleFilterDate(value)} />
      </div>
      <Card
        sectioned
        title={
          <Stack vertical>
            <Stack alignment="center">
              <Heading>Overview Feedback store</Heading>
            </Stack>

            <Stack alignment="center">
              <Heading> Total feedback NPS received:</Heading>

              <DisplayText>{countNPS || 0}</DisplayText>
            </Stack>
            <Stack alignment="center">
              <Heading> Total feedback CSAT received:</Heading>

              <DisplayText>{countCSAT || 0}</DisplayText>
            </Stack>

            {/* <DisplayText>
              <TextStyle variation="positive">771%</TextStyle>
            </DisplayText> */}
            <Stack>
              <Stack vertical>
                <Heading>All Time NPS Score</Heading>
                <DisplayText>{scoreNPS || 0}</DisplayText>
              </Stack>

              <Stack vertical>
                <Heading>All Time CSAT Score</Heading>
                <DisplayText>{scoreCSAT || 0}</DisplayText>
              </Stack>
            </Stack>
          </Stack>
        }
      >
        {loading ? (
          <Spinner accessibilityLabel="Spinner example" size="large" />
        ) : (
          <Line data={state} options={LineOptions} />
        )}
      </Card>
      {toastMarkup}
    </div>
  );
};

export default Graph;
