/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable linebreak-style */
/* eslint-disable object-curly-newline */

/* eslint-disable camelcase */

/* eslint-disable no-var */

/* eslint-disable guard-for-in */

/* eslint-disable no-restricted-syntax */

/* eslint-disable no-param-reassign */

/* eslint-disable prefer-const */

/* eslint-disable no-restricted-globals */

/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';

import { Stack, Card, TextStyle, Layout, Button, Heading, DisplayText } from '@shopify/polaris';

import { useHistory } from 'react-router-dom';

import { Redirect } from '@shopify/app-bridge/actions';

import createApp from '@shopify/app-bridge';

import { getSessionToken } from '@shopify/app-bridge-utils';

import PlanDetails from '../../../../helper/json/PlanDetails.json';

import apiCaller from '../../../../helper/API/apiCaller';

// import { PlanSelected } from '../../../../helper/context/planSelected';

import store from '../../../../store/CustomStore';

var sessionToken = '';

const { REACT_APP_API_Key } = process.env;

const ChangePlan = () => {
  const history = useHistory();

  const Plan = store.get('planSelectedID');

  async function paymentRedirect(url) {
    const host = store.get('HOST');

    const app = createApp({
      apiKey: REACT_APP_API_Key,

      host,
    });

    // const REGISTER_REDIRECT_URL = `https://${shop}/admin/apps/${REACT_APP_NAME}`;

    const redirect = Redirect.create(app);

    // Removed widnow.location with shopify provided redirect

    redirect.dispatch(Redirect.Action.REMOTE, url);
  }

  async function setPlan(id, plan) {
    store.set('planSelected', plan);

    // store.set('planSelectedID', id);

    const SHOPNAME = store.get('shop-name');

    const headers = {
      'Content-Type': 'application/json',

      Authorizer: sessionToken,
    };

    // !redirected;

    const url = 'setMerchantPlan';

    const data = {
      shop_id: SHOPNAME,

      plan_id: id,
    };

    await apiCaller(url, headers, data)
      .then((response) => {
        const { url } = response.data;

        paymentRedirect(url);
      })

      .catch(() => {
        // console.log(error);
      });

    // }
  }

  async function checkChargeId() {
    const charge_id = store.get('CHargeID');

    if (charge_id) {
      const MERCHANT_ID = store.get('merchant_id');

      const CONFIRM_PAYMENT_URL = 'confirmPayment';

      const data = {
        merchant_id: MERCHANT_ID,

        shopify_payment_id: charge_id,
      };

      const headers = {
        'Content-Type': 'application/json',

        Authorizer: sessionToken,
      };

      apiCaller(CONFIRM_PAYMENT_URL, headers, data)
        .then((response) => {
          // tlocalStorage.removeItem('CHargeID');

          const { message } = response.data;

          if (message === 'verified') {
            history.push('/wizard');
          }
        })

        .catch(() => {
          // console.log(error);
        });
    }
  }

  async function getplans(sessionToken) {
    const url = 'getPlans';

    const headers = {
      'Content-Type': 'application/json',

      Authorizer: sessionToken,
    };

    const SHOPNAME = store.get('shop-name');

    // <-----API CALLLER------->

    await apiCaller(url, headers, SHOPNAME).then((response) => {
      const details = response.data;

      for (const key in details) {
        // eslint-disable-next-line array-callback-return

        details.map((item) => {
          // eslint-disable-next-line eqeqeq

          if (details[key].name.toLowerCase() === item.planName.toLowerCase()) {
            item.planId = details[key].id;

            item.price = details[key].price;
          }
        });
      }
    });
  }

  async function GetSessionToken(host) {
    const app = createApp({
      apiKey: REACT_APP_API_Key,

      host,
    });

    sessionToken = await getSessionToken(app);

    if (sessionToken) {
      getplans(sessionToken);
    }
  }

  useEffect(() => {
    checkChargeId();
  }, []);

  useEffect(() => {
    const host = store.get('HOST');

    if (host) {
      GetSessionToken(host);
    }
  }, []);

  return (
    <Layout>
      {PlanDetails.map((planItem) => (
        <Layout.Section oneThird>
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Card
              sectioned
              title={
                <Stack vertical spacing="loose">
                  <Stack spacing="extraTight">
                    <Stack.Item>{planItem.currency}</Stack.Item>

                    <Stack.Item>
                      <DisplayText>
                        <TextStyle variation="strong">{planItem.amount}</TextStyle>
                      </DisplayText>
                    </Stack.Item>
                  </Stack>

                  {Plan === planItem.planId ? (
                    <Button disabled>Choose this Plan</Button>
                  ) : (
                    <Button
                      primary
                      submit
                      onClick={() => setPlan(planItem.planId, planItem.planName)}
                    >
                      Choose this Plan
                    </Button>
                  )}

                  <Stack vertical>
                    <Heading>{planItem.planName}</Heading>

                    <p>{planItem.planHighlights}</p>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings1}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings2}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings3}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings4}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings5}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings6}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings7}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings8}</p>
                    </TextStyle>

                    <TextStyle variation="subdued">
                      <p>{planItem.offerings9}</p>
                    </TextStyle>

                    <TextStyle variation="positive">
                      <p>{planItem.informations}</p>
                    </TextStyle>
                  </Stack>
                </Stack>
              }
            />
          </div>
        </Layout.Section>
      ))}
    </Layout>
  );
};

export default ChangePlan;
