/* eslint-disable eqeqeq */
/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';

import {
  RadioButton,
  Stack,
  TextContainer,
  Select,
  Form,
  Button,
  FormLayout,
  TextField,
  Heading,
  Card,
  TextStyle,
  Spinner,
} from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../../store/CustomStore';
import apiCaller from '../../../helper/API/apiCaller';
import NPS from '../../../assets/FeedbackCat/NPS';
import CSAT from '../../../assets/FeedbackCat/CSAT';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const feedbackStyles = { display: 'flex', paddingTop: '10px' };
const Questions = () => {
  const [type, setType] = useState('');
  const [npsQuestion, setNPSQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [openText, setOpenText] = useState(false);
  const [csatQuestion, setCSATQuestion] = useState(
    ' Would you recommend our products/services to your friends and family?',
  );

  const [csatCustomQuestion, setCSATCustomQuestion] = useState('Enter Custom Question');
  const [openTextQuestion, setOpenTextQuestion] = useState('');

  function questionRecieved(val) {
    setNPSQuestion(val);
  }
  const handleQuestion = (e) => {
    setCSATCustomQuestion(e.target.value);
  };

  const handleQuestion2 = useCallback((newValue) => setOpenTextQuestion(newValue),
    []);

  async function navigate() {
    if (type === 'net_promoter_score') {
      store.set('Question', npsQuestion);
    } else if (
      type === 'customer_satisfaction_score' &&
      csatQuestion !== 'Custom question option selected!'
    ) {
      store.set('Question', csatQuestion);
    } else if (
      type === 'customer_satisfaction_score' &&
      csatQuestion === 'Custom question option selected!'
    ) {
      store.set('Question', csatCustomQuestion);
    }
    store.set('typeOfQuestion', type);
    if (openText === 'yes') {
      store.set('showOpenText', true);
    } else if (openText === 'no') {
      store.set('showOpenText', false);
    }
    store.set('openTextQuestion', openTextQuestion);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    setLoading(true);
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestionSet = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    // store.set('RewardFeedbackText', rewardText);
    // store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestionSet,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: RewardTextPrevious,
          code: RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
      toast.success('Your Updates are caught!');
    }
  }

  const handleCSATQuestionSelectChange = useCallback(
    (selected) => setCSATQuestion(selected),

    [],
  );

  const handleChange = useCallback(
    (_checked, value) => setType(value),

    [],
  );
  const handleOpenTextSelect = useCallback(
    (_checked, openText) => setOpenText(openText),

    [],
  );

  const handleSubmit = useCallback(() => {
    setSelected(selected);
    setValue(value);
    setOpenText(openText);
  }, []);
  const options = [
    {
      label: 'Would you recommend our products or services to your..',
      value:
        'Would you recommend our products or services to your friend or family member?',
    },
    {
      label: 'Did our product range meet your expectations?',
      value: 'Did our product range meet your expectations?',
    },
    {
      label: 'How was your experience with our website?',
      value: 'How was your experience with our website?',
    },
    {
      label: 'How likely are you to buy again from us?',
      value: 'How likely are you to buy again from us?',
    },
    {
      label: 'Create Your own Question?',
      value: 'Custom question option selected!',
    },
  ];
  async function getShopDetails(sessionToken) {
    setFetching(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const QuestionType = response.data.info[0].type_of_question;
      const ShowOpenText = response.data.info[0].show_open_text;
      const QuestionSelected = response.data.info[0].questions[0];
      const OpenTextQuestion = response.data.info[0].open_text_question;

      setType(QuestionType);
      setOpenTextQuestion(OpenTextQuestion);
      if (QuestionType === 'customer_satisfaction_score' && QuestionSelected !== null) {
        setCSATQuestion(QuestionSelected);
      } else if (QuestionType === 'net_promoter_score' && QuestionSelected !== null) {
        setNPSQuestion(QuestionSelected);
      }
      if (ShowOpenText === true) {
        setOpenText('yes');
      } else if (ShowOpenText === false) setOpenText('no');
      setFetching(false);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getShopDetails(sessionToken);
  }
  useEffect(() => {
    GetSessionToken();
  }, []);
  return (
    <Card sectioned>
      {fetching == true ? <Spinner accessibilityLabel="Spinner example" size="large" /> : fetching == false ? (
        <>
          <ToastContainer />
          <TextContainer spacing="loose">
            <Heading>Select the type of question you want to ask.</Heading>
          </TextContainer>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Stack vertical>
                <div style={feedbackStyles}>
                  <Stack vertical>
                    <RadioButton
                      label={(
                        <div style={{ width: '80%' }}>
                          <Heading>Net Promoter Score</Heading>
                          <TextStyle variation="subdued">
                            <p style={{ fontSize: '12px' }}>
                              Select this if you want to measure customer loyalty
                              and how likely they are to refer your products and
                              services to others.
                            </p>
                          </TextStyle>
                        </div>
                  )}
                      checked={type === 'net_promoter_score'}
                      id="net_promoter_score"
                      name="net_promoter_score"
                      onChange={handleChange}
                    />
                    <NPS npsQuestion={(nps) => questionRecieved(nps)} />
                  </Stack>
                </div>

                <div style={feedbackStyles}>
                  <Stack>
                    <RadioButton
                      label={(
                        <div style={{ width: '80%' }}>
                          <Heading>Customer Satisfaction Score</Heading>
                          <TextStyle variation="subdued">
                            <p style={{ fontSize: '12px' }}>
                              Select this if you want to measure customer
                              satisfaction, it can help provide useful data for
                              changing and improving your business practices to
                              better meet the needs of your clients.
                            </p>
                          </TextStyle>
                        </div>
                  )}
                      id="customer_satisfaction_score"
                      name="customer_satisfaction_score"
                      checked={type === 'customer_satisfaction_score'}
                      onChange={handleChange}
                    />

                    {/* //////////////////////////////////////////// */}
                    <div
                      style={{
                        width: '380px',
                        marginBottom: '20px',
                        marginLeft: '30px',
                      }}
                    >
                      <Card sectioned>
                        <div
                          style={{
                            padding: '5px',
                            textAlign: 'center',
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div style={{ textAlign: 'left', padding: '10px' }}>
                            <h1 style={{ fontSize: '14px', fontWeight: 'bold' }}>
                              {csatQuestion
                          !== 'Custom question option selected!' ? (
                                  [csatQuestion]
                                ) : (
                                  <textarea
                                    placeholder="Enter your custom question"
                              // value={csatCustomQuestion}
                                    onChange={(e) => handleQuestion(e)}
                                  />
                                )}
                            </h1>
                          </div>
                          <CSAT />
                        </div>
                      </Card>
                    </div>
                  </Stack>
                </div>
              </Stack>

              {type === 'customer_satisfaction_score' ? (
                <div
                  style={{
                    width: '100%',
                    // height: '100px',
                    marginLeft: '30px',
                    marginTop: '20px',
                    paddingRight: '90px',
                  }}
                >
                  <Select
                    label=" Select the type of question you want to ask."
                    placeholder="Select a Question."
                    options={options}
                    onChange={handleCSATQuestionSelectChange}
                    value={csatQuestion}
                  />
                </div>
              ) : (
                ''
              )}
              <hr
                style={{
                  border: '0',
                  borderTop: '1px solid lightgray',
                  marginRight: '50px',
                  marginTop: '0',
                }}
              />
              <div
                style={{
                  marginLeft: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {/* <input type="checkbox" /> */}
                <div style={{ width: '80%' }}>
                  <Heading>Add Open text field</Heading>
                  <TextStyle variation="subdued">
                    <p style={{ fontSize: '12px' }}>
                      Select this if you want to ask the customer to explain their
                      NPS or CSAT score.
                    </p>
                  </TextStyle>
                </div>
                <Stack spacing="extraLoose">
                  <RadioButton
                    label="Yes"
                    checked={openText === 'yes'}
                    id="yes"
                    name="open text"
                    onChange={handleOpenTextSelect}
                  />
                  <RadioButton
                    label="No"
                    id="no"
                    name="close text"
                    checked={openText === 'no'}
                    onChange={handleOpenTextSelect}
                  />
                </Stack>
              </div>
              {openText === 'yes' ? (
                <div style={{ width: '82%', marginLeft: '30px' }}>
                  <TextField
                    value={openTextQuestion}
                    placeholder="Can you explain your choice ?"
                    onChange={handleQuestion2}
                    multiline={2}
                    align="left"
                  />
                </div>
              ) : (
                ''
              )}
              <Stack distribution="trailing">
                {type && openText !== '' ? (
                  <Button primary loading={loading} onClick={() => navigate()}>
                    Save
                  </Button>
                ) : (
                  <Button disabled>Submit</Button>
                )}
              </Stack>
            </FormLayout>
          </Form>
        </>
      ) : ''}

    </Card>
  );
};

export default Questions;
