/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, {
  useCallback, useState, useEffect,
} from 'react';
import {
  SettingToggle,
  TextStyle,
  Stack,
  EmptyState,
  Heading,
  Card,
  Button,
  TextContainer,
} from '@shopify/polaris';
import { ToastContainer, toast } from 'react-toastify';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import store from '../../../store/CustomStore';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
import apiCaller from '../../../helper/API/apiCaller';
import imageUploader from '../../../helper/API/imageUploader';
import '../../../helper/imageUploader/imageuploader.css';
// import { PlanSelected } from '../../../helper/context/planSelected';

var sessionToken = '';
var UPLOADURL = '';
const { REACT_APP_API_Key } = process.env;
const AddLogo = () => {
  // const [uploadUrl, setUploadUrl] = useState('');
  const [logo, setLogo] = useState('');
  const [baseImage, setBaseImage] = useState('');
  // const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  // const [currentPlan, setCurrentPlan] = useState('');
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const currentPlanID = store.get('planSelectedID');

  const convertBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImagePreview(base64);
    setLogo(file);
    const headers = {
      'Content-Type': 'image/jpeg',
      Authorizer: sessionToken,
    };
    // localStorage.setItem('uploadS3url', uploadUrl);
    store.set('uploadS3url', UPLOADURL);
    imageUploader(UPLOADURL, headers, file)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          toast.success('Your Updates are caught!');
          setLoading(false);
        }
      })
      .catch(() => {
        // console.log(error);
      });
  };

  const contentStatus = active ? 'Disable' : ' Enable';
  const textStatus = active ? ' Enabled' : ' Disabled';

  async function navigate() {
    setLoading(true);
    // const host = store.get('HOST');
    // const app = createApp({
    //   apiKey: REACT_APP_API_Key,
    //   host,
    // });
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    // store.set('RewardFeedbackText', rewardText);
    // store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestion,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: RewardTextPrevious,
          code: RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
    }
  }

  async function getImageUrl(sessionToken) {
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };

    const MerchantId = store.get('merchant_id');
    const url = 'getLogoUrl';
    const data = {
      merchant_id: MerchantId,
      format: 'jpg',
      is_upload: true,
    };

    apiCaller(url, headers, data).then((response) => {
      // setUploadUrl(response.data);
      UPLOADURL = response.data;
      // console.log('response.data', UPLOADURL);
    });
  }
  async function getShopDetails(sessionToken) {
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const LogoUrl = response.data.info[0].logo_url;
      setBaseImage(LogoUrl);

      if (LogoUrl) {
        handleToggle();
      }

      // setModeSelected(reviewChannels);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getImageUrl(sessionToken);
    getShopDetails(sessionToken);
  }

  useEffect(() => {
    GetSessionToken();
  }, []);
  return (
    <Card sectioned>
      {currentPlanID !== 1 ? (
        <div style={{ width: '100%' }}>
          <ToastContainer />
          <SettingToggle enabled={active}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex' }}>
                <p>Add Logo : &nbsp;</p>
                <TextStyle variation="strong">{textStatus}</TextStyle>
                .
              </div>
              {active ? (
                <div style={{ color: '#bf0711' }}>
                  <Button onClick={handleToggle}>{contentStatus}</Button>
                </div>
              ) : !active ? (
                <Button primary onClick={handleToggle}>
                  {contentStatus}
                </Button>
              ) : (
                ''
              )}
            </div>
          </SettingToggle>

          {!active ? (
            ''
          ) : (
            <Stack vertical distribution="equalSpacing">
              <div style={{ marginTop: '20px' }}>
                <TextContainer spacing="loose">
                  <Heading>Upload Logo</Heading>
                  <TextStyle variation="subdued">
                    <p style={{ fontSize: '12px' }}>Make your customers feel at home.</p>
                  </TextStyle>
                </TextContainer>
              </div>
              <div className="upload-btn-wrapper">
                <Stack vertical>
                  <Button size="slim" type="button" className="btn">
                    Select
                  </Button>
                  <input
                    type="file"
                    name="myfile"
                    accept=".jpg, .png, .jpeg"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                  {baseImage !== '' && logo == '' ? (
                    <img src={baseImage} style={{ width: '400px' }} alt="logo" />
                  ) : baseImage !== '' && logo !== '' ? (
                    <img src={imagePreview} style={{ width: '400px' }} alt="logo" />
                  ) : (
                    ''
                  )}
                </Stack>
              </div>

              <Stack distribution="trailing">
                <div style={{ marginTop: '20px' }}>
                  {logo !== '' ? (
                    <Button loading={loading} primary onClick={() => navigate()}>
                      Upload
                    </Button>
                  ) : (
                    <Button primary disabled>
                      Upload
                    </Button>
                  )}
                </div>
              </Stack>
            </Stack>
          )}
        </div>
      ) : (
        <EmptyState image={DisableEmo}>
          <p>This feature has been disabled because of plan selected</p>
        </EmptyState>
      )}
    </Card>
  );
};

export default AddLogo;
