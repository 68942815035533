/* eslint-disable camelcase */
/* eslint-disable no-var */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { Button, EmptyState } from '@shopify/polaris';
import '../../../assets/styles/input.css';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { ToastContainer, toast } from 'react-toastify';
import DisableEmo from '../../../assets/EmptyStateSvg/disabled.svg';
// import { PlanSelected } from '../../../helper/context/planSelected';
import apiCaller from '../../../helper/API/apiCaller';
import store from '../../../store/CustomStore';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const ExportData = () => {
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  // const [currentPlan, setCurrentPlan] = useState('');
  const currentPlanID = store.get('planSelectedID');

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (event) => {
    const mail = event.target.value;
    if (emailRegex.test(mail)) {
      setIsValid(true);
      setEmail(mail);
      setMessage('Your email looks good!');
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
    }
  };
  async function exportFeedbackData() {
    const MERCHANT_ID = store.get('merchant_id');
    const ENDPOINT = 'downloadFeedback';
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const data = {
      merchant_id: parseInt(MERCHANT_ID),
      to: email,
    };
    const response = await apiCaller(ENDPOINT, headers, data);
    const { message } = response.data;
    if (message == 'success') {
      toast.success('Your Download is on your way!');
    }
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
  }
  useEffect(() => {
    GetSessionToken();
  }, []);

  return (
    <>
      {currentPlanID == 3 ? (
        <>
          <ToastContainer />
          <input
            className="input-field"
            type="email"
            placeholder="info@qrite.com"
            onChange={validateEmail}
          />
          <div className={`message ${isValid ? 'success' : 'error'}`}>{message}</div>
          <div style={{ paddingTop: '16px' }}>
            <Button primary disabled={!isValid} onClick={() => exportFeedbackData()}>
              Export Data
            </Button>
          </div>
        </>
      ) : (
        <EmptyState image={DisableEmo}>
          <p>This feature has been disabled because of plan selected</p>
        </EmptyState>
      )}
    </>
  );
};

export default ExportData;
