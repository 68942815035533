/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable linebreak-style */

import React, { useState, useCallback, useEffect } from 'react';
import {
  ChoiceList,
  Heading,
  Stack,
  Button,
  Card,
  TextStyle,
  Popover,
  OptionList,
  Link,
  Tag,
  Subheading,
  Spinner,
} from '@shopify/polaris';
import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { ToastContainer, toast } from 'react-toastify';
import store from '../../../store/CustomStore';
import apiCaller from '../../../helper/API/apiCaller';
import 'react-toastify/dist/ReactToastify.css';

var sessionToken = '';
const { REACT_APP_API_Key } = process.env;
const FeedBackChannel = () => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [schedule, setSchedule] = useState([]);
  const [channel, setChannel] = useState({ email: false, sms: false, thankyou: false });

  const [selected, setSelected] = useState('');
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const options = [
    { label: 'directly', value: '0 minute' },
    { label: '15 minute', value: '15 minute' },
    { label: '30 minute', value: '30 minute' },
    { label: '1 hour', value: '1 hour' },
    { label: '1.5 hour', value: '1.5 hour' },
    { label: '2 hour', value: '2 hour' },
    { label: '4 hour', value: '4 hour' },
    { label: '6 hour', value: '6 hour' },
    { label: '8 hour', value: '8 hour' },
    { label: '1 day', value: '1 day' },
    { label: '1.5 day', value: '1.5 day' },
    { label: '2 day', value: '2 day' },
    { label: '3 day', value: '3 day' },
    { label: '4 day', value: '4 day' },
  ];
  const activator = (
    <Stack alignment="center">
      <Button onClick={togglePopoverActive} disclosure>
        Schedule
      </Button>
      {schedule !== ''
        ? schedule.map((item) => (
          <Stack>
            <Tag>{item}</Tag>
          </Stack>
        ))
        : ''}
    </Stack>
  );
  async function navigate() {
    store.set('feedbackChannels', selected);
    store.set('feedbackSchedule', schedule);
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    setLoading(true);
    const SHOPNAME = store.get('shop-name');
    const FeedbackChannels = store.get('feedbackChannels');
    const feedbackSchedule = store.get('feedbackSchedule');
    const TypeOfQuestion = store.get('typeOfQuestion');
    const Question = store.get('Question');
    const uploadS3url = store.get('uploadS3url');
    const showOpenText = store.get('showOpenText');
    const openTextQuestion = store.get('openTextQuestion');
    const needCareEmail = store.get('needCareEmail');
    const autoReviewChannels = store.get('autoReviewChannels');
    const autoReviewUrl = store.get('autoReviewUrl');
    const autoReviewSchedule = store.get('autoReviewSchedule');
    const RewardTextPrevious = store.get('RewardFeedbackText');
    const RewardCodePrevious = store.get('RewardFeedbackCode');
    // store.set('RewardFeedbackText', rewardText);
    // store.set('RewardFeedbackCode', rewardCode);
    const url = 'updateShop';

    const data = {
      shopId: SHOPNAME,
      active: true,
      settings: {
        feedback_channels: FeedbackChannels,

        type_of_questions: TypeOfQuestion,
        questions: [Question],
        show_open_text: showOpenText,

        open_text_question: openTextQuestion,
        logo_url: uploadS3url === null ? null : { image_1: uploadS3url },

        need_care_notification: {
          email: needCareEmail,
        },
        reward_feedback: {
          text: RewardTextPrevious,
          code: RewardCodePrevious,
        },
        auto_review: {
          channels: autoReviewChannels,

          url: autoReviewUrl,
        },
        language: 'english',
        review_schedule: autoReviewSchedule,
        feedback_schedule: feedbackSchedule,
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const response = await apiCaller(url, headers, data).catch(() => {
      // console.log(error);
    });
    const MESSAGE = response.data.message;
    if (MESSAGE === 'Success') {
      setLoading(false);
      toast.success('Your Updates are caught!');
    }
  }

  const handleChange = useCallback((value) => setSelected(value), []);
  const handleOptionsChange = useCallback((value) => setSchedule(value), []);
  async function getShopDetails(sessionToken) {
    setFetching(true);
    const headers = {
      'Content-Type': 'application/json',
      Authorizer: sessionToken,
    };
    const URL = 'getShopDetails';
    const SHOPNAME = store.get('shop-name');
    const data = {
      shop: SHOPNAME,
    };
    await apiCaller(URL, headers, data).then((response) => {
      const schedule = response.data.info[0].feedback_schedule;
      const email = response.data.info[0].feedback_channel_email_enabled;
      const sms = response.data.info[0].feedback_channel_sms_enabled;
      const thankyou = response.data.info[0].feedback_channel_thank_you_enabled;
      setChannel({ email, sms, thankyou });
      setSchedule(schedule);
      setFetching(false);
    });
  }
  async function GetSessionToken() {
    const host = store.get('HOST');
    const app = createApp({
      apiKey: REACT_APP_API_Key,
      host,
    });
    sessionToken = await getSessionToken(app);
    getShopDetails(sessionToken);
  }
  // useEffect(() => {
  //   const email = shopDetails[0].feedback_channel_email_enabled;
  //   const sms = shopDetails[0].feedback_channel_sms_enabled;
  //   const thankyou = shopDetails[0].feedback_channel_thank_you_enabled;
  //   console.log('UNDEFINED CHECK', email);
  //   setChannels({ email, sms, thankyou });
  // }, []);

  useEffect(() => {
    GetSessionToken();
  }, []);

  return (
    <Card sectioned>
      {fetching == true ? (
        <Spinner accessibilityLabel="Spinner example" size="large" />
      ) : fetching == false ? (
        <>
          <ToastContainer />
          <Stack vertical distribution="fill">
            <Stack Item vertical>
              <Heading>Choose your feedback channels</Heading>
              <TextStyle variation="subdued">
                <p style={{ fontSize: '12px', marginTop: '10px' }}>
                  Check our support page before you turn on SMS to make sure the SMS will be
                  delivered. We charge based on sent messages also if they are not delivered. &nbsp;
                  <Link
                    url="https://support.qrite.com/docs/supported-countries-for-sms/"
                    removeUnderline
                    external
                  >
                    Find out more.
                  </Link>
                </p>
              </TextStyle>
              <Stack alignment="center">
                <Subheading>Current Channels:</Subheading>
                {channel.email === true ? <Tag>Email</Tag> : ''}
                {channel.sms === true ? <Tag>SMS</Tag> : ''}
                {channel.thankyou === true ? <Tag>Thank You Page</Tag> : ''}
              </Stack>
              {edit === false ? (
                <Button primary size="slim" onClick={() => setEdit(!edit)}>
                  Edit
                </Button>
              ) : edit === true ? (
                <>
                  {' '}
                  <TextStyle variation="subdued">
                    <p style={{ fontSize: '12px' }}>
                      Choose where to send the review request to your happy customers after the
                      order is fulfilled.
                    </p>
                  </TextStyle>
                  <ChoiceList
                    allowMultiple
                    choices={[
                      { label: 'Thank You Page', value: 'thank_you' },
                      { label: 'SMS*', value: 'sms' },
                      { label: 'Email*', value: 'email' },
                    ]}
                    selected={selected}
                    onChange={handleChange}
                  />
                  <TextStyle variation="subdued">
                    <p>
                      *Usage charges may apply,
                      <Link url="https://qrite.com/pricing" removeUnderline>
                        See pricing
                      </Link>
                    </p>
                  </TextStyle>
                </>
              ) : (
                ''
              )}
            </Stack>
            <Stack Item>
              <Stack vertical>
                <Heading>Schedule your Feedback</Heading>
                <TextStyle variation="subdued">
                  <p style={{ fontSize: '12px' }}>
                    Choose when to send the feedback request to your customers after the order is
                    fulfilled.
                  </p>
                </TextStyle>

                <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
                  <OptionList
                    onChange={handleOptionsChange}
                    options={options}
                    selected={schedule}
                    allowMultiple
                  />
                </Popover>
              </Stack>
            </Stack>
          </Stack>
          <Stack distribution="trailing">
            <div style={{ marginTop: '100px' }}>
              {schedule.length !== 0 || selected !== '' ? (
                <Button primary loading={loading} onClick={() => navigate()}>
                  Save
                </Button>
              ) : (
                <Button disabled>Save</Button>
              )}
            </div>
          </Stack>
        </>
      ) : (
        ''
      )}

      {/* </div> */}
    </Card>
  );
};

export default FeedBackChannel;
